/**
 * Copyright Redi Kurti, 2020. All rights reserved.
 */

// React imports
import React from "react";

// Network imports
import { Auth } from "aws-amplify";

// Redux imports
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchTrackingInventory,
  pushItemToSelectedHistory,
  removeItemFromSelectedHistory,
  clearItemSelectedHistory
} from "../../redux/actions/InventoryActions";

// Other component imports
import DrawerItemMainAction from "../../../components/DrawerItems/MainAction/DrawerItemMainAction";
import InventoryCard from "../../../components/Tracking/inventory/InventoryCard";
import NoItems from "../../../components/NoItems";
import ItemList from "../../../components/ItemList";
import { groupInventoryByCode } from "../../../utils/Inventory";

// CSS imports
import "./tracking-inventory.css";

const TrackingInventory = props => {
  const { items, fetchTrackingInventory, loading } = props;

  const onFetchInventory = async () => {
    await Auth.currentSession()
      .then(data => {
        const token = data.idToken.jwtToken;
        token && fetchTrackingInventory(token);
      })
      .catch(err => console.log(err));
  };

  const handleSelection = item => {
    console.log("Selected inventory item", item);
    props.clearItemSelectedHistory();
    props.history.push("/tracking/inventory/detail");
    props.pushItemToSelectedHistory(item);
  };

  const renderItem = ({ item, index }) => (
    <InventoryCard
      key={items[item].uid}
      item={items[item]}
      onPress={handleSelection}
    />
  );

  const renderListEmpty = () => (
    <NoItems
      iconName="box-empty-color"
      iconColor="white"
      title="Nuk ka produkte ne inventar."
    />
  );

  return (
    <div className="tracking-inventory-root">
      <div className="tracking-inventory-content">
        <div className="tracking-inventory-header">
          <div className="tracking-inventory-header-title">Inventory</div>
          <div className="tracking-inventory-header-action">
            <DrawerItemMainAction
              title=""
              iconName="download"
              iconColor="white"
              iconSize={24}
              handleClick={onFetchInventory}
            />
          </div>
        </div>
        <div className="tracking-inventory-body">
          <ItemList
            items={Object.keys(items)}
            loading={loading}
            renderItem={renderItem}
            renderEmpty={renderListEmpty}
          />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTrackingInventory,
      pushItemToSelectedHistory,
      clearItemSelectedHistory
    },
    dispatch
  );

const mapStateToProps = state => {
  const { items, fetchingInventory = false } = state.inventory;
  const groupedItems = groupInventoryByCode(items);
  return {
    items: groupedItems,
    loading: fetchingInventory
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackingInventory);
