import React from "react";
import PropTypes from "prop-types";
import { Consumer } from "../../containers/context";

import "./product-card.css";

const ProductCard = ({ product }) => {
  const { id, title, description, category, sub_category } = product;
  const linkToItem = `/inventory/catalog/${id}`;

  return (
    <Consumer>
      {context => (
        <div className="product-card-container">
          <div className="product-card-header">
            <div className="product-card-info">
              {product.category} • {product.sub_category}
            </div>
          </div>
          <div className="product-card-body">
            <div className="product-card-title">{title}</div>
            <div className="product-card-description">{description}</div>
          </div>
        </div>
      )}
    </Consumer>
  );
};

ProductCard.propTypes = {
  product: PropTypes.object.isRequired
};

export default ProductCard;
