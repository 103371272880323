export default {
  s3: {
    REGION: "us-east-1",
    BUCKET: "reden-tracking-system-in-qoka"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_r738QDsdH",
    APP_CLIENT_ID: "3ro592qgebcund6fepib41p4rl",
    IDENTITY_POOL_ID: "us-east-1:c89938f6-b8f3-4604-867a-853f5b61d99a"
  }
};
