import React from "react";
import {withRouter} from "react-router-dom";
import { AppBar, DrawerContainer, Main } from "./AppBar/index";
import {Dimmer, Input, Loader} from "semantic-ui-react";
import InvoiceList from './Invoice/list/InvoiceList';
import DrawerItem from "../components/DrawerItems/Navigator/DrawerItem";
import axios from "axios";
import * as API from "../dowhileAPI";
import _ from 'lodash';
import ItemCard from "./Inventory/list/components/ItemCard";
import CustomerCard from "./Customers/list/components/CustomerCard";
import Backdrop from "./AppBar/backdrop/Backdrop";


const styles = {

    root: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        padding: '20px',
    },
    rootHeader: {

        width: '100%',
    },
    rootBody: {
        flex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        padding: '10px 0',
    },
    invoiceContainer: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        marginRight: '20px',
        flex: 1,
        background: 'white',
        borderRadius: '20px',
    },
    inventoryContainer: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        marginRight: '20px',
        flex: 1,
        background: 'white',
        borderRadius: '20px',
    },
    inventoryContainerBody: {
        padding: '20px 0',
        height: '100%',
        overflow: 'auto',
    },
    customerContainer: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        marginRight: '20px',
        flex: 1,
        background: 'white',
        borderRadius: '20px',
    },
    customerContainerBody: {
        padding: '20px 0',
        height: '100%',
        overflow: 'auto',
    },
    header: {
        padding: '15px 20px',
        flex: 0.2,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '20px 20px 0 0',
    },
    headerEmpty: {
        flex: 1,
    },
    headerTitle: {
        fontSize: '2.5em',
        fontWeight: '900',
        color: '#484848',
        display: 'flex',
        alignItems: 'center',
    },
}

class Dashboard extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            user: null,
            redirectTo: "",
            isIn: false,

            sideBarOpen: true,

            // Invoices
            displayResults: [],
            value: '',
            isSearching: false,
            noResults: false,
            invoices: [],
            loading: true,
            loadingError: false,
            errorMessage: "",

            inventory: {
                displayResults: [],
                noResults: false,
                items: [],
                loading: true,
                loadingError: false,
                errorMessage: "",
            },
            customer: {
                noResults: false,
                customers: [],
                loading: true,
                loadingError: false,
                errorMessage: "",
            }
        };
    }

    componentDidMount() {
        this.setState({
            sideBarOpen: window.innerWidth > 748
        })
    };
    _handleSideBarToggle = () => {
        this.setState({
            sideBarOpen: !this.state.sideBarOpen
        })
    };
    _handleUserData = (user) => {

        if (user == null) {

            console.log("USER NOT FOUND FROM APPBAR.");

        } else {

            this.setState({
                user: user
            });

            this._fetchInvoices(user);
            // this._fetchItems();
            this._fetchCustomers(user);
        }
    };

    _fetchInvoices = user => {

        axios({
            method: 'get',
            headers: {'Authorization': user.idToken,
                'tenant_id': user.tenantID },
            url: API.FETCH_ALL_TRACKING_INVOICES
        }).then(res => {


            console.log(res);
            const fetchedInvoices = res.data;
            console.log("fetchedInvoice.length IS: " + fetchedInvoices.length);

            // No response.
            if (fetchedInvoices === null) {

                console.log("fetchedInvoices is NULL");
                this.setState({
                    loading: false,
                    invoices: [],
                    loadingError: true
                });
                return
            }

            // No invoices in database.
            if (fetchedInvoices.length < 1) {

                console.log("fetchedInvoices.length is 0");
                this.setState({
                    invoices: [],
                    loading: false,
                    loadingError: false,
                });
                return
            }

            const sortedInvoices = fetchedInvoices.sort(_handleDateSorting);

            console.log("FETCHING SUCCESSFUL");

            // Fetch successful
            this.setState({
                invoices: sortedInvoices,
                displayResults: sortedInvoices,
                loading: false,
            })

        }).catch( error => {

            console.log("FETCHING ERROR.");
            this.setState({
                invoices: [],
                loading: false,
                loadingError: true,
                errorMessage: error.message
            })
        })
    };
    _fetchCustomers = user => {

        console.log("U CALL FETCH USERI");

        axios({
            method: 'get',
            headers: {'Authorization': user.idToken,
                'tenant_id': user.tenantID },
            url: API.FETCH_ALL_CUSTOMERS
        }).then(res => {


            console.log(res);
            const fetchedCustomers = res.data;

            // No response.
            if (fetchedCustomers === null) {

                console.log("fetchedCustomers is NULL");
                this.setState({
                    customer: {
                        ...this.state.customer,
                        loading: false,
                        customers: [],
                        loadingError: true
                    }
                });
                return
            }

            // No customers in database.
            if (fetchedCustomers.length < 1) {

                console.log("fetchedCustomers.length is 0");
                this.setState({
                    customer: {
                        ...this.state.customer,
                        customers: [],
                        loading: false,
                        loadingError: false,
                    }
                });
                return
            }

            console.log("FETCHING SUCCESSFUL");
            // Fetch successful
            this.setState({
                customer: {
                    ...this.state.customer,
                    customers: fetchedCustomers,
                    displayResults: fetchedCustomers,
                    loading: false,
                }
            })

        }).catch( error => {

            console.log("FETCHING ERROR.");
            this.setState({
                customer: {
                    ...this.state.customer,
                    customers: [],
                    fetchedCustomers: [],
                    loading: false,
                    loadingError: true,
                    errorMessage: error.message
                }
            })
        })
    };



    // SearchBox
    handleSearchChange = (e, { value }) => {

        if (this.state.invoices.length < 1)
            return;

        this.setState({ isSearching: true, value });

        setTimeout(() => {
            if (value.length < 1) {

                this.setState({
                    displayResults: this.state.invoices,
                });

            } else {

                const re = new RegExp(_.escapeRegExp(this.state.value), 'i');
                const isMatch = result => re.test(result.invoice_id || result.customer.name);

                this.setState({
                    isSearching: false,
                    displayResults: _.filter(this.state.invoices, isMatch),
                });
            }
        }, 300)
    };

    // Drawer items click handling.
    _handleOnInvoice = () => {
        this.props.history.push("/invoice");
    };
    _handleOnCustomer = () => {
        this.props.history.push("/customer/list");
    };
    _handleOnFiles = () => {
        this.props.history.push("/files");
    };
    _handleOnInventory = () => {
        this.props.history.push("/inventory");
    };
    _handleOnTracking = () => {
        this.props.history.push("/tracking");
    };



    render() {

        const {
            sideBarOpen,
            user,
            isIn,
            invoices,
            loadingError,
            errorMessage,
            loading,
            displayResults,
            value,
            isSearching,
            inventory,
            customer,
        } = this.state;


        let invoicesToDisplay = isIn
            ? displayResults.filter(invoiceIn)
            : displayResults.filter(invoiceOut);

        let content = (
            <div style={styles.root}>

                <div style={styles.rootHeader}>

                </div>

                <div style={styles.rootBody}>

                    <div style={styles.invoiceContainer}>

                        <div style={styles.header}>
                            <div style={styles.headerEmpty} />
                            <div style={styles.headerTitle}> Invoices </div>
                        </div>

                        <InvoiceList
                            user={user}
                            invoices={invoices}
                            loadingError={loadingError}
                            errorMessage={errorMessage}
                            loading={loading}
                            displayResults={invoicesToDisplay}
                            value={value}
                            linkTo="/tracking/out/invoices/"
                            handleInvoiceClick={this._handleInvoiceClick}
                        />

                    </div>

                    <div style={styles.customerContainer}>
                        <div style={styles.header}>
                            <div style={styles.headerEmpty} />
                            <div style={styles.headerTitle}> Customers </div>
                        </div>
                        <div style={styles.customerContainerBody}>
                            {
                                customer.customers.map( customer =>
                                    <CustomerCard
                                        key={customer.customer_id}
                                        id={customer.customer_id}
                                        name={customer.name}
                                        address={customer.address}
                                        email={customer.email}
                                        phone={customer.phone}
                                        source='customer'
                                        handleSelection={this._handleCustomerSelection}
                                        {...this.props}
                                    />
                                )
                            }
                        </div>
                    </div>

                    <div style={styles.inventoryContainer}>
                        <div style={styles.header}>
                            <div style={styles.headerEmpty} />
                            <div style={styles.headerTitle}> Inventory </div>
                        </div>
                        <div style={styles.inventoryContainerBody}>
                            {
                                inventory.items.map( (item, index) => {
                                    return (
                                        <ItemCard
                                            key={index}
                                            id={item.id}
                                            sku={item.sku}
                                            name={item.name}
                                            category={item.category}
                                            quantity={item.quantity}
                                            price={item.price}
                                            tag={item.tag}
                                            user={user}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>

                </div>

            </div>
        );

        // If not logged in, show loader.
        if (user == null || loading) {
            content = (
                <Dimmer active blurring inverted>
                    <Loader />
                </Dimmer>
            );
        }

        return (
            <div className="Container">

                <AppBar service="invoice"
                        title={isIn ? "Hyrje" : "Dalje"}
                        getUserData={this._handleUserData}
                        handleSideBarToggle={ this._handleSideBarToggle } >

                    <Input
                        className="SearchBox"
                        placeholder='Search for invoice...'
                        loading={isSearching}
                        icon='search'
                        transparent
                        onChange={_.debounce(this.handleSearchChange, 500, { leading: true })}
                    />

                </AppBar>

                <div className="Content">

                    <DrawerContainer
                        open={sideBarOpen}
                        toggle={ this._handleSideBarToggle } >
                        <DrawerItem
                            title="Invoices"
                            iconName="sticky note"
                            iconColor="black"
                            handleClick={this._handleOnInvoice}
                            loading={false}/>

                        <DrawerItem
                            title="Customers"
                            iconName="user"
                            iconColor="black"
                            handleClick={this._handleOnCustomer}
                            loading={false}/>

                        <DrawerItem
                            title="Inventory"
                            iconName="box"
                            iconColor="black"
                            handleClick={this._handleOnInventory}
                            loading={false}/>

                        <DrawerItem
                            title="Files"
                            iconName="file"
                            iconColor="black"
                            handleClick={this._handleOnFiles}
                            loading={false}/>

                        <DrawerItem
                            title="Gjurmimi"
                            iconName="qrcode"
                            iconColor="black"
                            handleClick={this._handleOnTracking}
                            loading={false}/>
                    </DrawerContainer>
                    <Backdrop
                        open={window.innerWidth < 590 && sideBarOpen}
                        toggleBackdrop={ this._handleSideBarToggle }
                    />
                    <Main>
                        { content }
                    </Main>
                </div>

            </div>
        );
    }
}


Dashboard.propTypes = {

};

export default withRouter(Dashboard);

// HELPER FUNCTIONS.
// Sorting
const _handleDateSorting = (invoice1, invoice2) => {
    // This is a comparison function that will result in dates being sorted in
    // ASCENDING order. As you can see, JavaScript's native comparison operators
    // can be used to compare dates. This was news to me.

    const date1 = new Date(invoice1.date);
    const date2 = new Date(invoice2.date);

    if (date1 > date2) return -1;
    if (date1 < date2) return 1;
    return 0;
};
const invoiceIn = invoice => {
    return invoice.isIn === true;
};
const invoiceOut = invoice => {
    // Return invoices where isIn is false or non existent.
    return invoice.isIn === false || !('isIn' in invoice);
};