import React from 'react';
import {Consumer} from '../context';

const styles = {
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    formContainer: {
        flexGrow: 1,
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '20px',
        boxShadow: '0 5px 20px #b9b9b9',
    },
    formContainerHeader: {
        padding: '15px 20px',
        flex: 0.2,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '20px 20px 0 0',
        backgroundImage: 'linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)'
    },
    headerEmpty: {
        flex: 1,
    },
    headerTitle: {
        fontSize: '2.5em',
        fontWeight: '900',
        color: '#484848',
        display: 'flex',
        alignItems: 'center',
    },
    formContainerBody: {
        padding: '15px',
        flex: 0.7,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexGrow: 1,
        overflowY: 'auto',
    },
    formInput: {
        height: '35px',
        borderRadius: '5px',
        border: 'none',
        boxShadow: '0 1px 1px 0 #cfd9df',
        fontFamily: 'Lato, sans-serif',
        fontSize: '1em',
        padding: '0 6px',
        background: '#d3d3d3',
        width: '100%',
    },
    inputContainer: {
        margin: '10px 0',
        width: '70%',
    },
    messageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'center',
        width: '40%',
        height: '20%',
    },
    message: {
        display: 'flex',
        flex: 0.45,
        flexDirection: 'column',
        justifySelf: 'center',
        alignSelf: 'center',
    }
};

const InventoryForm = props => {

    return (
        <div style={styles.root}>
            <Consumer>
                {context =>
                    (
                        <div style={styles.formContainer}>

                            <div style={styles.formContainerHeader}>
                                <div style={styles.headerEmpty} />
                                <div style={styles.headerTitle}>
                                    Inventory
                                </div>
                            </div>

                            <div style={styles.formContainerBody}>
                                <div style={styles.inputContainer}>
                                    <input
                                        style={{...styles.formInput,
                                            background: context.errors.locationIDEmpty ? 'indianred' : '#d3d3d3'}}
                                        placeholder='Location'
                                        type='text'
                                        value={context.inventory.location_id}
                                        onChange={ (e) => {context.actions.changeLocation(e)} }
                                    />
                                </div>
                                <div style={styles.inputContainer}>
                                    <input
                                        style={{...styles.formInput,
                                            background: context.errors.quantityEmpty ? 'indianred' : '#d3d3d3'}}
                                        placeholder='Quantity'
                                        type='text'
                                        value={context.inventory.quantity}
                                        onChange={ (e) => {context.actions.changeQuantity(e)} }
                                    />
                                </div>
                                <div style={styles.inputContainer}>
                                    <input
                                        style={styles.formInput}
                                        placeholder='Starting quantity'
                                        type='text'
                                        value={context.inventory.starting_qnt}
                                        onChange={ (e) => {context.actions.changeStartingQuantity(e)} }
                                    />
                                </div>
                                <div style={styles.inputContainer}>
                                    <input
                                        style={styles.formInput}
                                        placeholder='Minimum quantity'
                                        type='text'
                                        value={context.inventory.minimum_qnt}
                                        onChange={ (e) => {context.actions.changeMinimumQuantity(e)} }
                                    />
                                </div>
                                <div style={styles.inputContainer}>
                                    <input
                                        style={styles.formInput}
                                        placeholder='Starting Quantity Date'
                                        type='text'
                                        value={context.inventory.starting_qnt_date}
                                        onChange={ (e) => {context.actions.changeStartingQuantityDate(e)} }
                                    />
                                </div>
                            </div>
                        </div>
                    )
                }
            </Consumer>
        </div>
    );
}

export default InventoryForm;
