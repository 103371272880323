import React from "react";
import axios from "axios/index";
import * as API from "../../dowhileAPI";
import { Input, Message } from "semantic-ui-react";
import { AppBar, DrawerContainer, Main } from "../AppBar/index";
import { Button } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import DrawerItem from "../../components/DrawerItems/Navigator/DrawerItem";
import GoBack from "../../components/GoBack";
import InvoiceSectionHeader from "../../components/Tracking/invoices/InvoiceSectionHeader";
// Network imports
import { Auth } from "aws-amplify";

const styles = {
  root: {
    width: "100vw",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  header: {
    width: window.innerWidth < 768 ? "100%" : "70%",
  },
  formContainer: {
    width: window.innerWidth < 768 ? "100%" : "70%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  formInput: {
    margin: "10px",
    width: window.innerWidth < 768 ? "80%" : "40%"
  },
  action: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  primaryButton: {
    flex: 0.9,
    alignSelf: "center",
    margin: "8px",
    maxWidth: "200px",
    borderRadius: "20px",
    color: "black",
    boxShadow: "0 10px 30px 3px #b9b9b9",
    backgroundImage: "linear-gradient(to right, #43e97b 0%, #38f9d7 100%)"
  }
};

class NewCustomer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sideBarOpen: true,

      uploadCompletedPortion: 0,
      uploadSuccessful: false,
      uploadErrorMessage: "",
      showSuccessMessage: false,
      showFailureMessage: false,
      customerUploading: false,

      // INVOICE FORM DATA
      id: "",
      name: "",
      address: "",
      email: "",
      phone: "",

      idError: false,
      nameError: false,
      addressError: false,
      emailError: false,
      phoneError: false,

      // AUTH
      user: ""
    };
  }

  componentDidMount() {
    if (window.innerWidth < 768) this.setState({ sideBarOpen: false });
  }

  _handleSideBarToggle = () => {
    this.setState({ sideBarOpen: !this.state.sideBarOpen });
  };

  _handleUserData = user => {
    console.log("USER DATA FROM APPBAR-> ");
    console.log(user);
    this.setState({
      user: user
    });
  };

  // INVOICE FORM DATA HANDLERS
  _handleIdChange = event => {
    this.setState({
      id: event.target.value
    });
  };
  _handleNameChange = event => {
    this.setState({
      name: event.target.value
    });
  };
  _handleAddressChange = event => {
    this.setState({
      address: event.target.value
    });
  };
  _handleEmailChange = event => {
    this.setState({
      email: event.target.value
    });
  };
  _handlePhoneChange = event => {
    this.setState({
      phone: event.target.value
    });
  };
  _validateForm = () => {
    const { id, name } = this.state;

    let idError = id === "";
    let nameError = name === "";

    // If one of the fields is empty, update error state for all fields.
    if (idError || nameError)
      this.setState({
        idError,
        nameError
      });
    else
      this._uploadCustomerHTTP()
        .then(this._handleHTTPResponse)
        .catch(error => {
          this.setState({
            showFailureMessage: true,
            uploadErrorMessage: error.message,
            customerUploading: false
          });
        }); // upload customer
  };

  // Drawer item handlers.
  _handleNewCustomer = () => {
    window.location.reload();
  };

  // Upload Customer
  async _uploadCustomerHTTP() {
    this.setState({
      customerUploading: true
    });

    const { id, name, address, email, phone } = this.state;

    const data = {
      customer: {
        customer_id: id,
        name: name,
        address: address,
        email: email,
        phone: phone
      }
    };

    const token = await Auth.currentSession()
      .then(data => {
        const token = data.idToken.jwtToken;
        if (token) return token;
        return null;
      })
      .catch(err => console.log(err));
    if (!token) return;

    const config = {
      headers: {
        Authorization: token,
        tenant_id: "reden_01"
      },
      onUploadProgress: progressEvent => {
        let percentageCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (percentageCompleted === 100)
          this.setState({ uploadCompletedPortion: 0 });
        this.setState({ uploadCompletedPortion: percentageCompleted });
      }
    };

    return axios.post(API.ADD_CUSTOMER, data, config);
  }
  _handleHTTPResponse = response => {
    const data = JSON.parse(response.data.body);

    if (data.UploadSuccessful)
      this.setState({ showSuccessMessage: true, customerUploading: false });
    else {
      this.setState({
        showFailureMessage: true,
        uploadErrorMessage: data.Error,
        customerUploading: false
      });
    }
  };

  renderGoBack = () => {
    return (
      <GoBack
        onClick={() => this.props.history.push("/tracking/customers/")}
        color="black"
        title="Te gjithë klientët"
      />
    );
  };

  render() {
    const {
      sideBarOpen,
      idError,
      nameError,
      addressError,
      emailError,
      phoneError,
      uploadErrorMessage,
      customerUploading,
      showSuccessMessage,
      showFailureMessage
    } = this.state;

    let content;

    // Upload complete, server is OK.
    if (showSuccessMessage) {
      content = (
        <div style={styles.formContainer}>
          <Message
            success
            header="Got it!"
            content="You may now go back to the list of customers."
          />
        </div>
      );
    }
    // Upload complete but server returns error.
    if (showFailureMessage) {
      content = (
        <div style={styles.formContainer}>
          <Message
            negative
            header="We had a problem with that."
            content={uploadErrorMessage}
          />
        </div>
      );
    }

    if (!showFailureMessage && !showSuccessMessage) {
      content = (
        <div style={styles.formContainer}>
          <Input
            style={styles.formInput}
            placeholder="ID"
            type="text"
            icon="tag"
            iconPosition="left"
            error={idError}
            onChange={this._handleIdChange}
          />
          <Input
            style={styles.formInput}
            placeholder="Name"
            type="text"
            icon="user"
            iconPosition="left"
            error={nameError}
            onChange={this._handleNameChange}
          />
          <Input
            style={styles.formInput}
            placeholder="Address"
            type="text"
            icon="map marker alternate"
            iconPosition="left"
            error={addressError}
            onChange={this._handleAddressChange}
          />
          <Input
            style={styles.formInput}
            placeholder="Email"
            type="text"
            icon="mail"
            iconPosition="left"
            error={emailError}
            onChange={this._handleEmailChange}
          />
          <Input
            style={styles.formInput}
            placeholder="Phone"
            type="text"
            icon="phone"
            iconPosition="left"
            error={phoneError}
            onChange={this._handlePhoneChange}
          />
          <div style={styles.action}>
            <Button
              disabled={false}
              style={styles.primaryButton}
              onClick={this._validateForm}
            >
              Save
            </Button>
          </div>
        </div>
      );
    }
    return (
      <div style={styles.root}>
        <div style={styles.header}>
          <InvoiceSectionHeader
            title="Klient i ri"
            renderGoBack={this.renderGoBack}
          />
        </div>
        {content}
      </div>
    );
  }
}

export default withRouter(NewCustomer);
