import React from "react";
import ItemForm from "./ItemForm";
import InventoryItemSimple from "../../../Inventory/InventoryItemSimple";
import InvoiceFormHeader from "../../../../components/Tracking/invoices/new/InvoiceFormHeader";

// Redux imports
import { connect } from "react-redux";
import "./item-list-form.css";

const ItemListForm = ({ newInvoice }) => {
  const { items } = newInvoice;
  let total = items.reduce((sum, item) => {
    return (sum += parseInt(item.total));
  }, 0);
  return (
    <div className="item-list-form-container">
      <InvoiceFormHeader title={`Produktet - ${total}  ALL`} />
      <div className="item-list-form-list">
        <ItemForm />
        <div className="item-list-form-list-container">
          <div className="item-list-form-list">
            {items.map((item, index) => {
              return (
                <InventoryItemSimple
                  key={index}
                  description={item.description}
                  price={item.price * item.quantity}
                  quantity={item.quantity}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { newInvoice } = state.invoice;
  return { newInvoice };
};

export default connect(mapStateToProps)(ItemListForm);
