/**
 * Copyright Redi Kurti, 2019. All rights reserved.
 */

// React imports
import React from "react";
import { useHistory } from "react-router-dom";

// Redux imports
import {
  addFile,
  addExplosive,
  addExplosiveList
} from "../../../redux/actions/TrackingActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Other component imports
import DrawerItemMainAction from "../../../../components/DrawerItems/MainAction/DrawerItemMainAction";
import XmlFileList from "../../../../components/Tracking/invoices/in/XmlFileList";
import InvoiceSectionHeader from "../../../../components/Tracking/invoices/InvoiceSectionHeader";
import GoBack from "../../../../components/GoBack";

// Util imports
import parser from "fast-xml-parser";
import pako from "pako";
// Utils
import {
  extractExplosivesThatAreItems,
  groupExplosivesByProductCode
} from "../../../../utils/Invoice";

// CSS imports
import "./xml.css";

const Xml = props => {
  let history = useHistory();
  const { invoice, filesArray } = props;

  const renderGoBack = () => {
    return (
      <GoBack
        onClick={() =>
          history.push(
            "/tracking/invoices/" +
              (invoice.isIn ? "in" : "out") +
              "/" +
              invoice.invoice_id +
              "/invoice"
          )
        }
        color="black"
        title={`Fatura ${invoice.invoice_id}`}
      />
    );
  };

  const renderAction = () => {
    return (
      <DrawerItemMainAction
        title="Add new xml"
        iconName="plus"
        iconColor="white"
        iconSize={24}
        handleClick={handleNewXml}
      />
    );
  };

  const handleNewXml = () => {
    const fileSelector = document.createElement("input");
    fileSelector.setAttribute("type", "file");
    fileSelector.setAttribute("multiple", "multiple");
    fileSelector.setAttribute("name", "file");
    fileSelector.setAttribute("accept", "text/xml");
    fileSelector.addEventListener("change", _onFileSelected);
    window.setTimeout(() => fileSelector.click(), 50);
  };

  const _onFileSelected = e => {
    // Get all selected files.
    const selectedFiles = e.target.files;

    // return; if there was no selection.
    if (selectedFiles.length < 1) {
      return;
    }

    for (let i = 0; i < selectedFiles.length; i++) {
      let file = selectedFiles[i];
      const fileSummary = {
        name: file.name,
        invoice_id: props.invoice.invoice_id,
        summaryItems: [],
        loading: true
      };
      props.addFile(fileSummary, props.invoice.invoice_id);
      _loadXML(file);
    }
  };

  const _loadXML = file => {
    let text = "";
    const reader = new FileReader();
    reader.onload = event => {
      text = reader.result;
      _readXML(text, file);
    };
    reader.readAsText(file);
  };

  const _readXML = (xmlText, file) => {
    // Parse the text of XML document.
    const result = parser.validate(xmlText);
    if (result !== true) console.log(result.err);
    var options = {
      attributeNamePrefix: "",
      attrNodeName: "attr", //default is 'false'
      textNodeName: "#text",
      ignoreAttributes: false,
      ignoreNameSpace: false,
      allowBooleanAttributes: false,
      parseNodeValue: true,
      parseAttributeValue: false,
      trimValues: true,
      cdataTagName: "__cdata", //default is 'false'
      cdataPositionChar: "\\c",
      localeRange: "", //To support non english character in tag/attribute values.
      parseTrueNumberOnly: false,
      arrayMode: false, //"strict"
      //   attrValueProcessor: (val, attrName) =>
      //     he.decode(val, { isAttributeValue: true }), //default is a=>a
      //   tagValueProcessor: (val, tagName) => he.decode(val), //default is a=>a
      stopNodes: ["parse-me-as-string"]
    };
    const res = parser.parse(xmlText, options);
    const shipment = res.Shipment;
    // Summary items, converted from array to object with full info.
    let summaryItemsRaw = shipment.SummaryItems.SummaryItem;
    if (!Array.isArray(summaryItemsRaw)) summaryItemsRaw = [summaryItemsRaw];
    const summaryItems = summaryItemsRaw.reduce((allItems, item) => {
      return {
        ...allItems,
        [item.attr.SID]: {
          sid: item.attr.SID,
          psn: item.attr.PSN,
          productCode: item.ProducerProductCode,
          productName: item.ProducerProductName,
          itemQuantity: item.ItemQuantity
        }
      };
    }, {});

    // Sender
    const sender = {
      code: shipment.Sender.Code,
      name: shipment.Sender.Name
    };

    // Receiver
    const receiver = {
      customer_id: "AL",
      name: shipment.Receiver.Name
    };

    const messageId = file.name.split(".")[0];

    const fileSummary = {
      name: file.name,
      invoice_id: invoice.invoice_id,
      summaryItems,
      loading: false,
      sender,
      receiver,
      messageId
    };

    props.addFile(fileSummary, invoice.invoice_id);

    // Units
    let unitsRaw = shipment.Units.Unit;
    if (!Array.isArray(unitsRaw)) unitsRaw = [unitsRaw];
    const units = unitsRaw.map(unit => {
      return constructUnit(
        unit,
        [],
        summaryItems,
        file.name,
        invoice.invoice_id
      );
    });

    const items = extractExplosivesThatAreItems(units);
    const invoice2 = {
      invoice_id: messageId,
      // date: invoice.date,
      customer: receiver,
      summaryItems,
      explosives: items.map(item => {
        return {
          itemQuantity: item.itemQuantity,
          uid: item.uid,
          parentLine: item.parentLine,
          sid: item.sid
        };
      })
    };

    // Save json file
    handleSaveToPC(invoice2, messageId);
    props.addExplosiveList(units, invoice.invoice_id);
  };

  const handleSaveToPC = (jsonData, invoice_id) => {
    const fileData = JSON.stringify(jsonData);
    const blob = new Blob([fileData], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "JSON-" + invoice_id + ".json";
    link.href = url;
    link.click();
  };

  const constructUnit = (unit, parentLine, summaryItems, file, invoice) => {
    const uid = unit.attr.UID;
    const psn = unit.attr.PSN;
    const countOfTradeUnits = unit.CountOfTradeUnits;
    const itemQuantity = unit.ItemQuantity;
    let product = {};
    let units = [];

    let children = unit.Units ? unit.Units.Unit : unit.Items.Item;
    if (!Array.isArray(children)) children = [children];
    if (unit.Units) {
      units = children.map(childUnit => {
        return constructUnit(
          childUnit,
          [...parentLine, uid],
          summaryItems,
          file,
          invoice
        );
      });
    } else {
      units = children.map(childItem => {
        const childItemUid = childItem.attr.UID;
        const psn = childItem.attr.PSN;
        const sid = childItem.attr.SID;
        const iQ = parseFloat(
          parseFloat(itemQuantity) / parseFloat(countOfTradeUnits)
        );
        const { productCode, productName } = summaryItems[sid];
        return {
          uid: childItemUid,
          psn,
          product: {
            code: productCode,
            name: productName
          },
          sid,
          isItem: true,
          parentLine: [...parentLine, uid],
          itemQuantity: iQ,
          invoice,
          file
        };
      });
    }

    product = units[0].product;

    const newItem = {
      uid,
      psn,
      countOfTradeUnits,
      itemQuantity,
      product,
      units,
      parentLine,
      file,
      invoice
    };

    return newItem;
  };

  const extractExplosivesForDynamoDB = explosives => {
    if (!explosives || !explosives.length || explosives.length === 0) return [];
    let explosivesOnThisLevelAndTheirChildren = [];
    for (let explosive of explosives) {
      if (explosive.isItem) {
        return explosives;
      } else {
        const children = extractExplosivesForDynamoDB(explosive.units);
        const lighterDirectChildren = getDirectChildren(explosive, children);
        explosivesOnThisLevelAndTheirChildren.push(
          ...[{ ...explosive, units: lighterDirectChildren }, ...children]
        );
      }
    }
    return explosivesOnThisLevelAndTheirChildren;
  };

  const makeExplosiveLighter = explosive => {
    if (explosive.isItem) {
      return explosive.uid;
    } else {
      return {
        uid: explosive.uid,
        units: explosive.units
      };
    }
  };

  const getDirectChildren = (explosive, children) => {
    const directChildren = children.filter(
      child => child.parentLine[child.parentLine.length - 1] === explosive.uid
    );
    return directChildren.map(makeExplosiveLighter);
  };

  /**
   * Recursive function that groups items based on their product code.
   * @param {Array} explosives the array of explosives
   * @return {Array} an array of items grouped by product code.
   */
  const mapScannedExplosives = explosives => {
    const mappedItems = explosives.reduce((mapOfProducts, explosive) => {
      let product = mapOfProducts.get(explosive.product.code);
      const normalizedExplosive = {
        uid: explosive.uid,
        countOfTradeUnits: explosive.countOfTradeUnits,
        itemQuantity: explosive.itemQuantity,
        units: explosive.units
      };
      if (product) {
        product = {
          ...product,
          units: [...product.units, normalizedExplosive],
          itemQuantity:
            (product.itemQuantity || 0) + (explosive.itemQuantity || 0)
        };
      } else {
        product = {
          uid:
            "inv" +
            "+" +
            explosive.product.code +
            "+" +
            explosive.invoice +
            "+" +
            explosive.file,
          ...explosive.product,
          psn: explosive.psn,
          units: [normalizedExplosive],
          itemQuantity: explosive.itemQuantity || 0
        };
      }
      mapOfProducts.set(explosive.product.code, product);
      return mapOfProducts;
    }, new Map());

    return Array.from(mappedItems.values()).map(item => {
      const binaryString = pako.deflate(JSON.stringify(item.units), {
        to: "string"
      });
      return {
        ...item,
        units: binaryString
      };
    });
  };

  return (
    <div className="tracking-in-invoices-invoice-root">
      <div className="tracking-in-invoices-invoice-content">
        <InvoiceSectionHeader
          title="XML"
          renderAction={renderAction}
          renderGoBack={renderGoBack}
        />
        <div className="tracking-in-invoices-invoice-body">
          <XmlFileList files={filesArray} />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addFile,
      addExplosive,
      addExplosiveList
    },
    dispatch
  );

const mapStateToProps = (state, ownProps) => {
  const { invoices } = state.tracking;
  const myInvoice = invoices[ownProps.invoice_id];
  const filesArray = myInvoice.files
    ? Object.keys(myInvoice.files).map(key => myInvoice.files[key])
    : [];
  return { invoice: myInvoice, filesArray };
};

export default connect(mapStateToProps, mapDispatchToProps)(Xml);
