import React from 'react';
import {Consumer} from "../context";
import InventoryList from './list/InventoryList';
import Backdrop from '../AppBar/backdrop/Backdrop';
import Main from '../AppBar/main/Main';
import DrawerContainer from '../AppBar/drawer/DrawerContainer';
import DrawerItem from '../../components/DrawerItems/Navigator/DrawerItem';
import ProductCard from '../../components/Inventory/ProductCard';
import ColumnHeaderList from '../../components/ColumnHeaderList';

const Catalog = props => {

    return (
        <Consumer>
            { context => (
                <div className="Content">
                    <DrawerContainer
                        open={context.sideBarOpen}
                        toggle={context.actions.handleSideBarToggle}>
                        <DrawerItem
                            title="New Item"
                            iconName="add"
                            iconSize="large"
                            iconColor="black"
                            handleClick={context.actions.showCatalogCreate}
                            primaryPositive
                        />
                        <DrawerItem
                            title="Catalog"
                            iconName="paper"
                            iconSize="large"
                            iconColor="black"
                            handleClick={context.actions.showCatalog} 
                        />
                    </DrawerContainer>
                    <Backdrop
                        open={window.innerWidth < 590 && context.sideBarOpen}
                        toggleBackdrop={context.actions.handleSideBarToggle}
                    />
                    <Main>
                        <div className="catalog-container"> 
                            <div className="catalog-header">
                        
                            </div>
                            <div className="catalog-body">
                                <ColumnHeaderList headerTitle="Catalog" headerAction={context.actions.showCatalogCreate}>
                                    { context.items.map( (product, index) =>
                                        <ProductCard key={index} product={product} /> )}
                                </ColumnHeaderList>
                            </div>       
                        </div>    
                    </Main>
                </div>
            )}
        </Consumer>
    )
}

export default Catalog;