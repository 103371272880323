import React, {Component} from 'react';
import {AppBar, DrawerContainer, Main} from "../../AppBar";
import {Provider} from "../../context";
import {Dimmer, Loader} from "semantic-ui-react";
import axios from "axios";
import * as API from "../../../dowhileAPI";
import ProductForm from "../ProductForm";
import InventoryForm from "../InventoryForm";



const styles = {
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        padding: '20px',
    },
    header: {
        display: 'flex',
        flex: '0.1',
        width: '100%',
        fontSize: '3.5em',
        fontWeight: '900',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    body: {
        display: 'flex',
        justifyContent: 'space-between',
        flex: 1,
        width: '100%',
        height: '100%',
        flexDirection: 'row',
        padding: '20px 0',
    },
    columnContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexGrow: 1,
        padding: '0 10px',
    },
    Submit: {
        border: 'none',
        borderRadius: '0 20px 20px 0',
        padding: '5px',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: 'linear-gradient(to right, #43e97b 0%, #38f9d7 100%)',
        boxShadow: '0 5px 10px #cfd9df',
        color: '#484848',
        fontWeight: '700',
        fontSize: '1em',
        transition: 'box-shadow 300ms ease-in-out',
    },
    invoiceDrawer: {
        fontFamily: "sans-serif",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        flexGrow: "1",
        paddingTop: "120px",
    },
};

class InventoryView extends Component {

    constructor(props) {
        super(props);

        this.state = {

            user: {},
            id: "",
            loading: false,
            sideBarOpen: true,

            inventory: {
                location_id: "",
                quantity: "",
                minimum_qnt: "",
                starting_qnt: "",
                starting_qnt_date: "",
            },
            product: {
                id: "",
                name: "",
                price: "",
                tag: "",
                category_id: "",
                notes: "",
            },
            errors:{
                idEmpty: false,
                nameEmpty: false,
                priceEmpty: false,
                categoryIDEmpty: false,
                locationIDEmpty: false,
                quantityEmpty: false,
                startingQuantityEmpty: false,
            }
        }
    }

    componentWillMount() {
        // Get the <id> of the item from the url.
        const id = this.props.location.search.split('=')[1];

        console.log("SELECTED ITEM ID", id);

        this.setState({
            id: id,
        });

    }

    _handleUserData = (user) => {

        // TODO: Remove debugging message.
        console.log("USER DATA FROM APPBAR-> ");
        console.log(user);

        if (user == null) {

            console.log("USER NOT FOUND.");

        } else {

            this.setState({
                user: user
            });

            this._fetchItem();
        }
    };

    _fetchItem = () => {

        this.setState({
            loading: true,
        });

        const { user, id } = this.state;

        axios({
            method: 'post',
            url: API.GET_ITEM,
            headers: {
                Authorization: user.idToken,
                "tenant_id": user.tenantID
            },
            data: {
                id
            }
        }).then(res => {
            console.log("SUCCESS! ITEM:", res.data.items[0]);
            if (res.data.status === 201) {

                const item = res.data.items[0];
                const product = {
                    id: item.sku,
                    name: item.name,
                    price: item.price,
                    tag: item.tag,
                    category_id: item.category,
                    notes: item.notes,
                };
                const inventory = {
                    location_id: item.location_id,
                    quantity: item.quantity,
                    minimum_qnt: item.minimum_qnt,
                    starting_qnt: item.starting_qnt,
                    starting_qnt_date: item.starting_qnt_date,
                }

                this.setState({
                    product,
                    inventory,
                    loading: false,
                    error: null,
                })
            } else {

                console.log("ERROR FROM SERVER", res.data.error);

                this.setState({
                    item: {},
                    loading: false,
                    error: res.data.error
                })
            }
        }).catch( error => {

            console.log("CLIENT ERROR", error);
            this.setState({
                item: {},
                loading: false,
                error: error
            })
        })




    };

    // Handle inventory Update
    _handleInventoryUpdate = () => {


        console.log("UPDATE THIS SUNOVABIQ");
    };

    // HANDLE CHANGE IN INPUTS OF THE PRODUCT FORM.
    // These functions update the product object of the class state.
    _handleIDChange = event => {
        this.setState({
            product: {
                ...this.state.product,
                id: event.target.value,
            }
        })
    };
    _handleNameChange = event => {
        this.setState({
            product: {
                ...this.state.product,
                name: event.target.value,
            }
        })
    };
    _handlePriceChange = event => {
        this.setState({
            product: {
                ...this.state.product,
                price: event.target.value.replace(/[^0-9.]/g,''),
            }
        })
    };
    _handleTagChange = tag => {
        console.log(tag);
        this.setState({
            product: {
                ...this.state.product,
                tag: tag,
            }
        })
    };
    _handleCategoryChange = category => {
        this.setState({
            product: {
                ...this.state.product,
                category_id: category.target.value,
            }
        })
    };
    _handleNotesChange = event => {
        this.setState({
            product: {
                ...this.state.product,
                notes: event.target.value,
            }
        })
    };

    // HANDLE CHANGE IN INPUTS OF THE INVENTORY FORM
    // These functions update the inventory object of the class state.
    _handleLocationChange = event => {
        console.log(event);
        this.setState({
            inventory: {
                ...this.state.inventory,
                location_id: event.target.value,
            }
        })
    };
    _handleQuantityChange = event => {
        this.setState({
            inventory: {
                ...this.state.inventory,
                quantity: event.target.value.replace(/[^0-9.]/g,''),
            }
        })
    };
    _handleStartingQuantityChange = event => {
        this.setState({
            inventory: {
                ...this.state.inventory,
                starting_qnt: event.target.value.replace(/[^0-9.]/g,''),
            }
        })
    };
    _handleMinimumQuantityChange = event => {
        this.setState({
            inventory: {
                ...this.state.inventory,
                minimum_qnt: event.target.value.replace(/[^0-9.]/g,''),
            }
        })
    };
    _handleStartingQuantityDateChange = event => {
        this.setState({
            inventory: {
                ...this.state.inventory,
                starting_qnt_date: event.target.value,
            }
        })
    };


    _handleSideBarToggle = () => {
        this.setState({
            sideBarOpen: !this.state.sideBarOpen
        })
    };

    render() {

        const { inventory, product, sideBarOpen, loading, errors } = this.state;



        return (

                <div className="Container">
                    <AppBar
                        service="inventory"
                        title="Artikujt"
                        getUserData={this._handleUserData}
                        handleSideBarToggle={ this._handleSideBarToggle } />


                    {
                        loading && (
                            <Dimmer active blurring inverted>
                                <Loader/>
                            </Dimmer>
                        )
                    }
                    <div className="Content">
                        {
                            sideBarOpen &&
                            <DrawerContainer anchor="left">
                                <div className="CustomerDrawer">

                                    <div
                                        style={styles.Submit}
                                        onClick={this._handleInventoryUpdate}
                                    >
                                        Save Changes
                                    </div>
                                </div>
                            </DrawerContainer>
                        }
                        <Main>
                            <Provider value={{
                                product,
                                inventory,
                                errors,
                                actions: {
                                    // Product actions
                                    changeID: this._handleIDChange,
                                    changeName: this._handleNameChange,
                                    changePrice: this._handlePriceChange,
                                    changeCategory: this._handleCategoryChange,
                                    changeTag: this._handleTagChange,
                                    changeNotes: this._handleNotesChange,
                                    // Inventory actions
                                    changeLocation: this._handleLocationChange,
                                    changeQuantity: this._handleQuantityChange,
                                    changeMinimumQuantity: this._handleMinimumQuantityChange,
                                    changeStartingQuantity: this._handleStartingQuantityChange,
                                    changeStartingQuantityDate: this._handleStartingQuantityDateChange,
                                }
                            }}>
                                <div style={styles.container}>
                                    <div style={styles.header}>
                                        <div> Update item: {product.name} </div>

                                    </div>
                                    <div style={styles.body}>
                                        <div style={styles.columnContainer}>
                                            <ProductForm />
                                        </div>

                                        <div style={styles.columnContainer}>
                                            <InventoryForm />

                                        </div>

                                    </div>
                                </div>
                            </Provider>
                        </Main>
                    </div>
                </div>
        );
    }
}

InventoryView.propTypes = {};

export default InventoryView;

