import React from "react";
import { formatDate } from "../../utils/Formatter";
import "./barcode-info-card.css";

const BarcodeInfoCard = ({ invoice }) => {
  return (
    <div className="barcode-info-card-root">
      <div className="barcode-info-card-header">
        <div className="barcode-info-card-title">
          {formatDate(invoice.date)}
        </div>
      </div>
      <div className="barcode-info-card-body">
        <div className="invoice-card-customer-amount">
          Fatura {invoice.invoice_id}
        </div>
      </div>
    </div>
  );
};

export default BarcodeInfoCard;
