/**
 * Copyright Redi Kurti, 2019. All rights reserved.
 */

// React imports
import React from "react";

// Redux imports
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";

// Other component imports
import ProductList from "../../../../components/Tracking/invoices/in/ProductList";
import InvoiceSectionHeader from "../../../../components/Tracking/invoices/InvoiceSectionHeader";
import GoBack from "../../../../components/GoBack";

const Products = props => {
  let history = useHistory();
  const { invoice, productArray } = props;

  const renderGoBack = () => {
    return (
      <GoBack
        onClick={() =>
          history.push(
            "/tracking/invoices/" +
              (invoice.isIn ? "in" : "out") +
              "/" +
              invoice.invoice_id +
              "/invoice"
          )
        }
        color="black"
        title={`Fatura ${invoice.invoice_id}`}
      />
    );
  };

  return (
    <div className="tracking-in-invoices-invoice-root">
      <div className="tracking-in-invoices-invoice-content">
        <InvoiceSectionHeader title="Products" renderGoBack={renderGoBack} />
        <div className="tracking-in-invoices-invoice-body">
          <ProductList products={productArray} />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

const mapStateToProps = (state, ownProps) => {
  console.log(state.tracking);
  const { invoices } = state.tracking;
  const myInvoice = invoices[ownProps.invoice_id];
  const filesArray = myInvoice.files
    ? Object.keys(myInvoice.files).map(key => myInvoice.files[key])
    : [];

  const products = filesArray.reduce((products, file) => {
    const summaryItemsOnFile = Object.keys(file.summaryItems).map(
      sid => file.summaryItems[sid]
    );

    let summaryItem, productsOnFile;
    for (summaryItem of summaryItemsOnFile) {
      const oldProduct = products[summaryItem.productCode];
      if (oldProduct) {
        productsOnFile = {
          ...productsOnFile,
          [summaryItem.productCode]: {
            ...oldProduct,
            itemQuantity: oldProduct.itemQuantity + summaryItem.itemQuantity
          }
        };
      } else {
        console.log(
          "Product does not exist. Creating" + summaryItem.productCode
        );
        productsOnFile = {
          ...productsOnFile,
          [summaryItem.productCode]: summaryItem
        };
      }
    }
    return {
      ...products,
      ...productsOnFile
    };
  }, {});

  const productArray = Object.keys(products).map(
    productCode => products[productCode]
  );

  console.log("productArray", productArray);
  return { invoice: myInvoice, filesArray, productArray };
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
