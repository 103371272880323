import {
  SET_ACTIVE_DRAWER_ITEM,
  INVOICE_IN_ADD_FILE,
  INVOICE_IN_REMOVE_FILE,
  TRACKING_INVOICE_ADD_EXPLOSIVE,
  TRACKING_INVOICE_ADD_EXPLOSIVE_LIST,
  TRACKING_INVOICE_SET_LOADING,
  ADD_INVOICE
} from "../types";

// Network imports.
import axios from "axios";
import pako from "pako";
import * as API from "../../../utils/API";

export const setActiveDrawerItem = index => ({
  type: SET_ACTIVE_DRAWER_ITEM,
  index
});

export const addExplosive = explosive => ({
  type: TRACKING_INVOICE_ADD_EXPLOSIVE,
  explosive
});

export const addExplosiveList = (explosiveList, invoice_id) => ({
  type: TRACKING_INVOICE_ADD_EXPLOSIVE_LIST,
  explosiveList,
  invoice_id
});

export const addFile = file => ({
  type: INVOICE_IN_ADD_FILE,
  file
});

export const removeFile = file => ({
  type: INVOICE_IN_REMOVE_FILE,
  file
});

export const fetchInvoices = (
  auth,
  tracking_status = "pending",
  last_update = 0
) => {
  return dispatch => {
    const params = {
      method: "post",
      headers: {
        Authorization:
          "eyJraWQiOiJCcjJqdW4xNHVCNEF2RzJ1Q282K3ArVFRpK29lSFNJMnJ3UUYyV2FFY1BJPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJmOTY1ZWFiYi00YmFlLTQyNjMtYTBhMS0wZGQ2NTRiYWUwNTkiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfcjczOFFEc2RIIiwiY29nbml0bzp1c2VybmFtZSI6ImY5NjVlYWJiLTRiYWUtNDI2My1hMGExLTBkZDY1NGJhZTA1OSIsImN1c3RvbTp0ZW5hbnRfaWQiOiJyZWRlbl8wMSIsImF1ZCI6IjNybzU5MnFnZWJjdW5kNmZlcGliNDFwNHJsIiwiZXZlbnRfaWQiOiIwYTA3N2NhNi0xOTNjLTQ5N2YtOTZiNi1hYzY5YTM5NTI2YTAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTU3NzY0MzI2NiwibmFtZSI6IlJlZGkiLCJwaG9uZV9udW1iZXIiOiIrMTIzNDU2IiwiZXhwIjoxNTc3NjQ2ODY2LCJpYXQiOjE1Nzc2NDMyNjYsImVtYWlsIjoicmVkaWt1cnRpLnNAZ21haWwuY29tIn0.BjWNLcU_XzVCpE3uzv0rF6UkNqZFpYyvRAfNalUrqlkzkpRnoFNBGyy8J630C2EyUjoJet2_gGXFsP-WVdP1_O9pRBVFN0xsVWJOb52xF3QNppSPwHJF2gFEnwI-dI54Sw77gJz97y-MAS6h56o4FEW-rKfczs0ArS8a58JoKan-1XmNgSEGL4W6DZh4oBvWnXAMR2aC63COd_NN7ZILT8sIkvnfjzawTR5rWj_u0P_YcY_1Y7vBED744cfKzjDy2x2d21O8UOWIJvYyHxc-DiZXMyznLYg3gW2HRWuWpWIPNS8KDuB6WvrHmNOLxy6fNOqobo_x3bPIfaGAn5Ghhw",
        tenant_id: "reden_01"
      },
      data: {
        tracking_status,
        last_update
      },
      url: API.FETCH_TRACKING_INVOICE_LIST
    };
    axios(params)
      .then(res => {
        console.log(res);
        const fetchedInvoices = res.data.items;
        if (!fetchedInvoices || fetchedInvoices.length === 0) return;
        fetchedInvoices.forEach(invoice => {
          dispatch(addInvoice(invoice));
        });
      })
      .catch(err => {
        console.log(err);
      });
  };
};

export const saveChanges = (token, data) => {
  // Deflate the payload (invoice)
  const dataAsString = JSON.stringify(data);
  var binaryString = pako.deflate(dataAsString);

  return dispatch => {
    dispatch(setInvoiceLoading(true, data.invoice));
    const params = {
      method: "post",
      headers: {
        Authorization: token,
        "Content-Type": "application/octet-stream"
      },
      data: binaryString,
      url: API.TRACKING_INVOICE_SAVE_CHANGES
    };
    axios(params)
      .then(res => {
        dispatch(setInvoiceLoading(false, data.invoice));
        console.log("saveChanges response: ", res);
      })
      .catch(err => {
        dispatch(setInvoiceLoading(false, data.invoice));
        console.log(err);
      });
  };
};

export const addInvoice = invoice => ({
  type: ADD_INVOICE,
  payload: invoice
});

export const setInvoiceLoading = (status, invoice) => ({
  type: TRACKING_INVOICE_SET_LOADING,
  status,
  invoice
});

export const saveArchivedXml = (token, data) => {
  return dispatch => {
    dispatch(setInvoiceLoading(true, data.invoice));
    const params = {
      method: "post",
      headers: {
        Authorization: token
      },
      data,
      url: API.TRACKING_INVOICE_SAVE_ARCHIVED_XML
    };
    return axios(params)
      .then(res => {
        dispatch(setInvoiceLoading(false, data.invoice));
        console.log("saveChanges response: ", res);
        return res;
      })
      .catch(err => {
        dispatch(setInvoiceLoading(false, data.invoice));
        console.log(err);
        return err;
      });
  };
};
