import React, { Component } from "react";
import { Checkbox, Form, Input } from "semantic-ui-react";

import "./Login.css";

import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails
} from "amazon-cognito-identity-js";

import { Auth } from "aws-amplify";

import { Link } from "react-router-dom";

const poolData = {
  UserPoolId: "us-east-1_r738QDsdH",
  ClientId: "3ro592qgebcund6fepib41p4rl"
};

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: "",
      rememberMe: false
    };
  }

  signIn = async (username, password) => {
    try {
      await Auth.signIn(username, password);
      this._handleOnSuccess();
    } catch (err) {
      if (err.code === "UserNotConfirmedException") {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        // About how to resend the code and confirm the user, please check the signUp part
      } else if (err.code === "PasswordResetRequiredException") {
        // The error happens when the password is reset in the Cognito console
        // In this case you need to call forgotPassword to reset the password
        // Please check the Forgot Password part.
      } else if (err.code === "NotAuthorizedException") {
        // The error happens when the incorrect password is provided
      } else if (err.code === "UserNotFoundException") {
        // The error happens when the supplied username/email does not exist in the Cognito user pool
      } else {
        console.log(err);
      }
    }
  };

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  _handleEmailChange = event => {
    this.setState({
      email: event.target.value
    });
  };
  _handlePasswordChange = event => {
    this.setState({
      password: event.target.value,
      greeting: "Sign In"
    });
  };

  _handleOnSuccess = () => {
    this.props.history.push("/tracking/invoices/out/");
  };

  _handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    const { email, password } = this.state;
    this.signIn(email, password);
  };

  render() {
    return (
      <div className="Login">
        <div className="LoginContainer">
          <div className="Title">
            <h2> Sign in </h2>
          </div>
          <div className="LoginForm">
            <Form onSubmit={this._handleSubmit}>
              <div className="Email">
                <Form.Field>
                  <Input
                    placeholder="email"
                    type="email"
                    icon="user"
                    iconPosition="left"
                    onChange={this._handleEmailChange}
                  />
                </Form.Field>
              </div>
              <div className="Password">
                <Form.Field>
                  <Input
                    placeholder="password"
                    type="password"
                    icon="lock"
                    iconPosition="left"
                    onChange={this._handlePasswordChange}
                  />
                </Form.Field>
              </div>
              <div className="RememberMe">
                <Form.Field>
                  <Checkbox
                    onChange={this._handleRememberMeCheck}
                    label="Remember me"
                  ></Checkbox>
                </Form.Field>
              </div>
              <div className="Submit">
                <button type="submit" className="Submit">
                  Sign in
                </button>
              </div>
              <div className="RedirectSignUp">
                <Link to="/register"> Sign up </Link> instead.
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
