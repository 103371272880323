/**
 * Copyright Redi Kurti, 2019. All rights reserved.
 */

import React from "react";

// Redux imports
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Other component imports
import TrackingInvoiceCard from "../../../components/Tracking/invoices/TrackingInvoiceCard";
import DrawerItemMainAction from "../../../components/DrawerItems/MainAction/DrawerItemMainAction";

// CSS imports
import "./invoice-list.css";
import { fetchInvoices } from "../../redux/actions/TrackingActions";

const InvoiceList = props => {
  const { invoices, invoicesArray, fetchInvoices } = props;

  const handleNewInvoice = () => {
    props.history.push("/tracking/invoices/create");
  };

  return (
    <div className="tracking-invoice-list-root">
      <div className="tracking-invoice-list-content">
        <div className="tracking-invoice-list-header">
          <div className="tracking-invoice-list-header-title">Faturat</div>
          <div className="tracking-invoice-list-header-action">
            <DrawerItemMainAction
              title="Add new invoice"
              iconName="plus"
              iconColor="white"
              iconSize={24}
              handleClick={handleNewInvoice}
            />
            <DrawerItemMainAction
              title=""
              iconName="download"
              iconColor="white"
              iconSize={24}
              handleClick={() => fetchInvoices()}
            />
          </div>
        </div>
        <div className="tracking-invoice-list-body">
          {invoicesArray.map(invoice => {
            return (
              <TrackingInvoiceCard
                key={invoice.invoice_id}
                linkTo={"/lesh"}
                invoice={invoice}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchInvoices
    },
    dispatch
  );

const mapStateToProps = (state, ownProps) => {
  const { invoices } = state.tracking;
  const inout = ownProps.match.params.inout;
  const invoicesArray = Object.keys(invoices)
    .map(key => invoices[key])
    .filter(invoice => invoice.isIn === (inout === "in"));
  return { invoices, invoicesArray };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList);
