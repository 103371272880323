import React, { Component } from 'react'

export default class index extends Component {

    state = {
        log: [],
        logCount: 0,
        active: false,
        activeMenuItem: 'home',
    };

    clearLog = () => this.setState({ log: [], logCount: 0 })

    handleButtonClick = () => {

        this.setState({ visible: !this.state.visible,
            active: !this.state.active,
        });
    };

    handleSidebarHide = () => this.setState({ visible: false });

    updateLog = eventName => () =>
        this.setState({
            log: [`${new Date().toLocaleTimeString()}: ${eventName}`, ...this.state.log].slice(0, 20),
            logCount: this.state.logCount + 1,
        });


    handleMenuItemClick = (e, { name }) => this.setState({ activeMenuItem: name })

    render() {

        return (
            <div style={
                {
                    backgroundColor: 'red',
                    color: 'white',
                    height: '100vh',
                    textAlign: 'left',
                    padding: 10,
                }
            }>
                <div margin="auto"> Termies and condies. </div>
            </div>
        )
    }
}
