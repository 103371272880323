import React from "react";
import PropTypes from "prop-types";
import { Consumer } from "../../containers/context";

import "./file-components.css";

let styles = {
  root: {
    display: "flex",
    flexDirection: "row",
    padding: "5px",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none"
  },
  iconContainer: {},
  titleContainer: {}
};

const FileActionButton = props => {
  const color = props.color;

  if (props.disabled) {
    styles.titleContainer = { ...styles.titleContainer, color: "#99B3B6" };
    styles.root = { ...styles.root, cursor: "default" };
  } else {
    styles.titleContainer = {
      ...styles.titleContainer,
      color: color ? color : "black"
    };
    styles.root = { ...styles.root, cursor: "pointer" };
  }

  return (
    <Consumer>
      {context => (
        <div
          style={styles.root}
          className="file-action-button"
          onClick={props.disabled ? null : props.onClick}
        >
          <div className="file-action-button-icon" style={styles.iconContainer}>
            {props.children}
          </div>
          <div
            className="file-action-button-title"
            style={styles.titleContainer}
          >
            {props.title}
          </div>
        </div>
      )}
    </Consumer>
  );
};

FileActionButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default FileActionButton;
