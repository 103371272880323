import React from "react";

import "./backdrop.css";

const Backdrop = ({ open, toggleBackdrop }) => {
  if (open) return <div className="backdrop" onClick={toggleBackdrop} />;

  return null;
};

export default Backdrop;
