import React from "react";
import { AppBar, DrawerContainer, DrawerItem, Main } from "../AppBar/index";
import {Loader} from "semantic-ui-react";
import axios from "axios/index";
import * as API from "../../dowhileAPI";
import {Route, Switch} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import CustomerCreatePayments from './CustomerCreatePayments';
import ColumnHeaderList from "../../components/ColumnHeaderList";
import InventoryItemSimple from "../Inventory/InventoryItemSimple";
import PaymentCard from "../../components/Finance/PaymentCard";
import InvoiceCard2 from "../Invoice/components/InvoiceCard2";

import 'react-toastify/dist/ReactToastify.css';
import './customer-view.css';
import Backdrop from "../AppBar/backdrop/Backdrop";
import InvoiceCardCustomer from "../../components/Invoice/InvoiceCardCustomer";


const styles = {
    customerSingleContainer: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '5px',
    },
    customerSingleContainerBody: {
        width: '100%',
        height: '100%',
        padding: '5px',
        flex: 1,
        display: 'flex',
    }
};

export default class CustomerContainer extends React.Component {    

    toastId = null;
    successToast = message => this.toastId =
        toast.success(message, { autoClose: true, hideProgressBar: true });
    errorToast = message => this.toastId =
        toast.error(message, { autoClose: true, hideProgressBar: true });

    constructor(props) {

        super(props);

        this.state = {
            user: {},
            customer: {
                id: this.props.match.params.id,
                isLoading: true,
                isDeleting: false,
                error: false,
                errorMessage: null,
            },
            items: [],
            invoices: [],
            payments: [],

            apiEndpoint: "",
            activeDrawer: 'dashboard',
            sideBarOpen: true,
        };
    }

    componentDidMount() {

        // Sidebar starts closed for smaller devices
        if (window.innerWidth < 748) {
            this.setState({
                sideBarOpen: false
            })
        }

        this.setState({
            apiEndpoint: API.VIEW_CUSTOMER + '?id=' + this.state.customer.id
        });
    };
    _handleUserData = user => {
        if (user == null)
            return
        this.setState({
            user: user
        });
        this._fetchCustomer();
    };
    _handleSideBarToggle = () => {
        this.setState(prevState => {
            return {
                sideBarOpen: !prevState.sideBarOpen
            }
        })
    };

    // FETCH CUSTOMER
    _fetchCustomer = () => {

        const { apiEndpoint, user } = this.state;

        axios({
            method: 'get',
            url: apiEndpoint,
            headers: {
                Authorization: user.idToken,
                tenant_id: user.tenantID
            },
        }).then(res => {

            console.log(res);

            // Set state to not loading so that it stops spinner.
            this.setState({
                customer: {
                    ...this.state.customer,
                    isLoading: false,
                }
            })

            // If server returns 404, customer was not found on database.
            if (res.data.status === 404) {
                this.props.history.push('/customer/list');
            }

            // If server returns 201, customer fetch was successful.
            if (res.data.status === 201) {
                this.setState({
                    customer: { // Populate customer attributes
                        ...res.data.customer,
                        error: false,
                        errorMessage: null,
                    },
                    payments: (res.data.customer.payments) ? res.data.customer.payments : [],
                    invoices: (res.data.customer.invoices) ? res.data.customer.invoices : [], // Populate invoices
                    items: (res.data.customer.invoices) ? this._mapInvoiceItems(res.data.customer.invoices) : [] // Populate items
                })
            }
        }).catch( error => {
            this.setState({
                customer: {
                    ...this.state.customer,
                    isLoading: false,
                    error: true,
                    errorMessage: error.message,
                }
            })
        })
    };

    // DELETE CUSTOMER
    _handleCustomerDelete = () => {

        // Set state to isDeleting
        this.setState({
            customer: {
                ...this.state.customer,
                isDeleting: true,
            },
            sideBarOpen: (window.innerWidth < 768) ? false : true,
        });
        this._deleteCustomer()
            .then(this._handleDeleteCustomerResponse)
            .catch( error => {
                this.setState({
                    customer: {
                        ...this.state.customer,
                        isDeleting: false,
                        error: true,
                        errorMessage: error.message,
                    }
                });
        });
    };
    _deleteCustomer = () => {

        
        const { user, customer } = this.state;
        const data = { "customer_id": customer.id };
        console.log("DELETING CUSTOMER", data);
        const config = {
            headers: {
                'Authorization': user.idToken,
                'tenant_id': user.tenantID,
            }
        };
        return axios.post(API.DELETE_CUSTOMER, data, config);
    };
    _handleDeleteCustomerResponse = res => {

        console.log(res);

        this.setState({
            isDeleting: false,
        });

        try {
            if (res.data.statusCode === 202) {
                console.log("CUSTOMER DELETED SUCCESSFULLY.");

                this.props.history.push('/customer/list');
            }
        } catch (error) {

            this.setState({
                loadingError: true,
                errorMessage: error.message,
                isDeleting: false,
            });
        }

    };

    // Add payment to local state only. Every database operation of payments is handled by CustomerPayments.js
    // + Takes a list of JSON objects of payments and adds them to the state of this class -> payments: [...]
    addPayment = payments => {
        console.log("Adding " + payments.length + " payments to local state...");

        // Add the new payment objects to the beginning of the array, as they are newer.
        this.setState({
            payments: [ ...payments, ...this.state.payments ]
        });

        this.successToast("Pagesa u shtua me sukses.");
    };

    // Remove payment from local state only. Actual deletion of the payment from database is handled in CustomerPayments.js
    // + Takes a list of payment objects and deletes them from the state of this class -> payments: [...]
    removePayment = payments => {
        console.log("Removing " + payments.length + " payments from local state...");

        const paymentsOnState = this.state.payments;
        // TODO: filter payments and reset payments state.
    };

    // DRAWER ITEM CLICK HANDLERS
    _showDashboard = () => {
        this.setState({
            activeDrawer: 'dashboard',
            sideBarOpen: (window.innerWidth >= 768),
        });
        this.props.history.push(`/customer/${this.state.customer.id}/`);
    };
    _showInvoices = () => {
        this.setState({
            activeDrawer: 'invoices',
            sideBarOpen: (window.innerWidth >= 768),
        });
        this.props.history.push(`/customer/${this.state.customer.id}/invoices/`);
    };
    _showPayments = () => {
        this.setState({
            activeDrawer: 'payments',
            sideBarOpen: (window.innerWidth >= 768),
        });
        this.props.history.push(`/customer/${this.state.customer.id}/payments/`);
    };
    _showItems = () => {
        this.setState({
            activeDrawer: 'items',
            sideBarOpen: (window.innerWidth >= 768),
        });
        this.props.history.push(`/customer/${this.state.customer.id}/items/`);
    };
    _showNewPayment = () => {
        this.setState({
            activeDrawer: 'dashboard',
            sideBarOpen: (window.innerWidth >= 768),
        });
        this.props.history.push(`/customer/${this.state.customer.id}/payments/create/`);
    };
    _showNewInvoice = () => {
        this.setState({
            activeDrawer: 'dashboard',
            sideBarOpen: (window.innerWidth >= 768),
        });
        this.props.history.push(`/invoice/new/`);
    };

    // Maps all items from all invoices of this customer into a list. Returns the list.
    _mapInvoiceItems = invoices => {

        let allItems = [];

        invoices.forEach( invoice => {
            allItems = [ ...allItems, ...invoice.items]
        });

        let result = [];
        allItems.reduce( (res, item) => {
            if (!res[item.description]) {
                res[item.description] = { 
                    description: item.description,
                    code: item.code,
                    quantity: 0,
                    price: item.price,
                };
                result.push(res[item.description])
            }
            res[item.description].quantity += item.quantity;
            return res;
        }, {});

        return result;
    };

    render() {

        const {
            state: {
                user,
                customer,
                sideBarOpen,
                items,
                activeDrawer,
                invoices,
                payments,
            },
            props: {
                backdropOpen,
            },
            _showDashboard,
            _showInvoices,
            _showItems,
            _showPayments,
            _showNewPayment,
            _handleCustomerDelete,

            
            addPayment, // method -> Add payment to local state
            removePayment, // method -> Remove payment from local state
        } = this;

        const drawer = (
            <DrawerContainer
                open={sideBarOpen}
                toggle={ this._handleSideBarToggle }
            >
                <DrawerItem
                    title="Dashboard"
                    iconName="th large"
                    iconSize="large"
                    iconColor="black"
                    active={activeDrawer === 'dashboard'}
                    handleClick={_showDashboard} />
                <DrawerItem
                    title="Invoices"
                    iconName="file"
                    iconSize="large"
                    iconColor="black"
                    active={activeDrawer === 'invoices'}
                    handleClick={_showInvoices} />
                <DrawerItem
                    title="Payments"
                    iconName="dollar"
                    iconSize="large"
                    iconColor="black"
                    active={activeDrawer === 'payments'}
                    handleClick={_showPayments} />
                <DrawerItem
                    title="Items"
                    iconName="box"
                    iconSize="large"
                    iconColor="black"
                    active={activeDrawer === 'items'}
                    handleClick={_showItems} />
                <DrawerItem
                    title="Delete customer"
                    iconName="trash alternate outline"
                    iconSize="large"
                    iconColor="black"
                    handleClick={_handleCustomerDelete}
                    primaryNegative />
            </DrawerContainer>
        );


        return (
            <div className="Container">
                <ToastContainer />
                <AppBar
                    service="customer"
                    getUserData={this._handleUserData}
                    handleSideBarToggle={ this._handleSideBarToggle } />
                <div className="Content">
                    { drawer }
                    <Backdrop
                        open={window.innerWidth < 590 && sideBarOpen}
                        toggleBackdrop={ this._handleSideBarToggle }
                    />
                    <Main>
                        <Switch>
                            <Route exact path="/customer/:id/invoices/" render={ () =>
                                <div style={styles.customerSingleContainer}>
                                    <div style={styles.customerSingleContainerBody}>
                                        <ColumnHeaderList headerTitle="Faturat" headerAction={this._showNewInvoice}>
                                            { invoices.map( (invoice, index) =>
                                                <InvoiceCardCustomer key={index} invoice={invoice} /> )}
                                        </ColumnHeaderList>
                                    </div>
                                </div> }
                            />
                            <Route exact path="/customer/:id/payments/" render={ () =>
                                <div style={styles.customerSingleContainer}>
                                    <div style={styles.customerSingleContainerBody}>
                                        <ColumnHeaderList headerTitle="Pagesat" headerAction={this._showNewPayment}>
                                            { payments.map( (payment, index) =>
                                                <PaymentCard key={index} payment={payment} /> )}
                                        </ColumnHeaderList>
                                    </div>
                                </div> }
                            />
                            <Route exact path="/customer/:id/payments/create/" render={ () =>
                                <div className="customer-view-create">
                                    <CustomerCreatePayments
                                        sideBarOpen={sideBarOpen}
                                        user={user}
                                        customer={customer}
                                        invoices={invoices}
                                        addPayment={addPayment}
                                        history={this.props.history}
                                        match={this.props.match}
                                    />
                                </div> }
                            />
                            <Route path="/customer/" render={
                                () => <div className="customer-view-container">
                                    <div className="customer-view-header">
                                        {customer.name}
                                    </div>
                                    <div className="customer-view-body">
                                        {
                                            customer.isLoading || customer.isDeleting
                                            ?
                                            <Loader active inline />
                                            :
                                            <div>HELLO FROM CUSTOMER</div>
                                        }
                                    </div>
                                </div>}
                            />
                        </Switch>
                    </Main>
                </div>
            </div>
        );
    }
};