import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import "./tracking-invoice-card.css";
import Omnicon from "../../../assets/icons/Omnicon";
import { formatDate } from "../../../utils/Formatter";

const TrackingInvoiceCard = props => {
  let {
    invoice_id,
    customer,
    date,
    tracking_status,
    isIn,
    amount,
    currency
  } = props.invoice;

  if (!customer) {
    customer = {
      customer_id: "X00000000X",
      name: "Customer unknown"
    };
  }

  if (!date) {
    date = "1/1/1970";
  }

  const trackingStatusDone = tracking_status === "SOLD" || tracking_status === "OK" || tracking_status === "DONE";
  const isPaidText = trackingStatusDone ? "Done" : "Pending";
  const isPaidColor = trackingStatusDone ? "green" : "orange";
  const isPaidIcon = trackingStatusDone ? "check" : "alert-circle";

  // const isInColor = isIn ? "green" : "orange";
  // const isInText = isIn ? "In" : "Out";
  // const isInIcon = isIn ? "chevron-down" : "chevron-up";

  // Add dots every 3 digits for the invoice amount
  // const amountHumanReadable = amount
  //   .toString()
  //   .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");

  const className = trackingStatusDone
    ? "tracking-invoice-card tracking-invoice-card-paid"
    : "tracking-invoice-card tracking-invoice-card-pending";

  return (
    <Link to={invoice_id + "/invoice"} style={{ textDecoration: "none" }}>
      <div className={className}>
        <div className="tracking-invoice-card-row-1">
          <div className="tracking-invoice-card-id">{invoice_id}</div>
          <div className="tracking-invoice-card-date">{formatDate(date)}</div>
        </div>
        <div className="tracking-invoice-card-customer">{customer.name}</div>
        <div className="tracking-invoice-card-finance">
          <div className="tracking-invoice-card-finance-row-1">
            <div className="tracking-invoice-card-indicator">
              <div className="tracking-invoice-card-indicator-icon">
                <Omnicon name={isPaidIcon} size={24} color={isPaidColor} />
              </div>
              <div
                className="tracking-invoice-card-indicator-text"
                style={{ color: "#484848" }}
              >
                {isPaidText}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

TrackingInvoiceCard.propTypes = {
  invoice: PropTypes.object.isRequired
};

export default TrackingInvoiceCard;
