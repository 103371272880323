import {
  MERGE_INVENTORY,
  SET_LAST_FETCHED_TIMESTAMP,
  SET_FETCHING_INVENTORY_STATUS,
  SELECTED_ITEM_HISTORY_PUSH,
  SELECTED_ITEM_HISTORY_BACK,
  SELECTED_ITEM_HISTORY_CLEAR
} from "../types";

// Network imports.
import Axios from "axios";
import * as API from "../../../utils/API";

import {
  inflateExplosives,
  denormalizeExplosive
} from "../../../utils/Inventory";

export const pushItemToSelectedHistory = item => ({
  type: SELECTED_ITEM_HISTORY_PUSH,
  selectedItem: item
});

export const removeItemFromSelectedHistory = item => ({
  type: SELECTED_ITEM_HISTORY_BACK,
  selectedItem: item
});

export const clearItemSelectedHistory = () => ({
  type: SELECTED_ITEM_HISTORY_CLEAR
});

export const mergeInventory = inventory => ({
  type: MERGE_INVENTORY,
  inventory
});

export const setLastFetchedTimestamp = timestamp => ({
  type: SET_LAST_FETCHED_TIMESTAMP,
  payload: timestamp
});

export const setFetchingInventoryStatus = status => ({
  type: SET_FETCHING_INVENTORY_STATUS,
  status
});

export const fetchInventory = (token, data) => {
  return dispatch => {
    console.debug("Fetching inventory...");
    dispatch(setFetchingInventoryStatus(true));
    // Build the params object for the server request.
    const params = {
      method: "post",
      headers: {
        Authorization: token,
        tenant_id: "reden_01"
      },
      data,
      url: API.FETCH_INVENTORY
    };
    Axios(params)
      .then(res => {
        console.debug(res);
        const inventory = res.data.catalog;
        dispatch(mergeInventory(inventory));
        dispatch(setLastFetchedTimestamp(Date.now()));
      })
      .catch(error => {
        dispatch(setFetchingInventoryStatus(false));
        console.debug("FETCHING ERROR", error.message);
      });
  };
};

export const fetchTrackingInventory = (token, uidElements = []) => {
  return dispatch => {
    const data = {
      query: uidElements
    };
    const config = {
      headers: { Authorization: token }
    };
    Axios.post(API.FETCH_TRACKING_INVENTORY, data, config)
      .then(res => {
        console.log(res);
        dispatch(setLastFetchedTimestamp(Date.now()));
        dispatch(setFetchingInventoryStatus(false));
        const { inventory, status } = res.data;
        console.log("Inventory fetched", inventory);
        if (inventory) {
          if (status === 300) {
            console.error("Error fetching UID");
          }
          if (status === 404) {
            console.debug("Inventory not found on server");
          }
          if (status === 201) {
            console.debug("Inventory fetched successfully.");
            if (uidElements.length > 0) {
              const inventoryWithInflatedUnits = inflateExplosives(inventory);
              console.debug(inventoryWithInflatedUnits);
              const inventoryDenormalized = inventoryWithInflatedUnits.map(
                item => denormalizeExplosive(item)
              );
              dispatch(mergeInventory(inventoryDenormalized));
              return;
            }
            dispatch(mergeInventory(inventory));
          }
        }
      })
      .catch(err => {
        dispatch(setFetchingInventoryStatus(false));
        console.log(err);
      });
  };
};
