import React from "react";
import PropTypes from "prop-types";

const styles = {
    container: {
        width: '100%',
        margin: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        minWidth: '140px',
    },
    title: {
        fontFamily: 'Lato',
        flex: 1,

        display: "flex",
        justifyContent: 'flex-start',
        alignItems: 'center',
        
        padding: "0 0 0 5px",
        fontSize: '1.5em',
        fontWeight: '700',
    },
    total: {
        flex: 0.3,
        justifySelf: "flex-end",
        background: 'lightgrey',
        borderRadius: '5px',
        width: '100%',
        fontFamily: 'Lato',
        fontSize: '1em',
        padding: 7,
    }
};

const InventoryItemSimple = props => {

    const { description, quantity } = props;

    return (
        <div style={styles.container}>
            <div style={styles.title}> { description } </div>
            <div style={styles.total}> { quantity } </div>
        </div>
    ); 
}

InventoryItemSimple.propTypes = {
    description: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
};

export default InventoryItemSimple;

