import React from "react";
import { Breadcrumb } from "semantic-ui-react";
import { Consumer } from "../context";

const Navigation = props => {
  return (
    <Consumer>
      {context => (
        <div className="FilesNavigation">
          <Breadcrumb size="big">
            <Breadcrumb.Section link>
              <div onClick={() => context.actions.navigateTo("home")}>Home</div>
            </Breadcrumb.Section>
            {context.navigation.map((nav, index) => {
              // If it is the last breadcrumb or not (true/false)
              const lastNav = index === context.navigation.length - 1;

              return (
                <Breadcrumb size="big">
                  <Breadcrumb.Divider icon="right chevron" />
                  <Breadcrumb.Section link active={lastNav}>
                    <div
                      className="FilesNavigationText"
                      onClick={() => context.actions.navigateTo(nav)}
                    >
                      {nav}
                    </div>
                  </Breadcrumb.Section>
                </Breadcrumb>
              );
            })}
          </Breadcrumb>
        </div>
      )}
    </Consumer>
  );
};

export default Navigation;
