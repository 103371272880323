import React from "react";
import axios from "axios/index";
import * as API from "../../../../dowhileAPI";
import { Message } from "semantic-ui-react";
import moment from "moment";
import ItemListForm from "./ItemListForm";
import { Provider } from "../../../context";
import _ from "lodash";
import InvoiceForm from "./InvoiceForm";

// Redux imports
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchCustomers } from "../../../redux/actions/CustomerActions";
import {
  uploadInvoice,
  updateNewInvoice
} from "../../../redux/actions/InvoiceActions";

// Network imports
import { Auth } from "aws-amplify";
import "react-datepicker/dist/react-datepicker.css";
import MainButton from "../../../../components/MainButton";

import "./tracking-invoices-new.css";

const styles = {
  total: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flex: 1
  },
  submit: {
    flex: 0.5,
    display: "flex",
    flexDirection: "row",
    width: "100%"
  },
  container: {},
  header: {
    display: "flex",
    flex: "0.1",
    minHeight: 50,
    width: "70%",
    fontSize: "4em",
    fontWeight: "900",
    alignItems: "center",
    justifyContent: "center",
    padding: 10
  },
  body: {},
  messageContainer: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    width: "40%",
    height: "20%"
  },
  message: {
    display: "flex",
    flex: 0.45,
    flexDirection: "column",
    justifySelf: "center",
    alignSelf: "center"
  }
};

class AddTrackingInvoice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customers: [],
      inventory: [],
      loadingInventory: false,
      loadingCustomers: true,
      loadingError: false,
      errorMessage: "",

      uploadCompletedPortion: 0,
      uploadSuccessful: false,
      uploadErrorMessage: "",
      showSuccessMessage: false,
      showFailureMessage: false,

      // INVOICE FORM DATA
      id: "",
      date: "",
      customer: "",
      isIn: false,
      isTracking: true,

      status: "pending",
      items: []
    };
  }

  getToken = async () => {
    return await Auth.currentSession()
      .then(data => {
        const token = data.idToken.jwtToken;
        return token ? token : null;
      })
      .catch(err => null);
  };
  componentDidMount() {
    this.handleCustomerFetch();
  }
  handleCustomerFetch = async () => {
    const token = await this.getToken();
    const data = {
      last_update: this.props.customerLastFetchedTimestamp,
      parameters: ["tenant_id", "name", "customer_id"]
    };
    this.props.fetchCustomers(token, data);
  };
  _handleOnItemChange = item => {
    this.setState({
      items: [...this.state.items, item]
    });
  };

  // Call the API to save the invoice to the cloud
  uploadInvoice = async () => {
    const token = await this.getToken();
    const res = await this.props.uploadInvoice(token, {
      invoice: this.props.newInvoice
    });
    this.setState({ res });
    this.clearInvoice();
  };

  clearInvoice = () => {
    this.props.updateNewInvoice({
      invoice_id: "",
      customer: {},
      date: moment(),
      isIn: false,
      isTracking: true,
      status: "pending",
      items: []
    });
  };

  render() {
    const {
      uploadCompletedPortion,
      uploadErrorMessage,
      showSuccessMessage,
      showFailureMessage
    } = this.state;
    const { loadingCustomers, customers } = this.props;

    const { loadingError, items, inventory, loadingInventory } = this.state;

    let content;

    // Upload complete, server is OK.
    if (showSuccessMessage) {
      content = (
        <div style={styles.message}>
          <Message
            success
            icon="check circle outline"
            header="E morem!"
            content="Tani mund te krijoni nje
                                fature te re ose te shikoni
                                listen e faturave te gjurmimit."
          />
        </div>
      );
    }
    // Upload complete but server returns error.
    if (showFailureMessage) {
      content = (
        <div style={styles.message}>
          <Message
            negative
            icon="x"
            header="Patem nje problem."
            content={uploadErrorMessage}
          />
        </div>
      );
    }

    if (uploadCompletedPortion < 100) {
      content = (
        <div className="tracking-invoices-new-root">
          <div className="tracking-invoices-new-body">
            <InvoiceForm
              customerList={customers}
              customerLoading={loadingCustomers}
              customerError={loadingError}
              onChange={this.handleInvoiceChange}
            />
            <Provider
              value={{
                items,
                inventory,
                loadingInventory,
                actions: { addItem: this._handleOnItemChange }
              }}
            >
              <ItemListForm />
            </Provider>
          </div>
          <div style={styles.header}>
            <div style={styles.submit}>
              <MainButton
                onPress={this.clearInvoice}
                title="Anullo"
                iconName=""
                iconColor=""
                color="tranparent"
                textColor="indianred"
              />
              <MainButton
                onPress={this.uploadInvoice}
                title="Shto faturen"
                iconName="plus"
                iconColor="black"
                color="#2ecc71"
                textColor="black"
              />
            </div>
          </div>
        </div>
      );
    }

    return <>{content}</>;
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCustomers,
      uploadInvoice,
      updateNewInvoice
    },
    dispatch
  );

const mapStateToProps = state => {
  const { customers, fetchingCustomers, lastFetchedTimestamp } = state.customer;
  const { newInvoice, newInvoiceLoading } = state.invoice;
  return {
    customers: Object.keys(customers).map(key => customers[key]),
    customerLastFetchedTimestamp: lastFetchedTimestamp,
    loadingCustomers: fetchingCustomers,
    newInvoice,
    loading: newInvoiceLoading
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTrackingInvoice);
