// src/index.js

/**
 * Copyright Redi Kurti, 2019. All rights reserved.
 */

// React imports
import React from "react";
import ReactDOM from "react-dom";

// Network imports
import Amplify from "aws-amplify";
import * as serviceWorker from "./serviceWorker";

// Other component imports
import App from "./App";
import config from "./config";

// CSS imports
import "./index.css";

if (module.hot) {
  module.hot.accept();
}

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
      {
        name: "notes",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      }
    ]
  }
});

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.register();
