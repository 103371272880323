import React from "react";
import InvoiceCard from "../components/InvoiceCard";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./invoice-list.css";

const InvoiceList = props => {
  const { user, invoices, linkTo } = props;

  if (invoices.length === 0)
    return (
      <div className="Invoice-InvoiceList">
        <div className="Invoice-List">
          <h2>
            Nuk ka fatura, regjistroni nje te re
            <Link to="/tracking/invoices/add"> ketu </Link>{" "}
          </h2>
        </div>
      </div>
    );

  return (
    <div className="Invoice-InvoiceList">
      <div className="List">
        {invoices.map(invoice => {
          return <InvoiceCard linkTo={linkTo} user={user} invoice={invoice} />;
        })}
      </div>
    </div>
  );
};

InvoiceList.propTypes = {
  user: PropTypes.object.isRequired,
  invoices: PropTypes.array.isRequired,
  linkTo: PropTypes.string.isRequired
};

export default InvoiceList;
