import React from 'react';
import PropTypes from 'prop-types';

import './invoice-card-customer.css';

const InvoiceCardCustomer = ({invoice}) => {

    console.log('INVOICE', invoice);

    // Add dots every 3 digits for the payment amount.
    const amount = '1650230'
        .toString()
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");


    // Convert to local date.
    let localDate;
    try {
        localDate = new Intl.DateTimeFormat(
            'en-GB',
            { year: 'numeric', month: 'short', day: '2-digit'}
        ).format(new Date(invoice.date))
    } catch (e) {
        console.error(e);
        localDate = invoice.date;
    }

    return (
        <div className="invoice-card-customer-container">
            <div className={`invoice-card-customer-header-${invoice.status}`}>
                <div className="invoice-card-customer-id">
                    { invoice.invoice_id }
                </div>
                <div className="invoice-card-customer-date">
                    { localDate }
                </div>
            </div>
            <div className="invoice-card-customer-body">
                <div className="invoice-card-customer-amount">
                    { amount }
                </div>
                <div className="invoice-card-customer-currency">
                    { 'ALL' }
                </div>
            </div>
        </div>
    );
};

InvoiceCardCustomer.propTypes = {
    customer: PropTypes.object.isRequired,
    payment: PropTypes.object.isRequired,
};

export default InvoiceCardCustomer;
