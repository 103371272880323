export const FETCH_TRACKING_INVOICE_LIST =
  "https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/invoice/read";

export const TRACKING_INVOICE_SAVE_CHANGES =
  "https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/tracking/uid/create";

export const FETCH_EXPLOSIVE =
  "https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/tracking/uid/read";

export const FETCH_INVENTORY =
  "https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/inventory";

export const FETCH_TRACKING_INVENTORY =
  "https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/tracking/inventory/read";

export const FETCH_INVOICES =
  "https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/invoice/read";

export const UPDATE_INVOICE =
  "https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/tracking/entries/update";

// FILES
export const TRACKING_FILES_UPLOAD =
  "https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/tracking/files/upload";

export const TRACKING_FILES_DOWNLOAD =
  "https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/tracking/files/download";

export const TRACKING_FILES_DELETE =
  "https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/tracking/files/delete";

export const TRACKING_FILES_MOVE =
  "https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/tracking/files/move";

export const TRACKING_EXPLORE_UID =
  "https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/tracking/explore/uid";

export const TRACKING_INVOICE_SAVE_ARCHIVED_XML =
  "https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/tracking/xml/create";

export const FETCH_CUSTOMERS =
  "https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/customer/read";
