/**
 * Copyright Redi Kurti, 2020. All rights reserved.
 */

import React from "react";
import Omnicon from "../assets/icons/Omnicon";

const styles = {
  saveButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "100%",
    width: 'max-content',
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 10,
    cursor: "pointer",
    userSelect: "none",
  },
  saveButtonText: {
    fontFamily: "Lato",
    fontWeight: "300",
    marginLeft: 5,
    fontSize: 16,
    height: '100%',
  },
  icon: {
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  }
};

const GoBack = props => {
  const { title, onClick, color } = props;
  return (
    <div style={styles.saveButton} onClick={onClick}>
      <div style={styles.icon}>
        <Omnicon name="arrow-left" color={color} size={24} />
      </div>
      <div style={{ ...styles.saveButtonText, color: color }}>
        {title ? title : "back"}
      </div>
    </div>
  );
};

export default GoBack;
