/**
 * Copyright Redi Kurti, 2019. All rights reserved.
 */

import React from "react";

import { useHistory } from "react-router-dom";

// Redux imports
import { setActiveDrawerItem } from "../../../../containers/redux/actions/TrackingActions";
import { toggleDrawer } from "../../../../containers/redux/actions/AppbarActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Other component imports
import DrawerItem from "../../../../components/DrawerItems/Navigator/DrawerItem";
import { Transition } from "react-transition-group";

// CSS imports
import "../../tracking-drawer.css";

const defaultStyle = {
  transition: `width 300ms ease-out, opacity 300ms ease-in`,
  opacity: 0,
  width: 0
};

const transitionStyles = {
  entering: { width: 0, opacity: 0 },
  entered: { width: "300px", opacity: 1 },
  exiting: { width: "300px", opacity: 1 },
  exited: { width: 0, opacity: 0 }
};

const Drawer = ({
  activeDrawerItem,
  drawerOpen,
  setActiveDrawerItem,
  toggleDrawer,
  baseURL
}) => {
  // History hook
  let history = useHistory();

  const goTo = (endpoint, index) => {
    setActiveDrawerItem(index);
    toggleDrawer();
    const url = (baseURL ? baseURL : "") + endpoint;
    console.log("Going to ", url);
    history.push(url);
  };

  return (
    <Transition in={drawerOpen} timeout={300}>
      {state => (
        <div
          className="panel-drawer-root"
          style={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}
        >
          <div className="panel-drawer-list">
            <DrawerItem
              title="Fatura"
              iconName="file-plus"
              iconColor="black"
              handleClick={() => goTo("invoice", 0)}
              loading={false}
              active={activeDrawerItem === 0}
            />
            <DrawerItem
              title="XML"
              iconName="file-minus"
              iconColor="black"
              handleClick={() => goTo("xml", 1)}
              loading={false}
              active={activeDrawerItem === 1}
            />
            <DrawerItem
              title="Produktet"
              iconName="package"
              iconColor="black"
              handleClick={() => goTo("products", 2)}
              loading={false}
              active={activeDrawerItem === 2}
            />
            <DrawerItem
              title="Barkodet"
              iconName="search"
              iconColor="black"
              handleClick={() => goTo("uids", 3)}
              loading={false}
              active={activeDrawerItem === 3}
            />
          </div>
        </div>
      )}
    </Transition>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setActiveDrawerItem,
      toggleDrawer
    },
    dispatch
  );

const mapStateToProps = state => {
  const { activeDrawerItem } = state.tracking;
  const { drawerOpen } = state.appbar;
  return { activeDrawerItem, drawerOpen };
};

export default connect(mapStateToProps, mapDispatchToProps)(Drawer);
