import React from "react";
import { Main } from "../AppBar/index";
import _ from "lodash";
import { Switch, Route, useHistory, useParams } from "react-router-dom";
import InvoiceList from "./invoices/InvoiceList";
import TrackingDrawer from "../../components/Tracking/TrackingDrawer";
import Appbar from "../../components/Appbar/Appbar";
import Files from "../Files/Files";
import TrackingExplore from "./explore/TrackingExplore";
import TrackingInventory from "./inventory/TrackingInventory";
import TrackingInventoryDetail from "./inventory/TrackingInventoryDetail";
import AddTrackingInvoice from "./invoices/new/AddTrackingInvoice";
import CustomerContainer from "../Customers/CustomerContainer";
import NewCustomer from "../Customers/NewCustomer";

const Tracking = props => {
  const _handleInvoiceClick = invoice => {
    const myInvoice = invoice[0];
    if (myInvoice.isIn)
      this.props.history.push(
        "/tracking/in/invoices/" + myInvoice.invoice_id + "/"
      );
    else
      this.props.history.push(
        "/tracking/out/invoices/" + myInvoice.invoice_id + "/"
      );
  };

  return (
    <div className="Container">
      <Appbar title="Track & Trace" />
      <div className="Content">
        <TrackingDrawer />

        <Main>
          <Switch>
            <Route
              exact
              path="/tracking/invoices/create"
              render={props => <AddTrackingInvoice {...props} />}
            />
            <Route
              path="/tracking/invoices/:inout"
              render={props => (
                <InvoiceList
                  {...props}
                  handleInvoiceClick={_handleInvoiceClick}
                />
              )}
            />
            <Route
              path="/tracking/files/"
              render={props => <Files {...props} drawer={false} />}
            />
            <Route
              path="/tracking/explore/"
              render={props => <TrackingExplore {...props} />}
            />
            <Route
              exact
              path="/tracking/inventory/detail"
              render={props => <TrackingInventoryDetail {...props} />}
            />
            <Route
              path="/tracking/inventory/"
              render={props => <TrackingInventory {...props} />}
            />
            <Route
              path="/tracking/customers/create"
              render={props => <NewCustomer {...props} />}
            />
            <Route
              path="/tracking/customers/"
              render={props => <CustomerContainer {...props} />}
            />
          </Switch>
        </Main>
      </div>
    </div>
  );
};

export default Tracking;
