import { UPDATE_NEW_INVOICE, SET_LOADING_STATUS } from "../types";

// Network imports.
import Axios from "axios";
import * as API from "../../../utils/API";

export const updateNewInvoice = newInvoice => ({
  type: UPDATE_NEW_INVOICE,
  newInvoice
});

export const setLoadingStatus = status => ({
  type: SET_LOADING_STATUS,
  status
});

export const uploadInvoice = (token, data) => {
  return dispatch => {
    console.log("UPLOADING INVOICE ...");
    dispatch(setLoadingStatus(true));

    const params = {
      method: "post",
      headers: {
        Authorization: token,
        tenant_id: "reden_01"
      },
      data,
      url:
        "https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/invoice/create"
    };

    console.log("UPLOADING INVOICE WITH PARAMS > ", params);

    return Axios(params)
      .then(res => {
        console.log(res);
        dispatch(setLoadingStatus(true));
        // Handle errors from server/api/http
        if (res.status !== 200)
          return {
            uploadingError: {
              status: res.status,
              message: `Server call error with status ${res.status}`
            }
          };
        // Handle errors from database
        if (res.data.status !== 201) {
          return {
            uploadingError: {
              status: res.data.status,
              message: res.data.message
            }
          };
        }

        console.log("NewInvoice upload successful!");
        return { showSuccessMessage: true };
      })
      .catch(error => {
        console.log("UPLOADING INVOICE ERROR", error.message);
        return {
          showFailureMessage: true,
          uploadErrorMessage: error.message
        };
      });
  };
};
