import React from "react";
import { Loader } from "semantic-ui-react";

const styles = {
  loaderContainer: {
    width: "100%",
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  loaderMessage: {
    padding: "20px",
    fontSize: "2em",
    fontWeight: "700"
  }
};

const MyLoader = props => {
  const { loading, title } = props;

  if (loading)
    return (
      <div style={styles.loaderContainer}>
        <div style={styles.loaderMessage}>{title}</div>
        <div>
          <Loader active inline="centered" size="big" />
        </div>
      </div>
    );

  return null;
};

export default MyLoader;
