import React from "react";
import { Icon, Loader } from "semantic-ui-react";
import { Consumer } from "../../containers/context";

export default class Folder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: "",
      renamingDone: false
    };
  }

  render() {
    const {
      props: { isLoading, isSelected, isNewFolder, name, prefix }
    } = this;

    // Change background color for the item being de/selected.
    const bgColor = isSelected ? "rgba(215, 215, 215, 0.3)" : "white";

    return (
      <Consumer>
        {context => (
          <div style={{ backgroundColor: bgColor }} className="File">
            <div
              className="FileImage"
              onClick={() => context.actions.selectFile({name, prefix})}
            >
              {isLoading ? (
                <Loader active inline="centered" />
              ) : (
                <Icon name="folder open" size="large" />
              )}
            </div>
            <div
              className="FileTitle"
              onClick={() =>
                isNewFolder ? null : context.actions.navigateTo(name)
              }
            >
              {name}
            </div>
          </div>
        )}
      </Consumer>
    );
  }
}
