import React from 'react';
import { AppBar, DrawerContainer, DrawerItem, Main } from "../AppBar/index";
import {Loader} from "semantic-ui-react";
import axios from "axios/index";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as API from "../../dowhileAPI";


const styles = {

    greeting: {
        fontSize: 64,
    },
    invoiceDrawer: {
        fontFamily: "sans-serif",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        flexGrow: "1",
        paddingTop: "120px",
    }
};


export default class InvoiceView extends React.Component {

    errorToast = message => this.toastId = toast.error(message, { autoClose: true, hideProgressBar: true });

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            invoice: {
                id: this.props.match.params.id,
                isLoading: true,
                isDeleting: false,
            },
            sideBarOpen: true,
        };
    };

    _handleSideBarToggle = () => {
        this.setState({
            sideBarOpen: !this.state.sideBarOpen
        })
    };

    // Authenticate user.
    _handleUserData = user => {

        if (user == null)
            return

        this.setState({ user: user });
        this._fetchInvoice(user, this.state.invoice);
    };

    _fetchInvoice = (user, invoice) => {

        axios({
            method: 'get',
            url: `${API.VIEW_INVOICE}?id=${invoice.id}`,
            headers: {
                Authorization: user.idToken,
                "tenant_id": user.tenantID
            },
        }).then(res => {

            console.log(res);
            const fetchedInvoices = res.data.Items;

            this.setState({ 
                invoice: { ...this.state.invoice, isLoading: false }
            });

            // No response.
            if (fetchedInvoices === null) {
                this.errorToast("No response from server.");
                return
            }
            // Fetch successful
            this.setState({
                invoice: {...this.state.invoice, ...fetchedInvoices[0]}
            })
        }).catch( error => {
            this.setState({ loading: false })
            this.errorToast(error.message);
        })
    };

    _deleteInvoice = () => {

        const deleteApiEndpoint = `https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/invoice/list/delete`;
        const { user, invoice } = this.state;

        const data = {

            "invoice_id": invoice.id,
        };

        console.log("DELETING INVOICE ... WITH DATA AND USER: ");
        console.log(data);
        console.log(user);

        const config = {

            headers: {
                'content-type': 'application/json',
                'Authorization': user.idToken,
                'tenant_id': user.tenantID,
            },

            onUploadProgress: progressEvent => {

            }
        };

        return axios.post(deleteApiEndpoint, data, config);
    };
    _handleDeleteInvoiceResponse = (res) => {

        this.setState({
            deletingInvoice: false,
        });

        console.log(res);

        try {
            if (res.data.statusCode === 202) {
                console.log("INVOICE DELETED SUCCESSFULLY.");

                this.props.history.push('/invoice/');
            }
        } catch (e) {
            console.log(e);
        }

    };
    _handleInvoiceDelete = () => {

        console.log("Deleting invoice... ");

        this.setState({
            deletingInvoice: true,
        });

        this._deleteInvoice().then(this._handleDeleteInvoiceResponse);
    };
    
    render() {

        const { 
            invoice: {
                isLoading, isDeleting, id 
            },
            sideBarOpen,
        } = this.state;

        return (
            <div className="Container">

                <ToastContainer />

                <AppBar
                    service="invoice"
                    getUserData={this._handleUserData} 
                    handleSideBarToggle={this._handleSideBarToggle}
                />

                <div className="Content">
                    {
                        sideBarOpen &&
                        <DrawerContainer anchor="left">
                            <div style={styles.invoiceDrawer}>
                                <DrawerItem 
                                    title="Delete Invoice" 
                                    loading={isDeleting}
                                    iconName="trash alternate outline" 
                                    iconSize="large" 
                                    iconColor="black" 
                                    handleClick={this._handleInvoiceDelete}/>
                            </div>
                        </DrawerContainer>
                    }

                    <Main>
                        { 
                            isLoading || isDeleting
                            ?
                                <Loader active inline />
                            :
                            <p> { id } </p>
                         
                        }
                    </Main>
                </div>
            </div>
        );
    }
}

InvoiceView.propTypes = {

};
