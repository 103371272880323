import React from "react";
import { Input, Button } from "semantic-ui-react";

import "./item-form.css";
import { Consumer } from "../../../context";

// Redux imports
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateNewInvoice } from "../../../redux/actions/InvoiceActions";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: "0 0 20px 0"
  },

  buttonContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    margin: "15px 0"
  },
  inputListContainer: {
    display: "flex",
    width: "100%"
  },
  inputListContainerPrimary: {
    flex: 0.4
  },
  inputListContainerSecondary: {
    flex: 0.2
  },
  primaryButton: {
    flex: 0.5,
    alignSelf: "center",
    maxWidth: "200px",
    borderRadius: "20px",
    color: "black",
    backgroundImage: "linear-gradient(to right, #43e97b 0%, #38f9d7 100%)",
    boxShadow: "0 4px 6px rgba(0,0,0,0.15)"
  }
};

class ItemForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      quantity: "",
      quantityError: false,

      price: "",
      priceError: false,

      description: "",
      descriptionError: false
    };
  }

  _handleQuantityChange = event => {
    this.setState({
      quantity: event.target.value
    });
  };

  _handlePriceChange = event => {
    this.setState({
      price: event.target.value
    });
  };

  _handleDescriptionChange = event => {
    this.setState({
      description: event.target.value
    });
  };

  _handleAddItem = items => {
    const { quantity, price, description } = this.state;

    let quantityError = quantity === "";
    const priceError = price === "";
    const descriptionError = description === "";

    if (quantityError || priceError || descriptionError) {
      this.setState({
        quantityError,
        priceError,
        descriptionError
      });
      return -1;
    } else {
      let item = items.filter(item => {
        return item.title === description;
      })[0];

      if (!item)
        item = {
          product_id: "RandomID" + Math.random().toString(),
          location: "RandomLocation" + Math.random().toString()
        };

      this.setState({
        counter: this.state.counter + 1
      });

      const itemTX = {
        id: item.product_id,
        location_id: item.location,

        description: this.state.description,
        quantity: this.state.quantity,
        price: this.state.price,
        total: this.state.quantity * this.state.price
      };

      this.setState({
        description: "",
        price: "",
        quantity: ""
      });

      return itemTX;
    }
  };

  render() {
    const { quantityError, priceError, descriptionError } = this.state;

    return (
      <Consumer>
        {context => (
          <div style={styles.container}>
            <div style={styles.inputListContainer}>
              <div style={styles.inputListContainerPrimary}>
                <Input
                  list="inventory"
                  onChange={this._handleDescriptionChange}
                  className="ItemFormInput"
                  placeholder="Description"
                  loading={context.loadingInventory}
                  error={descriptionError}
                  value={this.state.description}
                  fluid
                />
                <datalist id="inventory">
                  {context.inventory.map(item => {
                    return <option key={item.title} value={item.title} />;
                  })}
                </datalist>
              </div>
              <div style={styles.inputListContainerSecondary}>
                <Input
                  onChange={this._handleQuantityChange}
                  className="ItemFormInput"
                  label={{ basic: true, content: "QTY" }}
                  labelPosition="right"
                  placeholder="Quantity"
                  error={quantityError}
                  value={this.state.quantity}
                  fluid
                />
              </div>
              <div style={styles.inputListContainerSecondary}>
                <Input
                  onChange={this._handlePriceChange}
                  className="ItemFormInput"
                  label={{ basic: true, content: "ALL" }}
                  labelPosition="right"
                  placeholder="Price"
                  error={priceError}
                  value={this.state.price}
                  fluid
                />
              </div>
              <div style={styles.inputListContainerSecondary}>
                <Button
                  primary
                  style={styles.primaryButton}
                  onClick={() => {
                    const item = this._handleAddItem(context.inventory);
                    console.log(item);
                    if (item !== -1)
                      this.props.updateNewInvoice({
                        items: [...this.props.newInvoice.items, item]
                      });
                  }}
                >
                  + Add item
                </Button>
              </div>
            </div>
          </div>
        )}
      </Consumer>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateNewInvoice
    },
    dispatch
  );

const mapStateToProps = state => {
  const { newInvoice } = state.invoice;
  return { newInvoice };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemForm);
