import React, { useState } from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";

// Network imports
import { Auth } from "aws-amplify";
import axios from "axios";

// Utils
import * as API from "../../../utils/API";
import Barcode from "../out/barcodes/Barcode";
import BarcodeInfoCard from "../../../components/Tracking/BarcodeInfoCard";
import InputWithAction from "../../../components/Input/InputWithAction";

import "./tracking-explore.css";
import MyLoader from "../../../components/MyLoader";

const TrackingExplore = props => {
  const [searchResults, setSearchResults] = useState(null);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const searchExplosive = async uid => {
    const token = await Auth.currentSession()
      .then(data => {
        const token = data.idToken.jwtToken;
        return token ? token : null;
      })
      .catch(err => console.log(err));
    if (!token) return;
    let parsedUid = uid.toUpperCase();
    axios
      .post(
        API.TRACKING_EXPLORE_UID,
        { uid: parsedUid },
        { headers: { Authorization: token } }
      )
      .then(res => {
        console.debug(res);
        setLoading(false);
        const response = res.data;
        console.debug(response);
        if (response.status == 404) {
          setNotFound(true);
          setSearchResults(null);
          return;
        }
        setSearchResults(response.invoices);
      })
      .catch(err => {
        setLoading(false);
        setError(true);
      });
  };

  const handleSearch = () => {
    console.log("Searching for ", value);
    if (value.length === 0) return;
    setLoading(true);
    setSearchResults(null);
    setError(false);
    setNotFound(false);
    searchExplosive(value);
  };

  const handleChange = event => {
    setValue(event.target.value.replace(/\s/g, ""));
  };

  const listenForEnter = event => {
    if (event.keyCode === 13) {
      handleSearch();
    }
  };

  let body;
  if (!searchResults) {
    body = (
      <div className="tracking-explore-central-message">
        {notFound ? "UID not found." : "Use the field on top to search."}
      </div>
    );
  } else {
    body = (
      <div className="tracking-explore-barcode-search-result">
        {searchResults.map(result => {
          return (
            <>
              <div className="tracking-explore-barcode-search-result-info">
                <BarcodeInfoCard invoice={result} />
              </div>
              <div className="tracking-explore-barcode-search-result-tree">
                <Barcode
                  key={result.units[0].uid}
                  barcode={{ ...result.units[0], status: "200" }}
                  searchingFor={value}
                  index={0}
                  noDelete={true}
                />
              </div>
            </>
          );
        })}
      </div>
    );
  }

  return (
    <div className="tracking-explore-root">
      <div className="tracking-explore-content">
        <div className="tracking-explore-header">
          <InputWithAction
            onKeyDown={listenForEnter}
            onChange={handleChange}
            value={value}
            type="text"
            placeholder="Search..."
            onClick={handleSearch}
          />
        </div>
        {loading ? (
          <MyLoader loading={loading} />
        ) : (
          <div className="tracking-explore-body">{body}</div>
        )}
      </div>
    </div>
  );
};

export default withRouter(TrackingExplore);
