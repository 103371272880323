/**
 * Copyright Redi Kurti, 2020. All rights reserved.
 */

import React from "react";
import MyLoader from "./MyLoader";
import "./item-list.css";

const ItemList = props => {
  const { items, loading, renderEmpty, renderItem } = props;

  if (!items || items.length === 0)
    return <div className="item-list-root">{renderEmpty()}</div>;
  if (loading)
    return (
      <div className="item-list-root">
        <MyLoader loading={loading} title="" />
      </div>
    );
  return (
    <div className="item-list-root">
      {items.map((item, index) => {
        return renderItem({ item, index });
      })}
    </div>
  );
};

export default ItemList;
