import React from "react";
import { withRouter } from "react-router-dom";
import { AppBar, DrawerContainer, Main } from "../AppBar/index";
import { Dimmer, Loader } from "semantic-ui-react";
import InvoiceList from "./list/InvoiceList";
import DrawerItem from "../../components/DrawerItems/Navigator/DrawerItem";
import axios from "axios";
import * as API from "../../dowhileAPI";
import Input from "../../components/Input/Input";
import _ from "lodash";
import Backdrop from "../AppBar/backdrop/Backdrop";

import './invoice.css';

class Invoice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      redirectTo: "",
      isIn: false,

      sideBarOpen: true,
      activeDrawerItem: 1,

      // Invoices
      displayResults: [
        // {
        //   customer: {
        //     customer_id: "A910283L",
        //     name: "Genci shpk"
        //   },
        //   date: "2019-09-14T20:31:28.770Z",
        //   invoice_id: "3001",
        //   isIn: true,
        //   isTracking: true,
        //   items: [],
        //   last_update: 1568500302418,
        //   tracking_status: "pending",
        //   uids: [],
        //   payment_status: "pending"
        // },
        // {
        //   customer: {
        //     customer_id: "A910283L",
        //     name: "Gjoni shpk"
        //   },
        //   date: "2019-09-16T20:31:28.770Z",
        //   invoice_id: "3002",
        //   isIn: false,
        //   isTracking: false,
        //   items: [],
        //   last_update: 1568500302200,
        //   tracking_status: "ok",
        //   uids: [],
        //   payment_status: "ok"
        // }
      ],
      value: "",
      isSearching: false,
      noResults: false,
      invoices: [
        // {
        //   customer: {
        //     customer_id: "A910283L",
        //     name: "Genci shpk"
        //   },
        //   date: "2019-09-14T20:31:28.770Z",
        //   invoice_id: "3001",
        //   isIn: true,
        //   isTracking: true,
        //   items: [],
        //   last_update: 1568500302418,
        //   tracking_status: "pending",
        //   uids: [],
        //   payment_status: "pending"
        // },
        // {
        //   customer: {
        //     customer_id: "A910283L",
        //     name: "Gjoni shpk"
        //   },
        //   date: "2019-09-16T20:31:28.770Z",
        //   invoice_id: "3002",
        //   isIn: false,
        //   isTracking: false,
        //   items: [],
        //   last_update: 1568500302200,
        //   tracking_status: "ok",
        //   uids: [],
        //   payment_status: "ok"
        // }
      ],
      loading: false,
      loadingError: false,
      errorMessage: ""
    };
  }

  componentDidMount() {
    // Sidebar is closed for smaller devices
    this.setState({ sideBarOpen: window.innerWidth > 748 });
  }
  _handleUserData = user => {
    if (user != null) {
      this.setState({ user: user });
      this._fetchInvoices(user);
    }
  };
  _fetchInvoices = user => {
    console.log("FETCHING INVOICES");
    this.setState({ loading: true });

    let last_update = 0;
    if (!last_update) last_update = 0;

    const params = {
      method: "post",
      headers: {
        Authorization: user.idToken,
        tenant_id: user.tenantID
      },
      data: {
        tracking_status: "",
        last_update
      },
      url: API.FETCH_ALL_TRACKING_INVOICES
    };

    console.log("FETCHING INVOICES WITH PARAMS: ", params);

    axios(params)
      .then(res => {
        this.setState({ loading: false });
        console.log(res);

        if (res.status !== 200 || res.data.status !== 201) return;

        const invoices = res.data.items;
        if (!invoices || invoices.length === 0) return;

        const sortedInvoices = invoices.sort(_handleDateSorting);

        console.log("sortedInvoices", sortedInvoices);
        // Fetch successful
        this.setState({
          invoices: sortedInvoices,
          displayResults: sortedInvoices
        });
      })
      .catch(error => {
        console.log("Error fetching invoices > ", error);
        this.setState({
          loading: false,
          loadingError: true,
          errorMessage: error.message
        });
      });
  };
  _handleInvoiceClick = invoice => {};

  // SearchBox
  handleSearchChange = (e, { value }) => {
    if (this.state.invoices.length < 1) return;

    this.setState({ isSearching: true, value });

    setTimeout(() => {
      if (value.length < 1) {
        this.setState({
          displayResults: this.state.invoices
        });
      } else {
        const re = new RegExp(_.escapeRegExp(this.state.value), "i");
        const isMatch = result =>
          re.test(result.invoice_id || result.customer.name);

        this.setState({
          isSearching: false,
          displayResults: _.filter(this.state.invoices, isMatch)
        });
      }
    }, 300);
  };

  // Drawer items click handling.
  _handleNewInvoice = () => {
    this.props.history.push("/invoice/new");
  };
  _handleIn = () => {
    this.setState({ isIn: true, activeDrawerItem: 0 });
    if (window.innerWidth < 698) this._handleSideBarToggle();
  };
  _handleOut = () => {
    this.setState({ isIn: false, activeDrawerItem: 1 });
    if (window.innerWidth < 698) this._handleSideBarToggle();
  };
  _handleTracking = () => {
    this.props.history.push("/tracking");
  };
  _handleSideBarToggle = () => {
    this.setState({
      sideBarOpen: !this.state.sideBarOpen
    });
  };

  render() {
    const {
      sideBarOpen,
      activeDrawerItem,
      user,
      isIn,
      loadingError,
      loading,
      displayResults
    } = this.state;

    let invoicesToDisplay = isIn
      ? displayResults.filter(invoiceIn)
      : displayResults.filter(invoiceOut);

    let content = (
      <div className="invoice-content">
        <div className="invoice-content-header">
          Invoices - {isIn ? 'In' : 'Out'}
        </div>
        <div className="invoice-content-body">
          <InvoiceList
            user={user}
            invoices={invoicesToDisplay}
            linkTo="/invoice/"
          />
        </div>
      </div>
    );

    // If not logged in, show loader.
    if (user == null || loading) {
      content = (
        <Dimmer active blurring inverted>
          <Loader />
        </Dimmer>
      );
    }

    if (loadingError) {
      content = <p>THERE WAS AN ERROR, TODO: show error toast.</p>;
    }

    let mainAction = {
      handleClick: this._handleNewInvoice,
      title: "Add new invoice",
      iconName: "plus",
      iconColor: "white",
      iconSize: 24
    };

    return (
      <div className="Container">
        <AppBar
          service="invoice"
          getUserData={this._handleUserData}
          handleSideBarToggle={this._handleSideBarToggle}
        >
          {/* <Input
                        className="SearchBox"
                        placeholder='Search for invoice...'
                        loading={isSearching}
                        icon='search'
                        transparent
                        onChange={_.debounce(this.handleSearchChange, 500, { leading: true })}
                    /> */}

          <Input />
        </AppBar>

        <div className="Content">
          <DrawerContainer
            open={sideBarOpen}
            toggle={this._handleSideBarToggle}
            mainAction={mainAction}
          >
            <div className="CustomerDrawer">
              <DrawerItem
                title="In"
                iconName="file-plus"
                iconColor="black"
                handleClick={this._handleIn}
                loading={false}
                active={activeDrawerItem === 0}
              />
              <DrawerItem
                title="Out"
                iconName="file-minus"
                iconColor="black"
                handleClick={this._handleOut}
                active={activeDrawerItem === 1}
                loading={false}
              />
              <DrawerItem
                title="Tracking"
                iconName="package"
                iconColor="black"
                handleClick={this._handleTracking}
                loading={false}
              />
            </div>
          </DrawerContainer>
          <Backdrop
            open={window.innerWidth < 590 && sideBarOpen}
            toggleBackdrop={this._handleSideBarToggle}
          />
          <Main>{content}</Main>
        </div>
      </div>
    );
  }
}

Invoice.propTypes = {};

export default withRouter(Invoice);

// HELPER FUNCTIONS.
// Sorting
const _handleDateSorting = (invoice1, invoice2) => {
  // This is a comparison function that will result in dates being sorted in
  // ASCENDING order. As you can see, JavaScript's native comparison operators
  // can be used to compare dates. This was news to me.

  const date1 = new Date(invoice1.date);
  const date2 = new Date(invoice2.date);

  if (date1 > date2) return -1;
  if (date1 < date2) return 1;
  return 0;
};
const invoiceIn = invoice => {
  return invoice.isIn === true;
};
const invoiceOut = invoice => {
  // Return invoices where isIn is false or non existent.
  return invoice.isIn === false || !("isIn" in invoice);
};
