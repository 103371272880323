import React from "react";
import ItemList from "../../ItemList";
import InventoryDetailCard from "./InventoryDetailCard";
import NoItems from "../../NoItems";

const InventoryDetailUnits = props => {
  const { items, selectItem, loading } = props;

  const renderListEmpty = () => (
    <NoItems
      iconName="box-empty-color"
      iconColor="white"
      title="Nuk ka produkte ne inventar."
    />
  );

  const renderItem = ({ item }) => (
    <InventoryDetailCard
      key={item.uid}
      item={item}
      onPress={selectItem}
      title={item.isInventory ? item.file : item.uid}
      qty={item.itemQuantity}
    />
  );

  return (
    <ItemList
      items={items ? items : []}
      loading={loading}
      renderItem={renderItem}
      renderEmpty={renderListEmpty}
    />
  );
};

export default InventoryDetailUnits;
