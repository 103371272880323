/**
 * Copyright Redi Kurti, 2020. All rights reserved.
 */
import React from "react";
import { Switch, Route, useParams } from "react-router-dom";
import { Auth } from "aws-amplify";

// Redux imports
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { saveArchivedXml } from "../../../redux/actions/TrackingActions";

// Utils
import {
  extractExplosivesThatAreItems,
  groupExplosivesByProductCode
} from "../../../../utils/Invoice";

// Other component imports
import Drawer from "../../../../components/Tracking/invoices/in/Drawer";
import Appbar from "../../../../components/Appbar/Appbar";
import Main from "../../../AppBar/main/Main";
import Invoice from "../in/Invoice";
import Products from "../in/Products";
import Xml from "../in/Xml";
import Uids from "../in/Uids";

const TrackingInvoiceOut = props => {
  let { invoice_id } = useParams();
  const { invoice } = props;

  const handleSaveChanges = async () => {
    console.log("handleSaveChanges");
    // Extract items.
    const items = extractExplosivesThatAreItems(invoice.uids);
    const explosives = groupExplosivesByProductCode(items);
    const invoice2 = {
      invoice_id: invoice.invoice_id,
      date: invoice.date,
      customer: invoice.customer,
      explosives
    };
    console.log(invoice2);

    // Call save archived xml endpoint.

    const token = await Auth.currentSession()
      .then(data => {
        return data.idToken.jwtToken;
      })
      .catch(err => console.log(err));
    console.log(token);
    const res = await props.saveArchivedXml(token, { invoice: invoice2 });
    console.log(res);
  };

  return (
    <div className="Container">
      <Appbar title="Dalje" />
      <div className="Content">
        <Drawer baseURL={"/tracking/invoices/out/" + invoice_id + "/"} />
        <Main>
          <Switch>
            <Route
              exact
              path="/tracking/invoices/out/:invoice_id/invoice"
              render={() => (
                <Invoice
                  invoice_id={invoice_id}
                  saveChanges={handleSaveChanges}
                />
              )}
            />
            <Route
              exact
              path="/tracking/invoices/out/:invoice_id/xml"
              render={() => <Xml invoice_id={invoice_id} />}
            />
            <Route
              exact
              path="/tracking/invoices/out/:invoice_id/products"
              render={() => <Products invoice_id={invoice_id} />}
            />
            <Route
              exact
              path="/tracking/invoices/out/:invoice_id/uids"
              render={() => <Uids invoice_id={invoice_id} />}
            />
          </Switch>
        </Main>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ saveArchivedXml }, dispatch);

const mapStateToProps = (state, ownProps) => {
  const { invoices } = state.tracking;
  const { invoice_id } = ownProps.match.params;
  const myInvoice = invoices[invoice_id];
  return { invoice: myInvoice ? myInvoice : null };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackingInvoiceOut);
