import {
  MERGE_CUSTOMERS,
  SET_FETCHING_CUSTOMERS_STATUS,
  SET_LAST_FETCHED_TIMESTAMP
} from "../types";

const INITIAL_STATE = {
  customers: {},
  fetchingCustomers: false,
  lastFetchedTimestamp: 0
};

const customerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MERGE_CUSTOMERS:
      const newState = {
        ...state,
        customers: {
          ...state.customers,
          ...action.customers.reduce((customers, currentCustomer) => {
            return {
              ...customers,
              [currentCustomer.customer_id]: currentCustomer
            };
          }, {})
        }
      };
      return newState;
    case SET_FETCHING_CUSTOMERS_STATUS:
      return {
        ...state,
        fetchingCustomers: action.status
      };
    case SET_LAST_FETCHED_TIMESTAMP:
      return {
        ...state,
        lastFetchedTimestamp: action.timestamp
      };
    default:
      return state;
  }
};

export default customerReducer;
