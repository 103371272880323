import React from "react";
import CustomerCard from "./components/CustomerCard";
import "./list.css";
import { Dimmer, Header, Icon, Loader } from "semantic-ui-react";
import { Link } from "react-router-dom";

const styles = {
  messageContainer: {
    flex: 1,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  message: {
    display: "flex",
    flexDirection: "column",
    background: "transparent"
  }
};

const CustomerList = props => {
  const handleCustomerSelection = customer => {
    console.log("SELECTED CUSTOMER: " + customer.name);
  };

  const { loadingError, errorMessage, loading, customers } = props;

  if (loadingError) {
    return (
      <div style={styles.messageContainer}>
        <div style={styles.message}>
          <Header icon>
            <Icon name="x" />
            Server Error. {errorMessage}
          </Header>
        </div>
      </div>
    );
  } else if (loading) {
    console.log("LOADING..." + loading);
    return (
      <Dimmer active blurring inverted>
        <Loader />
      </Dimmer>
    );
  } else if (customers.length === 0) {
    return (
      <div className="CustomerList">
        <div className="List">
          <h2>
            {" "}
            Nuk ka kliente, regjistroni nje te ri{" "}
            <Link to="/customer/add"> ketu </Link>{" "}
          </h2>
        </div>
      </div>
    );
  } else {
    return (
      <div className="CustomerList">
        <div className="List">
          {customers.map((customer, index) => (
            <CustomerCard
              key={customer.customer_id + index}
              id={customer.customer_id}
              name={customer.name}
              address={customer.address ? customer.address : null}
              email={customer.email ? customer.email : null}
              phone={customer.phone ? customer.phone : null}
              handleSelection={handleCustomerSelection}
            />
          ))}
        </div>
      </div>
    );
  }
};

export default CustomerList;
