/**
 * Copyright Redi Kurti, 2020. All rights reserved.
 */

import React from "react";
import { Auth } from "aws-amplify";

import AppBar from "../../components/Appbar/Appbar";
import Main from "../AppBar/main/Main";
import axios from "axios";
import * as API2 from "../../utils/API";
import _ from "lodash";
import { Provider } from "../context";
import Navigation from "./Navigation";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MoveFile from "./MoveFile";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import FileList from "./FileList";
import FilesToolbar from "./FilesToolbar";
import Input from "../../components/Input/Input";
import { buildPrefix } from "../../utils/Files";
import "./file.css";
import FilesDrawer from "../../components/Files/FilesDrawer";
import NewFolderModal from "../../components/Files/NewFolderModal";

export default class Files extends React.Component {
  toastId = null;
  successToast = message =>
    (this.toastId = toast.success(message, {
      autoClose: true,
      hideProgressBar: true
    }));
  errorToast = message =>
    (this.toastId = toast.error(message, {
      autoClose: true,
      hideProgressBar: true
    }));

  constructor(props) {
    super(props);

    this.state = {
      user: {},

      sideBarOpen: true,

      movingFiles: false,
      moveToDestination: {},

      modalOpen: false,
      inputValue: "",
      inputIsLoading: false,
      inputError: false,

      noResults: false,

      loadingError: false,
      loading: true,
      errorMessage: "",

      files: [],
      navigation: [],
      displayResults: []
    };
  }

  componentDidMount() {
    this._fetchFiles([]);
  }
  getToken = async () => {
    return await Auth.currentSession()
      .then(data => {
        return data.idToken.jwtToken;
      })
      .catch(err => console.log(err));
  };

  // SearchBox
  handleSearchChange = e => {
    const { value } = e.target;
    if (this.state.files.length < 1) return;
    this.setState({ inputIsLoading: true, inputValue: value });
    setTimeout(() => {
      if (value.length < 1) {
        this.setState({
          displayResults: this.state.files
        });
      } else {
        this.setState({
          inputIsLoading: false
        });
      }
    }, 300);
  };

  // NAVIGATE DIRECTORIES
  _navigateTo = destination => {
    if (destination === "home") {
      this.setState({
        navigation: []
      });
      return;
    }

    const indexOfDest = this.state.navigation.indexOf(destination);
    // If previously visited, go back by deleting the children of 'destination'.
    if (indexOfDest > -1) {
      this.setState({
        navigation: this.state.navigation.filter((nav, index) => {
          return index <= indexOfDest;
        })
      });
    } else {
      // Fetch files with the new destination

      const navigation = [...this.state.navigation, destination];

      if (
        this.state.files.filter(file => {
          return file.prefix === navigation.join("/");
        }).length > 0
      ) {
        this.setState({
          navigation
        });
        return;
      }

      this._fetchFiles(navigation);
      this.setState({
        navigation
      });
    }
  };
  _fetchFiles = async navigation => {
    const token = await Auth.currentSession()
      .then(data => {
        return data.idToken.jwtToken;
      })
      .catch(err => console.log(err));

    // Join the destination array into a directory path
    let prefix = navigation.join("/");
    // Build the url for the destination
    const url = `https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/files?prefix=${prefix}`;
    this.setState({ loading: true }); // Set to true to render loader

    axios({
      method: "get",
      headers: { Authorization: token, tenant_id: "reden_01" },
      url
    })
      .then(res => {
        const fetchedFiles = res.data;
        console.log(fetchedFiles);
        // No response.
        if (fetchedFiles === null) {
          this.setState({ loading: false, loadingError: true });
          this.errorToast("No response from server.");
          return;
        }
        // Fetch successful
        this.setState({
          files: [...this.state.files, ...fetchedFiles],
          displayResults: fetchedFiles,
          loading: false
        });
      })
      .catch(error => {
        this.setState({ loading: false });
        this.errorToast(error.message);
      });
  };

  // MOVE FILES OPERATIONS
  _moveFiles = () => {
    const { moveToDestination } = this.state;
    console.log("moveToDestination", moveToDestination);

    console.log("MOVING FILES");
    console.log("TO: ", this.state.moveToDestination);
    const files = this._getSelectedFiles().map(file => {
      console.log("FILOQYL", file);
      return {
        Bucket: file.Bucket,
        Key: file.Key,
        Name: file.Name
      };
    });

    if (files.length === 0) return;
    const destination =
      moveToDestination.path === ""
        ? files[0].Key.split("/")[0] + "/"
        : moveToDestination.path;
    this._moveFilesHTTP(files, destination)
      .then(res => {
        console.log(res);
        if (res.data.status === 201) {
          this._closeMoveFile();
          this._deleteFileHTTP(files)
            .then(this._handleDeleteFileResponse)
            .catch(err => console.log(err));
        }
      })
      .catch(err => console.log);
  };
  _moveFilesHTTP = async (files, destination) => {
    const token = await this.getToken();
    const data = {
      files,
      destination
    };
    const config = {
      headers: {
        Authorization: token
      }
    };
    return axios.post(API2.TRACKING_FILES_MOVE, data, config);
  };
  _selectAsDestination = async folder => {
    this.setState((prevState, props) => {
      return {
        files: prevState.files.map(file => {
          if (file.name === folder.name)
            return {
              ...file,
              isLoadingChildren: true
            };
          else return file;
        }),
        moveToDestination: folder
      };
    });

    if (folder.isHome) return;

    const fullName = folder.prefix === "" ? folder.name : "/" + folder.name;
    const children = this.state.files.filter(stateFile => {
      console.log("Comparing", fullName, " with ", stateFile);
      return stateFile.prefix === folder.prefix + fullName;
    });

    const token = await Auth.currentSession()
      .then(data => {
        return data.idToken.jwtToken;
      })
      .catch(err => console.log(err));

    if (children.length === 0 && !folder.isEmpty) {
      axios({
        method: "get",
        headers: {
          Authorization: token,
          tenant_id: "reden_01"
        },
        url: `https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/files?prefix=${folder.prefix +
          fullName}`
      })
        .then(res => {
          const fetchedFiles = res.data;
          console.log(fetchedFiles);
          if (fetchedFiles === null) {
            console.log("fetchedFiles is NULL");
            this.setState({
              loading: false,
              loadingError: true
            });
            return;
          }

          console.log("THIS FOLDER IS EMPTY: ", folder);

          const files = this.state.files.map(file => {
            if (file.name === folder.name) {
              console.log("UPDATING FILE THAT WASLAKJDSLDJALSJDLAKSJD");
              return {
                ...file,
                isLoadingChildren: false,
                isEmpty: fetchedFiles.length === 0
              };
            } else {
              return file;
            }
          });
          // Fetch successful
          this.setState({
            files: [...files, ...fetchedFiles],
            displayResults: fetchedFiles,
            loading: false
          });
        })
        .catch(error => {
          this.setState({
            loading: false,
            loadingError: true,
            errorMessage: error.message
          });
        });
    } else {
      console.log("FOUND CHILDREN: ", children);

      this.setState((prevState, props) => {
        return {
          files: prevState.files.map(file => {
            if (file.name === folder.name)
              return {
                ...file,
                isLoadingChildren: false
              };
            else return file;
          })
        };
      });
    }
  };
  _closeMoveFile = () => {
    this.setState({
      movingFiles: false, // Close the sidebar
      moveToDestination: {}, // Remove selection
      files: [
        ...this.state.files.map(file => {
          return { ...file, isSelectedAsDestination: false };
        })
      ] // Remove as destination
    });
  };
  _openMoveFile = () => {
    this.setState({
      movingFiles: true
    });
  };

  // DOWNLOAD ZIP OPERATIONS
  _handleOnZip = async () => {
    let files = this._getSelectedFiles();

    console.log("BEFORE DOWNLOAD: ", files);

    files = await Promise.all(
      files.map(async file => {
        // Use map for multiple async calls
        return {
          ...file,
          blob: await this._downloadFile(file)
        };
      })
    );

    console.log("AFTER", files);

    this._generateZip(files);
  };
  _downloadFile = async file => {
    console.log("DOWNLOADING FILE", file.Name);
    return axios({
      url: file.URL,
      method: "GET",
      responseType: "blob" // blob required to create zipJS file.
    }).then(response => {
      return response.data; // Return data
    });
  };
  _generateZip = files => {
    const zip = new JSZip();

    files.forEach(file => {
      zip.file(file.Path, file.blob);
    });

    zip.generateAsync({ type: "blob" }).then(zipFile => {
      saveAs(zipFile, "kontenjer.zip");
    });
  };

  // SELECT FILE OPERATIONS
  _handleFileSelection = ({ name, prefix }) => {
    console.log(name, prefix);

    this.setState({
      files: this.state.files.map(file => {
        if (file.name === name && file.prefix === prefix) {
          file = {
            ...file,
            isSelected: !file.isSelected
          };

          return file;
        } else {
          return file;
        }
      }),
      inputIsLoading: false
    });
  };
  _getSelectedFiles = () => {
    return this.state.files
      .filter(file => {
        return file.isSelected === true;
      })
      .map(file => {
        let name = file.name;
        if (file.isFolder) name += "/";

        return {
          Bucket: file.bucketName,
          Key: file.path,
          Name: name,
          URL: file.url,
          isFolder: file.isFolder,
          Path: file.prefix === "" ? file.name : file.prefix + "/" + file.name
        };
      });
  };

  // DELETE FILES OPERATIONS
  _handleFileDeletion = () => {
    console.log("DELETING SELECTED FILES...");

    const filesToDelete = this._getSelectedFiles();

    if (filesToDelete.length === 0) return;

    filesToDelete.forEach(fileToDelete => {
      this.setState((prevState, props) => {
        return {
          files: prevState.files.map(file => {
            if (file.path === fileToDelete.Key)
              return { ...file, isLoading: true };
            else return file;
          })
        };
      });
    });

    console.log(filesToDelete);

    this._deleteFileHTTP(filesToDelete)
      .then(this._handleDeleteFileResponse)
      .catch(err => console.log(err));
  };
  _deleteFileHTTP = async files => {
    const token = await this.getToken();
    return axios({
      method: "post",
      headers: {
        Authorization: token
      },
      url: API2.TRACKING_FILES_DELETE,
      data: { files }
    });
  };
  _handleDeleteFileResponse = response => {
    console.log(response);
    if (response.data.status === 201) {
      this.setState({
        files: this.state.files.filter(file => !file.isSelected)
      });
      this.successToast("Selected files were deleted.");
    } else {
      this.errorToast("There was an error with deleting the files.");
      this._getSelectedFiles().forEach(fileToDelete => {
        this.setState((prevState, props) => {
          return {
            files: prevState.files.map(file => {
              if (file.path === fileToDelete.Key)
                return { ...file, isLoading: false, error: true };
              else return file;
            })
          };
        });
      });
    }
  };

  // UPLOAD FILES OPERATIONS
  _handleAddFilesClick = () => {
    const fileSelector = document.createElement("input");
    fileSelector.setAttribute("type", "file");
    fileSelector.setAttribute("multiple", "multiple");
    fileSelector.setAttribute("name", "file");
    fileSelector.addEventListener("change", this._onFileSelected);

    window.setTimeout(() => fileSelector.click(), 50);
  };
  _onFileSelected = async e => {
    // Get all selected files.
    let selectedFiles = e.target.files;

    // return; if there was no selection.
    if (selectedFiles.length < 1) {
      return;
    }

    for (let i = 0; i < selectedFiles.length; i++) {
      let file = {
        nativeFile: selectedFiles[i],
        name: selectedFiles[i].name,
        prefix: this.state.navigation.join("/"),
        isSelected: false,
        uploading: true,
        percentageUploaded: 0,
        uploadingError: "",
        isLoading: true
      };

      // Add file to list of files in state
      this.setState({
        files: [file, ...this.state.files]
      });

      this._getSignedURL(file, file.name, false, file.nativeFile.type);
    }
  };
  _getSignedURL = async (file, key, tracking, contentType) => {
    const token = await this.getToken();
    const data = {
      key,
      prefix: buildPrefix(this.state.navigation),
      contentType
    };
    console.log(data);
    return axios({
      method: "post",
      url: API2.TRACKING_FILES_UPLOAD,
      headers: {
        Authorization: token
      },
      data
    })
      .then(res => {
        console.log(res);
        file.url = res.data;
        this._uploadToS3(file, contentType, file.nativeFile);
      })
      .catch(error => {
        file.error = error;
        console.log(error);
      });
  };
  _uploadToS3 = (fileToUpload, fileType, nativeFile) => {
    console.log(fileToUpload);

    // S3 PUT request configurations: file type, upload loader handling.
    const config = {
      headers: {
        "Content-Type": fileType
      },
      onUploadProgress: progressEvent => {
        let percentageUploaded = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        console.log(fileToUpload.name + " " + percentageUploaded);
        this.setState({
          files: this.state.files.map(file => {
            if (file.name === fileToUpload.name) {
              fileToUpload = {
                ...fileToUpload,
                percentageUploaded,
                isLoading: true
              };
              return fileToUpload;
            } else {
              return file;
            }
          })
        });
      }
    };

    // S3 PUT request with pre-signed URL from Lambda.
    return axios.put(fileToUpload.url, nativeFile, config).then((res, err) => {
      if (err) {
        this.errorToast("There was an error uploading files.");
        console.log(err);
      } else {
        console.log(res);
        this.successToast(
          "File " + fileToUpload.name + " uploaded successfully."
        );
      }

      this.setState(
        {
          files: this.state.files.map(file => {
            if (file.name === fileToUpload.name) {
              fileToUpload = {
                ...fileToUpload,
                uploadingComplete: res.status === 200,
                isLoading: false
              };
              return fileToUpload;
            } else {
              return file;
            }
          })
        },
        () => console.log(this.state.files)
      );
    });
  };

  // NEW FOLDER OPERATIONS
  _openNewFolderModal = () => {
    this.setState({
      modalOpen: true
    });
  };
  _closeNewFolderModal = () => {
    this.setState({
      modalOpen: false
    });
  };
  _saveNewFolder = name => {
    this._closeNewFolderModal(); // Close the modal.
    // Create new folder object.
    const folder = {
      name,
      isSelected: false,
      isFolder: true,
      prefix: this.state.navigation.join("/"),
      isLoading: true
    };
    this.setState({ files: [folder, ...this.state.files] });
    this._getSignedURL(folder, folder.name + "/", false, "");
  };

  render() {
    const {
      state: {
        isLoading,
        files,
        navigation,
        modalOpen,
        loading,
        movingFiles,
        moveToDestination
      }
    } = this;

    let filesOfCurrentDirectory = [];
    if (files.length > 0 && !isLoading) {
      const currentDirectory = navigation.join("/");
      filesOfCurrentDirectory = files.filter(file => {
        return file.prefix === currentDirectory;
      });
    }

    const re = new RegExp(_.escapeRegExp(this.state.inputValue), "i");
    const isMatch = result => re.test(result.name);
    let filesToDisplay = _.filter(filesOfCurrentDirectory, isMatch);

    return (
      <Provider
        value={{
          files,
          navigation,
          moveToDestination,
          actions: {
            selectFile: this._handleFileSelection,
            deleteFile: this._handleFileDeletion,
            navigateTo: this._navigateTo,
            saveNewFolder: this._saveNewFolder,
            selectAsDestination: this._selectAsDestination,
            confirmMove: this._moveFiles,
            closeMoveFile: this._closeMoveFile,
            openMoveFile: this._openMoveFile,

            newFiles: this._handleAddFilesClick,
            deleteFiles: this._handleFileDeletion,
            downloadZip: this._handleOnZip,
            newFolder: this._openNewFolderModal
          }
        }}
      >
        <div className="Container">
          <ToastContainer />
          {modalOpen && (
            <NewFolderModal
              isOpen={modalOpen}
              close={this._closeNewFolderModal}
              onSave={this._saveNewFolder}
            />
          )}
          <AppBar title="Files">
            <Input onChange={this.handleSearchChange} />
          </AppBar>

          <div className="FileListRoot">
            <FilesToolbar selectedFiles={this._getSelectedFiles()} />
            <Navigation />
            <FileList files={filesToDisplay} loading={loading} display="icon" />
          </div>

          {movingFiles && (
            <MoveFile readyToSelect={!_.isEmpty(moveToDestination)} />
          )}
        </div>
      </Provider>
    );
  }
}
