import { UPDATE_NEW_INVOICE, SET_LOADING_STATUS } from "../types";
import moment from "moment";
const INITIAL_STATE = {
  newInvoice: {
    invoice_id: "",
    customer: {},
    date: moment(),
    isIn: false,
    isTracking: true,
    status: "pending",
    items: []
  },
  newInvoiceLoading: false
};

const invoiceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_NEW_INVOICE:
      return {
        ...state,
        newInvoice: {
          ...state.newInvoice,
          ...action.newInvoice
        }
      };
    case SET_LOADING_STATUS:
      return {
        ...state,
        newInvoiceLoading: action.status
      };
    default:
      return state;
  }
};

export default invoiceReducer;
