import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import localforage from "localforage";

import rootReducer from "./containers/redux/reducers";

const persistConfig = {
  key: "root",
  version: 0.2,
  storage: localforage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);
