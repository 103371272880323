import React from "react";
import { Consumer } from "../context";
import FileActionButton from "../../components/Files/FileActionButton";
import FilePlus from "../../components/Icon/FilePlus";
import FolderPlus from "../../components/Icon/FolderPlus";
import Trash2 from "../../components/Icon/Trash2";
import DownloadCloud from "../../components/Icon/DownloadCloud";
import FileMove from "../../components/Icon/FileMove";

import "./file.css";

export default class FilesToolbar extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      props: { selectedFiles }
    } = this;

    const len = selectedFiles.length;
    const notFolder = selectedFiles.filter(file => !file.isFolder);
    const notFolderLen = notFolder.length;
    console.log("NOT FOLDERS", notFolder);

    console.log("NR. of selected files", len);

    return (
      <Consumer>
        {context => (
          <div className="FilesToolbar">
            <div className="FilesToolbarActionContainer">
              <FileActionButton
                title="New File"
                color="#373C46"
                onClick={context.actions.newFiles}
              >
                <FilePlus color="#373C46" />
              </FileActionButton>
              <FileActionButton
                title="New Folder"
                color="#373C46"
                onClick={context.actions.newFolder}
              >
                <FolderPlus color="#373C46" />
              </FileActionButton>
              <FileActionButton
                disabled={notFolderLen < 2}
                title="Download zip"
                color="#373C46"
                onClick={context.actions.downloadZip}
              >
                <DownloadCloud color={notFolderLen < 2 ? "#99B3B6" : "#373C46"} />
              </FileActionButton>
              <FileActionButton
                disabled={len < 1}
                color="#373C46"
                title="Move to"
                onClick={context.actions.openMoveFile}
              >
                <FileMove color={len < 1 ? "#99B3B6" : "#373C46"} />
              </FileActionButton>
              <FileActionButton
                disabled={len < 1}
                color="indianred"
                title="Delete"
                onClick={context.actions.deleteFiles}
              >
                <Trash2 color={len < 1 ? "#99B3B6" : "indianred"} />
              </FileActionButton>
            </div>
          </div>
        )}
      </Consumer>
    );
  }
}

FilesToolbar.propTypes = {};
