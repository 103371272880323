/**
 * Copyright Redi Kurti, 2019. All rights reserved.
 */

import React from "react";
import SummaryItemContainer from "./SummaryItemContainer";
import "./summary-item.css";

const XmlFileList = props => {
  const { files } = props;

  if (!files || files.length === 0)
    return <div>No documents are listed for this invoice.</div>;

  return (
    <div className="xml-file-list-root">
      {files.map(file => {
        return (
          <SummaryItemContainer key={file.name} completed={"0"} file={file} />
        );
      })}
    </div>
  );
};

export default XmlFileList;
