/**
 * Copyright Redi Kurti, 2020. All rights reserved.
 */

import React from "react";
import Omnicon from "../assets/icons/Omnicon";

const styles = {
  saveButton: {
    paddingHorizontal: 20,
    paddingVertical: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 16,
    width: "100%",
    height: "100%",
    borderRadius: 100,
    shadowOffset: {
      width: 8,
      height: 8
    },
    shadowOpacity: 0.5,
    shadowRadius: 15,
    elevation: 5,
    cursor: 'pointer',
  },
  saveButtonText: {
    fontFamily: "Lato",
    fontSize: 20
  },
  icon: {
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: 'relative',
  },
};

const MainButton = props => {
  const { title, iconName, iconColor, onPress, color, textColor } = props;
  return (
    <div
      style={{
        ...styles.saveButton,
        backgroundColor: color ? color : "blue",
        shadowColor: color ? color : "blue"
      }}
      onClick={onPress}
    >
    <div style={styles.icon}>
      <Omnicon />
      </div>
      <div
        style={{
          color: textColor ? textColor : "white",
          ...styles.saveButtonText
        }}
      >
        {title ? title : "JEPI"}
      </div>
      <div style={styles.icon}>
      <Omnicon
        name={iconName ? iconName : "camera"}
        color={iconColor ? iconColor : "white"}
        size={24}
      />
      </div>
    </div>
  );
};

export default MainButton;
