import React, {Component} from 'react';
import {AppBar, DrawerContainer, DrawerItem, Main} from "../../AppBar";
import {Provider} from "../../context";
import {Dimmer, Label, Loader} from "semantic-ui-react";
import * as API from "../../../dowhileAPI";
import axios from "axios";



const styles = {
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        padding: '20px',
    },
    header: {
        display: 'flex',
        flex: '0.1',
        width: '100%',
        fontSize: '3.5em',
        fontWeight: '900',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    body: {
        display: 'flex',
        justifyContent: 'space-between',
        flex: 1,
        width: '100%',
        height: '100%',
        flexDirection: 'row',
        padding: '20px 0',
    },
    columnContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexGrow: 1,
        padding: '0 10px',
    }
};

class InventoryView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: {},
            item: {
                name: "",

            },
            id: "",
            sideBarOpen: true,
            loading: false,
        }
    }

    componentWillMount() {

        // Get the <id> of the item from the url.
        const id = this.props.location.search.split('=')[1];

        console.log("SELECTED ITEM ID", id);

        this.setState({
            id: id,
        });


    }

    _handleUserData = (user) => {

        // TODO: Remove debugging message.
        console.log("USER DATA FROM APPBAR-> ");
        console.log(user);

        if (user == null) {

            console.log("USER NOT FOUND.");

        } else {

            this.setState({
                user: user
            });

            this._fetchItem();
        }
    };


    _fetchItem = () => {

        this.setState({
            loading: true,
        });

        const { user, id } = this.state;

        axios({
            method: 'post',
            url: API.GET_ITEM,
            headers: {
                Authorization: user.idToken,
                "tenant_id": user.tenantID
            },
            data: {
                id
            }
        }).then(res => {
            console.log("SUCCESS! ITEM:", res.data.items[0]);
            if (res.data.status === 201) {
                this.setState({
                    item: res.data.items[0],
                    loading: false,
                    error: null,
                })
            } else {

                console.log("ERROR FROM SERVER", res.data.error);

                this.setState({
                    item: {},
                    loading: false,
                    error: res.data.error
                })
            }
        }).catch( error => {

            console.log("CLIENT ERROR", error);
            this.setState({
                item: {},
                loading: false,
                error: error
            })
        })




    };


    _handleSideBarToggle = () => {
        this.setState({
            sideBarOpen: !this.state.sideBarOpen
        })
    };


    render() {

        const { item, id, sideBarOpen, loading } = this.state;



        return (

            <Provider value={{
                sideBarOpen,
                actions: {
                    // Product actions
                }
            }}>
            <div className="Container">
                <AppBar
                    service="inventory"
                    title="Artikujt"
                    getUserData={this._handleUserData}
                    handleSideBarToggle={ this._handleSideBarToggle } />


                {
                    loading && (
                        <Dimmer active blurring inverted>
                            <Loader/>
                        </Dimmer>
                    )
                }
                    <div className="Content">
                        {
                            sideBarOpen &&
                            <DrawerContainer anchor="left">

                                <DrawerItem
                                    title="Edit"
                                    iconName="file outline"
                                    iconSize="large"
                                    iconColor="black"
                                    handleClick={ () => this.props.history.push(`/inventory/view/edit?id=${id}`) }/>
                                <DrawerItem
                                    title="Delete"
                                    iconName="trash alternate outline"
                                    iconSize="large"
                                    iconColor="black"
                                    handleClick={() => console.log("DELETE") }
                                    primaryNegative/>

                            </DrawerContainer>
                        }
                        <Main>
                            <div style={styles.container}>
                                <div style={styles.header}>
                                    <div>

                                        { item.name }

                                    </div>

                                    <Label
                                        circular
                                        color={item.tag}
                                        size="massive"
                                        empty
                                    />

                                </div>
                                <div style={styles.body}>

                                    <div style={styles.columnContainer}>

                                        CURRENT INVENTORY GRAPH


                                    </div>

                                    <div style={styles.columnContainer}>

                                        TX


                                    </div>

                                    <div style={styles.columnContainer}>

                                        ABC


                                    </div>

                                </div>
                            </div>
                        </Main>
                    </div>
                </div>
            </Provider>
        );
    }
}

InventoryView.propTypes = {};

export default InventoryView;

