import React from "react";

// Network imports
import { Auth } from "aws-amplify";

import Appbar from "../../components/Appbar/Appbar";
import _ from "lodash";
import CustomerList from "./list/CustomerList";
import { Input } from "semantic-ui-react";
import InvoiceSectionHeader from "../../components/Tracking/invoices/InvoiceSectionHeader";
import DrawerItemMainAction from "../../components/DrawerItems/MainAction/DrawerItemMainAction";

// Redux imports
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchCustomers,
  setFetchingCustomersStatus
} from "../redux/actions/CustomerActions";

import "./customer-container.css";

class CustomerContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      sideBarOpen: true,

      // Customer
      data: [],
      value: "",
      isSearching: false,
      noResults: false,
      loading: true,
      loadingError: false,
      errorMessage: ""
    };
  }

  componentWillUnmount() {
    this.props.setFetchingCustomersStatus(false);
  }

  _handleNewCustomerClick = () => {
    this.props.history.push("/tracking/customers/create");
  };

  // SearchBox
  handleSearchChange = (e, { value }) => {
    this.setState({ value });
    const { customers } = this.props;
    const newData = customers.filter(item => {
      const itemData = `${item.name.toUpperCase()}   
      ${item.customer_id}`;
      const textData = value.toUpperCase();
      return itemData.indexOf(textData) > -1;
    });
    console.log(newData);
    this.setState({
      data: newData
    });
  };

  dataToRender = () => {
    const { customers } = this.props;
    const { data, value } = this.state;
    if (!customers || customers.length === 0) return [];
    if (value === "") return customers;
    return data.sort((prev, next) => prev.name.localeCompare(next.name));
  };

  handleFetchCustomers = async () => {
    const token = await Auth.currentSession()
      .then(data => {
        const token = data.idToken.jwtToken;
        if (token) return token;
        return null;
      })
      .catch(err => console.log(err));
    token && this.props.fetchCustomers(token);
  };

  renderActionRefresh = () => {
    return (
      <DrawerItemMainAction
        iconName="download"
        iconColor="white"
        iconSize={24}
        handleClick={this.handleFetchCustomers}
      />
    );
  };

  renderActionNew = () => {
    return (
      <DrawerItemMainAction
        iconName="plus"
        iconColor="white"
        iconSize={24}
        handleClick={this._handleNewCustomerClick}
      />
    );
  };

  render() {
    const { loading } = this.props;
    return (
      <div className="customer-container-root">
        <Appbar title="Klientë">
          <Input
            className="SearchBox"
            placeholder="Search..."
            icon="search"
            transparent
            onChange={_.debounce(this.handleSearchChange, 500, {
              loading: true
            })}
          />
        </Appbar>
        <div className="customer-container-content">
          <InvoiceSectionHeader
            title="Te gjithë klientët"
            renderAction={this.renderAction}
            actions={[this.renderActionRefresh, this.renderActionNew]}
          />
          <div className="customer-container-body">
            <CustomerList
              customers={this.dataToRender()}
              loading={loading}
              source="customer"
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCustomers,
      setFetchingCustomersStatus
    },
    dispatch
  );

const mapStateToProps = state => {
  const { customers, fetchingCustomers, lastFetchedTimestamp } = state.customer;
  console.log(customers);
  return {
    customers: Object.keys(customers).map(key => customers[key]),
    customerLastFetchedTimestamp: lastFetchedTimestamp,
    loading: fetchingCustomers
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerContainer);
