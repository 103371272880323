import React from "react";
import './icon.css';

const styles = {
    root: {
        width: '24px',
        height: '24px',
        position: 'relative',
        display: 'flex',
    },
};

export default class FilePlus extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {

        let { color } = this.props;
        color = color ? color : 'black';

        return (
            <div style={styles.root}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                fill="none" stroke={color} strokeWidth="1" strokeLinecap="round"
                strokeLinejoin="round" className="feather feather-file-plus">
                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                    <polyline points="14 2 14 8 20 8" />
                    <line x1="12" y1="18" x2="12" y2="12" />
                    <line x1="9" y1="15" x2="15" y2="15" />
                </svg>
            </div>
        );
    }
}
