import React from "react";
import { AppBar, DrawerContainer, DrawerItem, Main } from "../AppBar/index";
import InventoryList from "./list/InventoryList";
import { Dimmer, Input, Loader } from "semantic-ui-react";
import Backdrop from "../AppBar/backdrop/Backdrop";
import axios from "axios";
import * as API from "../../dowhileAPI";
import _ from "lodash";
import { Route, Switch } from "react-router-dom";
import InventoryView from "./view/InventoryView";
import { Provider } from "../context";
import InventoryViewEdit from "./view/InventoryViewEdit";
import Catalog from "./Catalog";
import CatalogCreate from "./CatalogCreate";

class Inventory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      loadingSomeAction: false,
      sideBarOpen: true,

      // Inventory data
      displayResults: [],
      value: "",
      isSearching: false,
      noResults: false,
      items: [],
      loading: true,
      loadingError: false,
      errorMessage: ""
    };
  }

  componentDidMount() {
    this.setState({
      sideBarOpen: window.innerWidth > 768
    });
  }

  _handleUserData = user => {
    // TODO: Remove debugging message.
    console.log("USER DATA FROM APPBAR-> ");
    console.log(user);

    if (user == null) {
      console.log("USER NOT FOUND FROM APPBAR.");
    } else {
      this.setState({
        user: user
      });

      this._fetchItems();
    }
  };
  _handleSideBarToggle = () => {
    console.log("CLICK");
    this.setState(prevState => {
      return { sideBarOpen: !prevState.sideBarOpen };
    });
  };

  // Handle Interaction with server.
  _fetchItems = () => {
    const { user } = this.state;

    axios({
      method: "post",
      headers: {
        Authorization: user.idToken,
        tenant_id: user.tenantID
      },
      url: API.READ_INVENTORY
    })
      .then(res => {
        console.log(res);

        if (res.data.status !== 201)
          this.setState({
            loading: false,
            loadingError: true,
            errorMessage: "res.data is NULL."
          });

        this.setState({
          items: res.data.catalog,
          displayResults: res.data.catalog,
          loading: false
        });
      })
      .catch(error => {
        this.setState({
          loading: false,
          loadingError: true,
          errorMessage: error.message
        });
      });
  };

  onInputChange = (e, { value }) => {
    this.handleSearchChange(value);
  };

  // SearchBox
  handleSearchChange = value => {
    if (this.state.items.length < 1) return;

    this.setState({ isSearching: true, value });

    setTimeout(() => {
      if (value.length < 1) {
        this.setState({
          displayResults: this.state.items
        });
      } else {
        const re = new RegExp(_.escapeRegExp(this.state.value), "i");
        const isMatch = result =>
          re.test(result.name) ||
          re.test(result.category) ||
          re.test(result.product_id);

        this.setState({
          isSearching: false,
          displayResults: _.filter(this.state.items, isMatch)
        });
      }
    }, 300);
  };

  _handleItemSelection = itemID => {
    this.setState({
      selectedItem: this.state.items.filter(item => {
        return item.sku === itemID;
      })[0]
    });
  };
  _handleItemEdit = itemID => {
    console.log("ITEM TO EDIT IN INVENTORY.JS", itemID);
    this.props.history.push("/inventory/view/edit");
  };
  _handleItemDelete = itemID => {
    console.log("ITEM TO DELETE IN INVENTORY.JS", itemID);
  };

  _handleNewItem = () => {
    console.log("Inventory Action -> Handle new item.");
    this.props.history.push("/inventory/add");
  };

  // ROUTING
  // Route to catalog, a list of all products on the catalog.
  showCatalog = () => {
    this.setState({
      activeDrawer: "catalog", // Set active drawer
      sideBarOpen: window.innerWidth >= 768 // Close sidebar if on mobile
    });

    this.props.history.push("/inventory/catalog/"); // Redirect to route.
  };
  // Route to catalog item form, to create a new catalog item on server.
  showCatalogCreate = () => {
    this.setState({
      activeDrawer: "catalog", // Set active drawer
      sideBarOpen: window.innerWidth >= 768 // Close sidebar if on mobile
    });

    this.props.history.push("/inventory/catalog/create/");
  };

  // After creating a new catalog item on server, add it to local state.
  addProduct = product => {
    // Add the new product to the catalogs local state.
    this.setState(prevState => {
      return {
        items: [product, ...prevState.items]
      };
    });

    // Update display results because the newly
    // + added item might match the current search.
    this.handleSearchChange(this.state.value);
  };

  handleItemClick = id => {
    console.log("CLICKD ITEM WITH ID: ", id);
    const item = this.state.items.filter(item => item.id === id)[0];
    if (item === -1) {
      console.log("COULD NOT FIND THE ITEM.");
      return;
    }

    console.log("FOUND ITEM: ", item);
    console.log("REDIRECTING...");
  }

  render() {
    const {
      user,
      items,
      loadingError,
      errorMessage,
      loading,
      displayResults,
      isSearching,
      selectedItem,
      sideBarOpen
    } = this.state;

    let showLoader = user == null || loading;

    const drawer = (
      <DrawerContainer 
        open={sideBarOpen} 
        toggle={this._handleSideBarToggle}
        goBack={this.props.history.goBack}>
        <DrawerItem
          title="New Item"
          iconName="add"
          iconSize="large"
          iconColor="black"
          handleClick={this.addItem}
          primaryPositive
        />
        <DrawerItem
          title="Catalog"
          iconName="paper"
          iconSize="large"
          iconColor="black"
          handleClick={this.showCatalog}
        />
      </DrawerContainer>
    );

    const list = (
      <div className="Content">
        {drawer}
        <Backdrop
          open={window.innerWidth < 590 && sideBarOpen}
          toggleBackdrop={this._handleSideBarToggle}
        />
        <Main>
          <InventoryList
            history={this.props.history}
            user={user}
            loadingError={loadingError}
            errorMessage={errorMessage}
            loading={loading}
            displayResults={displayResults}
          />
        </Main>
      </div>
    );

    return (
      <Provider
        value={{
          items,
          selectedItem,
          displayResults,
          sideBarOpen,
          history: this.props.history,
          actions: {
            // Product actions
            handleItemClick: this.handleItemClick,
            addItem: this._handleNewItem,
            editItem: this._handleItemEdit,
            deleteItem: this._handleItemDelete,
            handleSideBarToggle: this._handleSideBarToggle,
            showCatalog: this.showCatalog,
            showCatalogCreate: this.showCatalogCreate
          }
        }}
      >
        <div className="Container">
          <AppBar
            service="inventory"
            title="Artikujt"
            getUserData={this._handleUserData}
            handleSideBarToggle={this._handleSideBarToggle}
          >
            <Input
              className="SearchBox"
              placeholder="Search for item..."
              loading={isSearching}
              icon="search"
              transparent
              onChange={_.debounce(this.onInputChange, 500, { leading: true })}
            />
          </AppBar>

          {showLoader && (
            <Dimmer active blurring inverted>
              <Loader />
            </Dimmer>
          )}

          <Switch>
            <Route
              exact
              path="/inventory/view"
              render={() => (
                <InventoryView
                  history={this.props.history}
                  item={selectedItem}
                  user={user}
                />
              )}
            />
            <Route
              exact
              path="/inventory/view/edit"
              render={() => (
                <InventoryViewEdit
                  history={this.props.history}
                  item={selectedItem}
                />
              )}
            />
            <Route
              exact
              path="/inventory/catalog/"
              render={() => <Catalog />}
            />
            <Route
              exact
              path="/inventory/catalog/create"
              render={() => (
                <CatalogCreate
                  history={this.props.history}
                  user={user}
                  addProduct={this.addProduct}
                />
              )}
            />
            <Route
              exact
              path="/inventory/"
              render={() => list}
            />
          </Switch>
        </div>
      </Provider>
    );
  }
}

Inventory.propTypes = {};

export default Inventory;