import React, {Component} from 'react';
import { Label } from "semantic-ui-react";
import {Consumer} from "../context";

const styles = {
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    formContainer: {
        height: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '20px',
        boxShadow: '0 5px 10px #b9b9b9',
    },
    formContainerHeader: {
        padding: '15px 20px',
        flex: 0.2,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '20px 20px 0 0',
        backgroundImage: 'linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)'
    },
    headerEmpty: {
        flex: 1,
    },
    headerTitle: {
        fontSize: '2.5em',
        fontWeight: '900',
        color: '#484848',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    formContainerBody: {
        padding: '15px',
        flex: 0.7,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexGrow: 1,
        overflowY: 'auto',
    },
    formInput: {
        height: '35px',
        borderRadius: '5px',
        border: 'none',
        boxShadow: '0 1px 1px 0 #cfd9df',
        fontFamily: 'Lato, sans-serif',
        fontSize: '1em',
        padding: '0 6px',
        background: '#d3d3d3',
        width: '100%',
    },
    textAreaInput: {
        height: '80px',
        borderRadius: '5px',
        border: 'none',
        boxShadow: '0 1px 1px 0 #cfd9df',
        fontFamily: 'Lato, sans-serif',
        fontSize: '1em',
        padding: '5px',
        background: '#d3d3d3',
        width: '100%',
        resize: 'vertical',
    },
    inputContainer: {
        margin: '10px 0',
        width: '70%',
    },
    checkBoxContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '10px 0',
    },
    messageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'center',
        width: '40%',
        height: '20%',
    },
    message: {
        display: 'flex',
        flex: 0.45,
        flexDirection: 'column',
        justifySelf: 'center',
        alignSelf: 'center',
    }
};
const tags = [
    'red',
    'orange',
    'yellow',
    'olive',
    'green',
    'blue',
    'violet',
    'black',
];

class ProductForm extends Component {

    render() {
        return (
            <div style={styles.root}>
                <Consumer>
                    {context =>
                        (
                            <div style={styles.formContainer}>

                                <div style={styles.formContainerHeader}>
                                    <div style={styles.headerEmpty} />
                                    <div style={styles.headerTitle}>
                                        <div>Product</div>
                                        <Label
                                            circular
                                            color={context.product.tag}
                                            size="massive"
                                            empty
                                        />
                                    </div>
                                </div>

                                <div style={styles.formContainerBody}>
                                    <div style={styles.inputContainer}>
                                        <input
                                            style={{...styles.formInput,
                                                    background: context.errors.idEmpty ? 'indianred' : '#d3d3d3'}}
                                            placeholder='ID'
                                            type='text'
                                            value={context.product.id}
                                            onChange={ (e) => {context.actions.changeID(e)} }
                                        />
                                    </div>
                                    <div style={styles.inputContainer}>
                                        <input
                                            style={{...styles.formInput,
                                                background: context.errors.nameEmpty ? 'indianred' : '#d3d3d3'}}
                                            type='text'
                                            placeholder="Name"
                                            value={context.product.name}
                                            onChange={ (e) => {context.actions.changeName(e)} }
                                        />
                                    </div>
                                    <div style={styles.inputContainer}>
                                        <input
                                            style={{...styles.formInput,
                                                background: context.errors.priceEmpty ? 'indianred' : '#d3d3d3'}}
                                            type='text'
                                            placeholder="Price"
                                            value={context.product.price}
                                            onChange={ (e) => {context.actions.changePrice(e)} }
                                        />
                                    </div>
                                    <div style={styles.inputContainer}>
                                        <input
                                            style={{...styles.formInput,
                                                background: context.errors.categoryIDEmpty ? 'indianred' : '#d3d3d3'}}
                                            type='text'
                                            placeholder="Category"
                                            value={context.product.category_id}
                                            onChange={ (e) => {context.actions.changeCategory(e)} }
                                        />
                                    </div>
                                    <div style={styles.inputContainer}>
                                        <div>
                                            {
                                                tags.map(color =>
                                                    <Label
                                                        circular
                                                        color={color}
                                                        empty
                                                        size={(color === context.product.tag) ? 'huge' : 'large'}
                                                        key={color}
                                                        onClick={() => context.actions.changeTag(color)}
                                                    />
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div style={styles.inputContainer}>
                                        <textarea
                                            style={styles.textAreaInput}
                                            placeholder='Notes'
                                            value={context.product.notes}
                                            onChange={context.actions.changeNotes}
                                        />
                                    </div>
                                </div>

                            </div>
                        )
                    }
                </Consumer>
            </div>
        );
    }
}

export default ProductForm;
