import pako from "pako";

const groupInventoryByCode = items => {
  const itemsAsArray = Object.values(items);
  const itemsGrouped = itemsAsArray.reduce((group, item) => {
    const existingItem = group[item.product.code];
    if (existingItem) {
      return {
        ...group,
        [item.product.code]: {
          ...existingItem,
          itemQuantity: existingItem.itemQuantity + item.itemQuantity,
          units: [...existingItem.units, item]
        }
      };
    }
    return {
      ...group,
      [item.product.code]: {
        ...item,
        uid: item.product.code,
        units: [item]
      }
    };
  }, {});
  return itemsGrouped;
};

const inflateExplosives = deflatedExplosives => {
  return deflatedExplosives.map(explosive => {
    return {
      ...explosive,
      units: JSON.parse(pako.inflate(explosive.units, { to: "string" }))
    };
  });
};

const denormalizeExplosive = explosive => {
  if (explosive.isItem) return explosive;
  if (explosive.units) {
    explosive.units = explosive.units.map(unit =>
      denormalizeExplosive(mapParentDataToChild(explosive, unit))
    );
  }
  return explosive;
};

const mapParentDataToChild = (parentExplosive, child) => {
  const universalParams = extractUniversalDataFromParent(parentExplosive);
  if (child.units)
    return mapParentDataToChildThatHasUnits(universalParams, child);
  return mapParentDataToChildThatIsItem(universalParams, child);
};

const extractUniversalDataFromParent = parentExplosive => {
  return {
    psn: parentExplosive.psn,
    product: parentExplosive.product,
    file: parentExplosive.file,
    itemQuantity: parentExplosive.itemQuantity / parentExplosive.units.length,
    parentLine: parentExplosive.parentLine
      ? [...parentExplosive.parentLine, parentExplosive.uid]
      : [],
    invoice: parentExplosive.invoice
  };
};

const mapParentDataToChildThatHasUnits = (universalParams, child) => {
  return {
    ...universalParams,
    countOfTradeUnits: child.units.length,
    ...child
  };
};

const mapParentDataToChildThatIsItem = (universalParams, child) => {
  return {
    ...universalParams,
    uid: child,
    isItem: true
  };
};

const getUnits = (itemFromProps, itemsOnState) => {
  let units = itemFromProps.units;
  if (units) return units;
  if (itemFromProps.isInventory) {
    const itemFromState = itemsOnState[itemFromProps.uid];
    if (!itemFromState || !itemFromState.units) return [];
    return itemFromState.units;
  }
};

export {
  getUnits,
  denormalizeExplosive,
  inflateExplosives,
  groupInventoryByCode
};
