import React, { useState } from "react";
import SummaryItemCard from "./SummaryItemCard";
import PropTypes from "prop-types";
import { Icon, Loader } from "semantic-ui-react";

// Redux imports
import { removeFile } from "../../../../containers/redux/actions/TrackingActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "./summary-item.css";

const SummaryItemContainer = props => {
  const { file, removeFile } = props;
  const [isOpen, toggleOpen] = useState(true);

  const progressBarStyle = {
    width: `100%`
  };

  let actions;

  if (file.loading) {
    actions = (
      <div className="SummaryItemActions">
        <Loader active inline size="small" />
      </div>
    );
  } else if (file.done) {
    actions = (
      <div className="SummaryItemActions">
        <Icon size="large" name="check circle outline" />
      </div>
    );
  } else {
    actions = (
      <div className="SummaryItemActions" onClick={() => removeFile(file)}>
        <Icon size="small" name="trash alternate outline" />
      </div>
    );
  }

  if (!file.summaryItems) return null;
  if (file.loading) return <Loader />;

  return (
    <div className="SummaryItemContainer">
      <div className="SummaryItemContainerTopBar">
        <div className="SummaryItemTitle" onClick={() => toggleOpen(!isOpen)}>
          {file.name}
        </div>
        {actions}
      </div>
      <div className="SummaryItemProgressBar" style={progressBarStyle} />
      {!file.done && (
        <div className="SummaryItemList">
          {Object.keys(file.summaryItems).map(key => (
            <SummaryItemCard
              key={key}
              name={file.summaryItems[key].productName}
              quantity={file.summaryItems[key].itemQuantity.toString()}
            />
          ))}
        </div>
      )}
    </div>
  );
};

SummaryItemContainer.propTypes = {
  file: PropTypes.object.isRequired,
  removeFile: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      removeFile
    },
    dispatch
  );
export default connect(null, mapDispatchToProps)(SummaryItemContainer);
