import {
  SET_ACTIVE_DRAWER_ITEM,
  INVOICE_IN_ADD_FILE,
  INVOICE_IN_REMOVE_FILE,
  TRACKING_INVOICE_ADD_EXPLOSIVE,
  TRACKING_INVOICE_ADD_EXPLOSIVE_LIST,
  TRACKING_INVOICE_SET_LOADING,
  ADD_INVOICE
} from "../types";

const INITIAL_STATE = {
  activeDrawerItem: 0,
  invoices: {
    "2008": {
      invoice_id: "2008",
      isIn: true,
      isTracking: true,
      files: {},
      uids: []
    }
  }
};

const trackingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ACTIVE_DRAWER_ITEM:
      return {
        ...state,
        activeDrawerItem: action.index
      };
    case TRACKING_INVOICE_SET_LOADING:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          [action.invoice.invoice_id]: {
            ...action.invoice,
            loading: action.status
          }
        }
      };
    case TRACKING_INVOICE_ADD_EXPLOSIVE:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          [action.explosive.invoice]: {
            ...state.invoices[action.explosive.invoice],
            uids: [
              ...state.invoices[action.explosive.invoice].uids,
              action.explosive
            ]
          }
        }
      };
    case TRACKING_INVOICE_ADD_EXPLOSIVE_LIST:
      const currentUids = state.invoices[action.invoice_id].uids
        ? state.invoices[action.invoice_id].uids
        : [];
      return {
        ...state,
        invoices: {
          ...state.invoices,
          [action.invoice_id]: {
            ...state.invoices[action.invoice_id],
            uids: [...currentUids, ...action.explosiveList]
          }
        }
      };
    case ADD_INVOICE:
      const invoiceToAdd = action.payload;
      return {
        ...state,
        invoices: {
          ...state.invoices,
          [invoiceToAdd.invoice_id]: invoiceToAdd
        }
      };
    // case REMOVE_INVOICE:
    //   return {
    //     ...state,
    //     invoices: this.state.invoice.filter(stateInvoice => {
    //       return stateInvoice.invoice_id !== action.payload.invoice_id;
    //     })
    //   };
    case INVOICE_IN_ADD_FILE:
      let myFile = action.file;
      let myInvoiceID = myFile.invoice_id;
      return {
        ...state,
        invoices: {
          ...state.invoices,
          [myInvoiceID]: {
            ...state.invoices[myInvoiceID],
            files: {
              ...state.invoices[myInvoiceID].files,
              [myFile.name]: myFile
            }
          }
        }
      };
    case INVOICE_IN_REMOVE_FILE:
      let invoiceToRemoveFile = state.invoices[action.file.invoice_id];
      delete invoiceToRemoveFile.files[action.file.name];

      console.log("After removing file and uids", invoiceToRemoveFile);
      return {
        ...state,
        invoices: {
          ...state.invoices,
          [action.file.invoice_id]: {
            ...invoiceToRemoveFile,
            uids: invoiceToRemoveFile.uids.filter(
              uid => uid.file !== action.file.name
            )
          }
        }
      };
    default:
      return state;
  }
};

export default trackingReducer;
