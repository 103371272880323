import React, { Component } from "react";
import { AppBar, DrawerContainer, Main } from "../AppBar";
import { Input, Icon, Button, Loader } from "semantic-ui-react";
import axios from "axios/index";
import * as API from "../../dowhileAPI";

import "./catalog.css";

const styles = {
  formInput: {
    margin: "10px",
    width: "100%"
  },
  action: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  primaryButton: {
    flex: 0.7,
    width: "100%",
    height: "45px",
    alignSelf: "center",
    margin: "8px",
    borderRadius: "20px",
    color: "black",
    boxShadow: "0 3px 3px 1px #e1e1e1",
    backgroundImage: "linear-gradient(to right, #43e97b 0%, #38f9d7 100%)"
  },
  centeredContent: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
};

export default class CatalogCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // SERVER DATA
      uploadingProduct: false,          // Is it uploading to server?
      uploadedProduct: false,           // Has it been uploaded successfully?

      uploadingToInventory: false,
      uploadedToInventory: false,
      error: null,                      // error object

      // PAYMENT FORM DATA
      id: "",
      title: "",
      description: "",
      category: "",
      sub_category: "",
      quantity: 0,
      unit: "kg",

      // INPUT ERRORS
      idErr: false,
      titleErr: false,
      descriptionErr: false,
      categoryErr: false,
      sub_categoryErr: false,
      quantityErr: false,
      unitErr: false
    };
  }

  // INVOICE FORM DATA HANDLERS
  onIdChange = event => {
    this.setState({ id: event.target.value });
  };
  onTitleChange = event => {
    this.setState({ title: event.target.value });
  };
  onDescriptionChange = event => {
    this.setState({ description: event.target.value });
  };
  onCategoryChange = event => {
    this.setState({ category: event.target.value });
  };
  onSubCategoryChange = event => {
    this.setState({ sub_category: event.target.value });
  };
  onQuantityChange = event => {
    this.setState({ quantity: event.target.value });
  };
  onUnitChange = unit => {
    this.setState({ unit });
  };

  // Upload Customer
  uploadProduct = product => {
    console.log("UPLOADING PRODUCT...");

    this.setState({
      uploadingProduct: true
    });

    const { user } = this.props;
    const data = { product };

    const config = {
      headers: {
        Authorization: user.idToken,
        tenant_id: user.tenantID
      }
    };

    return axios.post(API.CREATE_CATALOG, data, config);
  };
  handleUploadProductResponse = res => {
    console.log(res);

    // Hide loader
    this.setState({
      uploadingProduct: false
    });

    if (res.data.status === 201) {
      this.setState({
        uploaded: true
      });

      const { id, title, description, category, sub_category } = this.state;
      const product = { id, title, description, category, sub_category };

      this.props.addProduct(product);
      
    } else {
      this.setState({
        uploadedProduct: false,
        error: true
      });
    }
  };

  uploadToInventory = item => {
    console.log("UPLOADING TO INVENTORY...");

    this.setState({
      uploadingToInventory: true
    });

    const { user } = this.props;
    const data = { item };

    const config = {
      headers: {
        Authorization: user.idToken,
        tenant_id: user.tenantID
      }
    };

    return axios.post(API.UPDATE_INVENTORY, data, config);
  };
  handleUploadToInventoryResponse = res => {
    console.log(res);

    // Hide loader
    this.setState({
      uploadingToInventory: false
    });

    if (res.data.status === 201) {
      this.setState({
        uploaded: true
      });

      const { id, title, description, category, sub_category, quantity, unit } = this.state;
      const item = { id, title, description, category, sub_category, quantity, unit };

    //   this.props.addInventory(item);

    } else {
      this.setState({
        uploadedToInventory: false,
        error: true
      });
    }
  };

  _validateForm = async () => {
    const {
      id,
      title,
      description,
      category,
      sub_category,
      quantity,
      unit
    } = this.state;

    let idErr = id === "";
    let titleErr = title === "";
    let descriptionErr = description === "";
    let categoryErr = category === "";
    let sub_categoryErr = sub_category === "";
    let quantityErr = quantity === "";
    let unitErr = unit === "";

    // If one of the fields is empty, update error state for all fields.
    if (
      idErr ||
      titleErr ||
      descriptionErr ||
      categoryErr ||
      sub_categoryErr ||
      unitErr ||
      quantityErr
    ) {
      this.setState({
        titleErr,
        descriptionErr,
        categoryErr,
        sub_categoryErr,
        idErr,
        quantityErr,
        unitErr
      });
      return;
    }

    const product = { id, title, description, category, sub_category, unit };
    const item = { ...product, quantity };

    await this.uploadProduct(product)
      .then(this.handleUploadProductResponse)
      .catch(err => {
        this.setState({ error: err });
        console.log(err);
      });

    await this.uploadToInventory(item)
      .then(this.handleUploadToInventoryResponse)
      .catch(err => {
          this.setState({ error: err });
          console.log(err);
      })

    console.log("DONE");
  };

  render() {
    const {
      uploadingProduct,
      uploadingToInventory,
      id,
      title,
      description,
      category,
      sub_category,
      quantity,
      unit,
      idErr,
      titleErr,
      descriptionErr,
      categoryErr,
      sub_categoryErr,
      quantityErr,
      unitErr
    } = this.state;

    if (uploadingProduct || uploadingToInventory)
      return (
        <div style={styles.centeredContent}>
          <Loader active inline />
          Uploading { uploadingProduct ? " to product catalog." : " inventory item."}
        </div>
      );

    return (
      <div className="Content">
        <DrawerContainer />
        <Main>
          <div className="catalog-create-root">
            <div className="catalog-create-container">
              <div className="catalog-create-header">
                <div className="catalog-create-header-title">
                  + Product i ri
                </div>
                <Icon name="user circle" size="huge" />
              </div>
              <div className="catalog-create-form">
                <Input
                  style={styles.formInput}
                  placeholder="id"
                  type="text"
                  icon="tag"
                  iconPosition="left"
                  error={idErr}
                  value={id}
                  onChange={this.onIdChange}
                />
                <Input
                  style={styles.formInput}
                  placeholder="title"
                  type="text"
                  icon="tag"
                  iconPosition="left"
                  error={titleErr}
                  value={title}
                  onChange={this.onTitleChange}
                />
                <Input
                  style={styles.formInput}
                  placeholder="description"
                  type="text"
                  icon="tag"
                  iconPosition="left"
                  error={descriptionErr}
                  value={description}
                  onChange={this.onDescriptionChange}
                />
                <Input
                  style={styles.formInput}
                  placeholder="category"
                  type="text"
                  icon="tag"
                  iconPosition="left"
                  error={categoryErr}
                  value={category}
                  onChange={this.onCategoryChange}
                />
                <Input
                  style={styles.formInput}
                  placeholder="Sub Category"
                  type="text"
                  icon="tag"
                  iconPosition="left"
                  error={sub_categoryErr}
                  value={sub_category}
                  onChange={this.onSubCategoryChange}
                />
                <Input
                  style={styles.formInput}
                  placeholder="Quantity"
                  type="number"
                  icon="tag"
                  iconPosition="left"
                  error={quantityErr}
                  value={quantity}
                  onChange={this.onQuantityChange}
                />
                <Button.Group style={styles.formInput}>
                  <Button
                    active={unit === "kg"}
                    onClick={() => this.onUnitChange("kg")}
                  >
                    KG
                  </Button>
                  <Button
                    active={unit === "m"}
                    onClick={() => this.onUnitChange("m")}
                  >
                    M
                  </Button>
                  <Button
                    active={unit === "pcs"}
                    onClick={() => this.onUnitChange("pcs")}
                  >
                    PCS
                  </Button>
                </Button.Group>
              </div>
              <div style={styles.action}>
                <Button
                  disabled={false}
                  style={styles.primaryButton}
                  onClick={this._validateForm}
                >
                  SAVE
                </Button>
              </div>
            </div>
          </div>
        </Main>
      </div>
    );
  }
}

CatalogCreate.propTypes = {};