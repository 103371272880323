/**
 * Copyright Redi Kurti, 2020. All rights reserved.
 */

import React from "react";
import Omnicon from "../../../assets/icons/Omnicon";

const InventoryCard = ({ onPress, item }) => {
  const { product, uid, itemQuantity, psn, invoice, file } = item;
  const { name, code } = product;

  return (
    <div style={styles.root} onClick={() => onPress(item)}>
      <div style={styles.content}>
        <div style={styles.header}>
          <div style={styles.title}>
            <div style={styles.titleText}>{name ? name : "Name unknown"}</div>
          </div>
        </div>
      </div>
      <div style={styles.amount}>
        <div style={styles.unitText}>qty</div>
        <div style={styles.amountText}>{itemQuantity}</div>
      </div>
    </div>
  );
};

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    maxWidth: 280,
    width: 240,
    height: 100,
    backgroundColor: "#f1f1f1",
    margin: 10,
    paddingTop: 2,
    borderRadius: 5,
    cursor: "pointer"
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flex: 1
  },
  header: {
    display: "flex",
    flex: 1
  },
  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 3,
    paddingRight: 3,
    // backgroundColor: 'blue',
    height: "100%"
  },
  titleText: {
    fontFamily: "Lato",
    fontWeight: "500",
    fontSize: 16,
    color: "black"
  },
  amount: {
    width: "100%",
    backgroundColor: "purple",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#2336bd",
    borderRadius: 5,
    paddingTop: 4,
    paddingBottom: 4,
    boxShadow: `0 5px 7px -1px rgba(35, 54, 189, 0.3),
    0 2px 3px -1px rgba(35, 54, 189, 0.1)`
  },
  amountText: {
    color: "white",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    fontSize: 24,
    fontFamily: "Lato",
    fontWeight: "900",
    marginRight: 4,
    marginLeft: 4
  },
  unitText: {
    fontFamily: "Lato",
    fontWeight: "700",
    textDecoration: "italic",
    color: "white",
    marginRight: 4,
    marginLeft: 4
  }
};

export default InventoryCard;
