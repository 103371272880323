/**
 * Copyright Redi Kurti, 2019. All rights reserved.
 */

import React from "react";
import "./in.css";
import SummaryItemCard from "./SummaryItemCard";

const ProductList = props => {
  const { products } = props;
  console.log("products IN ProductList", products);

  if (!products || products.length === 0)
    return (
      <div className="tracking-in-product-list-list-root">
        <div>No products are listed for this invoice.</div>
      </div>
    );

  return (
    <div className="tracking-in-product-list-list-root">
      {products.map(product => {
        return (
          <SummaryItemCard
            name={product.productName}
            quantity={product.itemQuantity}
          />
        );
      })}
    </div>
  );
};

export default ProductList;
