import { combineReducers } from "redux";

import trackingReducer from "./TrackingReducer";
import appbarReducer from "./AppbarReducer";
import inventoryReducer from "./InventoryReducer";
import customerReducer from "./CustomerReducer";
import invoiceReducer from "./InvoiceReducer";

export default combineReducers({
  tracking: trackingReducer,
  appbar: appbarReducer,
  inventory: inventoryReducer,
  customer: customerReducer,
  invoice: invoiceReducer
});
