// src/containers/App.js

/**
 * Copyright Redi Kurti, 2019. All rights reserved.
 */

// React imports
import React, { Component } from "react";

// Network imports
import { BrowserRouter, Route, Switch } from "react-router-dom";

// Redux imports
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./configureStore";

// CSS imports
import "./App.css";

// Asset imports

// Other component imports
// Tracking
import Tracking from "./containers/Tracking/Tracking";
import TrackingInvoiceIn from "./containers/Tracking/invoices/in/TrackingInvoiceIn";
import TrackingInvoiceOut from "./containers/Tracking/invoices/out/TrackingInvoiceOut";

import Invoice from "./containers/Invoice/Invoice";
import LoginContainer from "./containers/Login/LoginContainer";
import Dashboard from "./containers/Dashboard";
import Home from "./containers/Home";
import Customer from "./containers/Customers/CustomerContainer";
import CustomerView from "./containers/Customers/CustomerView";
import NewCustomer from "./containers/Customers/NewCustomer";

import RegisterContainer from "./containers/Register/RegisterContainer";
import TermsAndConditions from "./containers/TermsAndConditions/index";
import InvoiceView from "./containers/Invoice/InvoiceView";
import Inventory from "./containers/Inventory/Inventory";

import Files from "./containers/Files/Files";
import InventoryView from "./containers/Inventory/view/InventoryView";
import InventoryViewEdit from "./containers/Inventory/view/InventoryViewEdit";

const App = props => {
  return (
    <Provider store={store}>
      <PersistGate
        loading={<div> Loading persist gate</div>}
        persistor={persistor}
      >
        <BrowserRouter>
          <div className="App">
            <Switch>
              <Route exact path="/" render={() => <Home />} />
              <Route
                exact
                path="/invoice/:id"
                render={props => <InvoiceView {...props} />}
              />
              <Route
                path="/invoice/"
                render={props => <Invoice {...props} />}
              />
              <Route
                exact
                path="/customer/list"
                render={props => <Customer {...props} />}
              />
              <Route
                exact
                path="/customer/new"
                render={props => <NewCustomer {...props} />}
              />
              <Route
                path="/customer/:id/"
                render={props => <CustomerView {...props} />}
              />
              <Route
                
                path="/tracking/invoices/out/:invoice_id"
                render={props => <TrackingInvoiceOut {...props} />}
              />
              <Route
                
                path="/tracking/invoices/in/:invoice_id"
                render={props => <TrackingInvoiceIn {...props} />}
              />
              <Route
                path="/tracking"
                render={props => <Tracking {...props} />}
              />
              <Route
                exact
                path="/dashboard"
                render={props => <Dashboard {...props} />}
              />
              <Route
                exact
                path="/inventory/view"
                render={props => <InventoryView {...props} />}
              />
              <Route
                exact
                path="/inventory/view/edit"
                render={props => <InventoryViewEdit {...props} />}
              />
              <Route
                path="/inventory"
                render={props => <Inventory {...props} />}
              />
              <Route
                exact
                path="/terms&conditions"
                render={() => <TermsAndConditions />}
              />
              <Route path="/files" render={props => <Files />} />;
              <Route
                path="/login"
                render={props => <LoginContainer {...props} />}
              />
              ;
              <Route
                path="/register"
                render={props => <RegisterContainer {...props} />}
              />
              ;
              <Route render={() => <div> Not Found </div>} />
            </Switch>
          </div>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
