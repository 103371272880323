/**
 * Copyright Redi Kurti, 2020. All rights reserved.
 */

import React from "react";
import "./invoice-section-header.css";

const InvoiceSectionHeader = props => {
  const { title, renderGoBack, renderAction, actions } = props;
  return (
    <div className="tracking-in-invoices-invoice-header">
      <div className="tracking-in-invoices-invoice-header-goback">
        {renderGoBack ? renderGoBack() : null}
      </div>
      <div className="tracking-in-invoices-invoice-header-content">
        <div className="tracking-in-invoices-invoice-header-title">
          {title ? title : ""}
        </div>
        <div className="tracking-in-invoices-invoice-header-action">
          {renderAction ? renderAction() : null}
          {actions ? actions.map(action => action()) : null}
        </div>
      </div>
    </div>
  );
};

export default InvoiceSectionHeader;
