/**
 * Copyright Redi Kurti, 2020. All rights reserved.
 */

import React from "react";

// CSS imports
import "./invoice-form-header.css";

const InvoiceFormHeader = ({ title }) => {
  return (
    <div className="invoice-form-container-header">
      <div className="invoice-form-container-header-empty" />
      <div className="invoice-form-container-header-title">{title}</div>
    </div>
  );
};
export default InvoiceFormHeader;
