import React from "react";

import { Transition } from "react-transition-group";

// Redux imports
// import { setActiveDrawerItem } from "../../containers/redux/actions/TrackingActions";
import { toggleDrawer } from "../../redux/actions/AppbarActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "./main.css";

const defaultStyle = {
  transition: `left 300ms ease-out, opacity 300ms ease-in`,
  left: 0
};

const transitionStyles = {
  entering: { left: 0 },
  entered: { left: "300px", opacity: 0.25 },
  exiting: { left: "300px", opacity: 0.25 },
  exited: { left: 0 }
};

const Main = props => {
  return (
    <Transition in={props.drawerOpen} timeout={300}>
      {state => (
        <div
          className="Main"
          style={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}
          onClick={() => {
            if (props.drawerOpen) props.toggleDrawer();
          }}
        >
          <div
            style={{
              pointerEvents: props.drawerOpen ? "none" : ""
            }}
          >
            {props.children}
          </div>
        </div>
      )}
    </Transition>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      toggleDrawer
    },
    dispatch
  );

const mapStateToProps = state => {
  const { drawerOpen } = state.appbar;
  return { drawerOpen };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
