import React from "react";
import "./input.css";

const Input = props => {
  const type = props.type ? props.type : "text";
  const placeholder = props.placeholder ? props.placeholder : "Search";

  return (
    <div className="input-container">
      <input
        type={type}
        placeholder={placeholder}
        onChange={props.onChange}
        // Other custom attributes
      />
    </div>
  );
};

export default Input;
