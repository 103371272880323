import React from "react";
import './icon.css';

const styles = {
    root: {
        width: '24px',
        height: '24px',
        position: 'relative',
        display: 'flex',
    },
};

export default class DownloadCloud extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {

        const color = this.props.color ? this.props.color : 'black';

        return (
            <div style={styles.root}>
                <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    width="24" 
                    height="24" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    stroke={color}
                    strokeWidth="2" 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    className="feather feather-download-cloud"
                >
                    <polyline points="8 17 12 21 16 17"></polyline>
                    <line x1="12" y1="12" x2="12" y2="21"></line>
                    <path d="M20.88 18.09A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.29"></path>
                </svg>
            </div>
        );
    }
}
