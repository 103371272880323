import React from "react";

export default class Icon extends React.Component {
  render() {
    const { name, color, size, focused, fill, strokeWidth } = this.props;
    let Icon = (
      <IconBookOpen size={24} color={color} strokeWidth={focused ? 3 : 2} />
    );
    if (name === "book-open")
      Icon = (
        <IconBookOpen size={24} color={color} strokeWidth={focused ? 3 : 2} />
      );

    if (name === "user")
      Icon = <IconUser size={24} color={color} strokeWidth={focused ? 3 : 2} />;
    if (name === "search")
      Icon = (
        <IconSearch size={24} color={color} strokeWidth={focused ? 3 : 2} />
      );
    if (name === "archive")
      Icon = (
        <IconArchive size={24} color={color} strokeWidth={focused ? 3 : 2} />
      );
    if (name === "flashlight")
      Icon = (
        <IconFlashLight
          size={24}
          fill={focused ? color : "none"}
          color={color}
          strokeWidth={strokeWidth ? strokeWidth : 2}
        />
      );
    if (name === "invoice")
      Icon = (
        <IconInvoice size={24} color={color} strokeWidth={focused ? 3 : 2} />
      );
    if (name === "trash")
      Icon = (
        <IconTrash
          size={24}
          fill={focused ? color : "none"}
          color={color}
          strokeWidth={strokeWidth ? strokeWidth : 2}
        />
      );
    if (name === "camera")
      Icon = (
        <IconCamera
          size={24}
          color={color ? color : "black"}
          fill={fill ? fill : "none"}
          strokeWidth={strokeWidth ? strokeWidth : 2}
        />
      );
    if (name === "plus-square")
      Icon = (
        <IconPlusSquare
          size={size ? size : 24}
          color={color ? color : "black"}
          strokeWidth={focused ? 3 : 2}
        />
      );
    if (name === "plus-circle")
      Icon = (
        <IconPluscircle
          size={size ? size : 24}
          color={color ? color : "black"}
          strokeWidth={focused ? 3 : 2}
        />
      );
    if (name === "plus")
      Icon = (
        <IconPlus
          size={size ? size : 24}
          color={color ? color : "black"}
          strokeWidth={focused ? 3 : 2}
        />
      );
    if (name === "edit")
      Icon = (
        <IconEdit
          size={24}
          fill={focused ? color : "none"}
          color={color}
          strokeWidth={focused ? 3 : 2}
        />
      );

    if (name === "check")
      Icon = (
        <IconCheck
          size={size ? size : 24}
          fill={focused ? color : "none"}
          color={color}
          strokeWidth={focused ? 3 : 2}
        />
      );
    if (name === "file")
      Icon = (
        <IconFile
          size={size ? size : 24}
          fill={focused ? color : "none"}
          color={color}
          strokeWidth={focused ? 3 : 2}
        />
      );
    if (name === "file-minus")
      Icon = (
        <IconFileMinus
          size={size ? size : 24}
          fill={focused ? color : "none"}
          color={color}
          strokeWidth={focused ? 3 : 2}
        />
      );
    if (name === "file-plus")
      Icon = (
        <IconFilePlus
          size={size ? size : 24}
          fill={focused ? color : "none"}
          color={color}
          strokeWidth={focused ? 3 : 2}
        />
      );
    if (name === "calendar")
      Icon = (
        <IconCalendar
          size={24}
          fill={focused ? color : "none"}
          color={color}
          strokeWidth={focused ? 3 : 2}
        />
      );
    if (name === "alert-circle")
      Icon = (
        <IconAlertcircle
          size={24}
          fill={focused ? color : "none"}
          color={color}
          strokeWidth={focused ? 3 : 2}
        />
      );
    if (name === "alert-triangle")
      Icon = (
        <IconAlertTriangle
          size={24}
          fill={focused ? color : "none"}
          color={color}
          strokeWidth={focused ? 3 : 2}
        />
      );
    if (name === "chevron-down")
      Icon = (
        <IconChevronDown
          size={24}
          fill={focused ? color : "none"}
          color={color}
          strokeWidth={focused ? 3 : 2}
        />
      );
      if (name === "chevron-up")
      Icon = (
        <IconChevronUp
          size={24}
          fill={focused ? color : "none"}
          color={color}
          strokeWidth={focused ? 3 : 2}
        />
      );
    if (name === "trending-down")
      Icon = (
        <TrendingDown
          size={24}
          fill={focused ? color : "none"}
          color={color}
          strokeWidth={focused ? 3 : 2}
        />
      );
    if (name === "arrow-left")
      Icon = (
        <IconArrowLeft
          size={24}
          fill={focused ? color : "none"}
          color={color}
          strokeWidth={focused ? 3 : 2}
        />
      );
    if (name === "arrow-right")
      Icon = (
        <IconArrowRight
          size={24}
          fill={focused ? color : "none"}
          color={color}
          strokeWidth={focused ? 3 : 2}
        />
      );
    if (name === "settings")
      Icon = (
        <IconSettings
          size={24}
          fill={focused ? color : "none"}
          color={focused ? "white" : color}
          strokeWidth={focused ? 3 : 2}
        />
      );
    if (name === "package")
      Icon = (
        <IconPackage
          size={24}
          fill={focused ? color : "none"}
          color={focused ? "white" : color}
          strokeWidth={focused ? 3 : 2}
        />
      );

    return Icon;
  }
}

const IconUser = props => (
  <svg
    width={24}
    height={24}
    viewBox={`0 0 ${props.size} ${props.size}`}
    fill="none"
    stroke={props.color}
    strokeWidth={props.strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="prefix__feather prefix__feather-user"
    {...props}
  >
    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
    <circle cx={12} cy={7} r={4} />
  </svg>
);

const IconTrash = props => (
  <svg
    width={props.size}
    height={props.size}
    viewBox={`0 0 ${props.size} ${props.size}`}
    fill="none"
    stroke={props.color}
    strokeWidth={props.strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <polyline points="3 6 5 6 21 6" />
    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
    <line x1="10" y1="11" x2="10" y2="17" />
    <line x1="14" y1="11" x2="14" y2="17" />
  </svg>
);

const IconInvoice = props => (
  <svg
    width={props.size}
    height={props.size}
    viewBox={`0 0 ${props.size} ${props.size}`}
    fill="none"
    stroke={props.color}
    strokeWidth={props.strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
    {/* <polyline points="14 2 14 8 20 8" /> */}
    <line x1="16" y1="13" x2="8" y2="13" />
    <line x1="16" y1="17" x2="8" y2="17" />
    <polyline points="10 9 9 9 8 9" />
  </svg>
);

const TrendingDown = props => (
  <svg
    height={props.size}
    width={props.size}
    key={"test2"}
    viewBox="0 0 24 24"
    preserveAspectRatio="1"
    fill="none"
    stroke={props.color}
    strokeWidth={props.strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <polyline points="23 18 13.5 8.5 8.5 13.5 1 6" />
    <polyline points="17 18 23 18 23 12" />
  </svg>
);

const IconChevronDown = props => (
  <svg
    height={props.size}
    width={props.size}
    key={"test2"}
    viewBox="0 0 24 24"
    preserveAspectRatio="1"
    fill="none"
    stroke={props.color}
    strokeWidth={props.strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <polyline points="6 9 12 15 18 9"></polyline>
  </svg>
);

const IconChevronUp = props => (
  <svg
    height={props.size}
    width={props.size}
    key={"test2"}
    viewBox="0 0 24 24"
    preserveAspectRatio="1"
    fill="none"
    stroke={props.color}
    strokeWidth={props.strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <polyline points="18 15 12 9 6 15"></polyline>
  </svg>
);

const IconPackage = props => (
  <svg
    height={props.size}
    width={props.size}
    key={"test2"}
    viewBox="0 0 24 24"
    preserveAspectRatio="1"
    fill="none"
    stroke={props.color}
    strokeWidth={props.strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line>
    <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
    <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
    <line x1="12" y1="22.08" x2="12" y2="12"></line>
  </svg>
);

const IconFile = props => (
  <svg
    height={props.size}
    width={props.size}
    key={"test2"}
    viewBox="0 0 24 24"
    preserveAspectRatio="1"
    fill="none"
    stroke={props.color}
    strokeWidth={props.strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
    <polyline points="14 2 14 8 20 8"></polyline>
  </svg>
);

const IconFileMinus = props => (
  <svg
    height={props.size}
    width={props.size}
    key={"test2"}
    viewBox="0 0 24 24"
    preserveAspectRatio="1"
    fill="none"
    stroke={props.color}
    strokeWidth={props.strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
    <polyline points="14 2 14 8 20 8"></polyline>
    <line x1="9" y1="15" x2="15" y2="15"></line>
  </svg>
);

const IconFilePlus = props => (
  <svg
    height={props.size}
    width={props.size}
    key={"test2"}
    viewBox="0 0 24 24"
    preserveAspectRatio="1"
    fill="none"
    stroke={props.color}
    strokeWidth={props.strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
    <polyline points="14 2 14 8 20 8"></polyline>
    <line x1="12" y1="18" x2="12" y2="12"></line>
    <line x1="9" y1="15" x2="15" y2="15"></line>
  </svg>
);

const IconArrowLeft = props => (
  <svg
    height={props.size}
    width={props.size}
    key={"test2"}
    viewBox="0 0 24 24"
    preserveAspectRatio="1"
    fill="none"
    stroke={props.color}
    strokeWidth={props.strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <line x1="19" y1="12" x2="5" y2="12" />
    <polyline points="12 19 5 12 12 5" />
  </svg>
);

const IconSettings = props => (
  <svg
    height={props.size}
    width={props.size}
    key={"test2"}
    viewBox="0 0 24 24"
    preserveAspectRatio="1"
    fill="none"
    stroke={props.color}
    strokeWidth={props.strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <circle cx="12" cy="12" r="3" />
    <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z" />
  </svg>
);

const IconArrowRight = props => (
  <svg
    height={props.size}
    width={props.size}
    key={"test2"}
    viewBox="0 0 24 24"
    preserveAspectRatio="1"
    fill="none"
    stroke={props.color}
    strokeWidth={props.strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <line x1="5" y1="12" x2="19" y2="12" />
    <polyline points="12 5 19 12 12 19" />
  </svg>
);

const IconAlertTriangle = props => (
  <svg
    height={props.size}
    width={props.size}
    key={"test2"}
    viewBox="0 0 24 24"
    preserveAspectRatio="1"
    fill="none"
    stroke={props.color}
    strokeWidth={props.strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z" />
    <line x1="12" y1="9" x2="12" y2="13" />
    <line x1="12" y1="17" x2="12" y2="17" />
  </svg>
);

const IconAlertcircle = props => (
  <svg
    height={props.size}
    width={props.size}
    key={"test2"}
    viewBox="0 0 24 24"
    preserveAspectRatio="1"
    fill="none"
    stroke={props.color}
    strokeWidth={props.strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <circle cx="12" cy="12" r="10" />
    <line x1="12" y1="8" x2="12" y2="12" />
    <line x1="12" y1="16" x2="12" y2="16" />
  </svg>
);

const IconCalendar = props => (
  <svg
    height={props.size}
    width={props.size}
    key={"test2"}
    viewBox="0 0 24 24"
    preserveAspectRatio="1"
    fill="none"
    stroke={props.color}
    strokeWidth={props.strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />
    <line x1="16" y1="2" x2="16" y2="6" />
    <line x1="8" y1="2" x2="8" y2="6" />
    <line x1="3" y1="10" x2="21" y2="10" />
  </svg>
);

const IconBookOpen = props => (
  <svg
    height={props.size}
    width={props.size}
    key={"test2"}
    viewBox="0 0 24 24"
    preserveAspectRatio="1"
    fill="none"
    stroke={props.color}
    strokeWidth={props.strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="prefix__feather prefix__feather-book-open"
    {...props}
  >
    <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2zM22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z" />
  </svg>
);

const IconFlashLight = props => (
  <svg
    height={props.size}
    width={props.size}
    key={"test2"}
    viewBox="0 0 24 24"
    preserveAspectRatio="1"
    fill={props.fill}
    stroke={props.color}
    strokeWidth={props.strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2" />
  </svg>
);

const IconCheck = props => (
  <svg
    height={props.size}
    width={props.size}
    key={"test2"}
    viewBox="0 0 24 24"
    preserveAspectRatio="1"
    fill="none"
    stroke={props.color}
    strokeWidth={props.strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <polyline points="20 6 9 17 4 12" />
  </svg>
);

const IconPlusSquare = props => (
  <svg
    width={props.size}
    height={props.size}
    viewBox={`0 0 ${props.size} ${props.size}`}
    fill="none"
    stroke={props.color}
    strokeWidth={props.strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
    <line x1="12" y1="8" x2="12" y2="16" />
    <line x1="8" y1="12" x2="16" y2="12" />
  </svg>
);

const IconPluscircle = props => (
  <svg
    width={props.size}
    height={props.size}
    viewBox={`0 0 ${props.size} ${props.size}`}
    fill="none"
    stroke={props.color}
    strokeWidth={props.strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <circle cx="12" cy="12" r="10" />
    <line x1="12" y1="8" x2="12" y2="16" />
    <line x1="8" y1="12" x2="16" y2="12" />
  </svg>
);

const IconPlus = props => (
  <svg
    height={props.size}
    width={props.size}
    key={"test1"}
    viewBox="0 0 24 24"
    fill="none"
    stroke={props.color}
    strokeWidth={props.strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    // aspectRatio={1}
    {...props}
  >
    <line x1="12" y1="5" x2="12" y2="19" />
    <line x1="5" y1="12" x2="19" y2="12" />
  </svg>
);

const IconEdit = props => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 24 24"
    fill={props.fill}
    stroke={props.color}
    strokeWidth={props.strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M12 20h9" />
    <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z" />
  </svg>
);

const IconSearch = props => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={props.color}
    strokeWidth={props.strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="prefix__feather prefix__feather-search"
    {...props}
  >
    <circle cx={11} cy={11} r={8} />
    <path d="M21 21l-4.35-4.35" />
  </svg>
);

const IconArchive = props => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={props.color}
    strokeWidth={props.strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="prefix__feather prefix__feather-archive"
    {...this.props}
  >
    <path d="M21 8v13H3V8M1 3h22v5H1zM10 12h4" />
  </svg>
);

const IconCamera = props => (
  <svg
    width={props.size}
    height={props.size}
    fill="none"
    stroke={props.color}
    strokeWidth={props.strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="prefix__feather prefix__feather-camera"
    {...props}
  >
    <path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z" />
    <circle cx={12} cy={13} r={4} />
  </svg>
);
