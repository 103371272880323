import React, { Component } from "react";
import {Link} from "react-router-dom";
import "./Home.css";


const styles = {

    root: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        height: '100vh',
        width: '100vw',
        padding: 0,
        margin: 0,
        position: 'relative',
        background: "#0b345d",
        backgroundImage: "linear-gradient(155deg, #002f62 0%, #18496a 50%, #138b89 100%)",
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'row',
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '100%',
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '40px',
        height: '100%',
        width: '60%',
        flex: 0.6,
        boxSizing: 'border-box',
    },
    imageContainer: {

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        height: '100%',
        padding: '40px',
        width: '40%',
        flex: 0.4,
        boxSizing: 'border-box',
    },
    mainTitle: {
        fontFamily: 'Lato',
        fontWeight: '900',
        fontSize: '4em',
        margin: '40px',
        color: 'white',
    },
    subTitle: {
        fontFamily: 'Lato',
        fontWeight: 'bold',
        fontSize: '1.5em',
        color: 'white',
    },

};

export default class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeItem: 'home',
            mouseX: {
                start: 0,
                end: window.innerWidth,
                current: 0,
                range: window.innerWidth,
            },
            mouseY: {
                start: 0,
                end: window.innerHeight,
                current: 0,
                range: window.innerHeight,
            },
            mouseXout: {
                start: -500,
                end: 500,
                current: 0,
                range: 1000,
            },
            mouseYout: {
                start: -500,
                end: 500,
                current: 0,
                range: 1000,
            }
        }
    }


    handleItemClick = (e, { name }) => this.setState({ activeItem: name });

    _handleMouseMovement = event => {

        this.setState({
            mouseX: {
                ...this.state.mouseX,
                current: event.clientX,
                fraction: (this.state.mouseX.current - this.state.mouseX.start) / this.state.mouseX.range
            },
            mouseY: {
                ...this.state.mouseY,
                current: event.clientY,
                fraction: (this.state.mouseY.current - this.state.mouseY.start) / this.state.mouseY.range
            },
            mouseXout: {
                ...this.state.mouseXout,
                current: this.state.mouseXout.start + (this.state.mouseX.fraction * this.state.mouseXout.range),
            },
            mouseYout: {
                ...this.state.mouseYout,
                current: this.state.mouseYout.start + (this.state.mouseY.fraction * this.state.mouseYout.range),
            }
        });

        console.log('Output X',this.state.mouseXout.current);
        console.log('Output Y',this.state.mouseYout.current);
    };
    _handleResize = event => {

        this.setState({
            mouseX: {
                ...this.state.mouseX,
                end: window.innerWidth,
                range: this.state.mouseX.end - this.state.mouseX.start,
            },
            mouseY: {
                ...this.state.mouseY,
                end: window.innerHeight,
                range: this.state.mouseY.end - this.state.mouseY.start,
            }
        });
    };



    componentWillMount() {
        // window.addEventListener('mousemove', this._handleMouseMovement, false);
        // window.addEventListener('resize', this._handleResize, false);

    }
    componentWillUnmount() {
        // window.removeEventListener('mousemove', this._handleMouseMovement, false);
        // window.removeEventListener('resize', this._handleResize, false);
    }


    render() {

        return (

            <div style={styles.root}>

                    <nav>
                        <div className="NavContainer">
                            <div className="item-1 item">gemo</div>
                            <div className="item-2 item menu-item">Services</div>
                            <div className="item-3 item menu-item">Blockchain</div>
                            <div className="item-4 item menu-item">Pricing</div>
                            <div className="item-4 item menu-item">About us</div>

                            <div className="auth-container">
                                <div className="item-5 item">
                                    <Link to='/login'>
                                        Sign in
                                    </Link>
                                </div>
                                <Link to='/register'>
                                    <div className="item-6 item">
                                        Get started
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </nav>

                    <div className="MainContainer" style={styles.contentContainer}>

                        <div style={styles.titleContainer} >

                            <div style={styles.mainTitle}>
                                { "Inventory, in your pocket" }
                            </div>

                            <div style={styles.subTitle}>
                                Manage inventory operations on any screen.
                            </div>

                        </div>

                        <div style={styles.imageContainer} >



                        </div>

                    </div>

                <svg id="svg" xmlns="http://www.w3.org/2000/svg" viewBox="-300 0 950 270" >
                    <path d="M-314,267 C105,364 400,100 812,279" fill="none" stroke="white" strokeWidth="120" strokeLinecap="round"/>
                </svg>


            </div>
        );
    }
}