import React from "react";
import { Icon, Loader } from "semantic-ui-react";
import "./barcode.css";
import PropTypes from "prop-types";

const styles = {
  root: {
    borderRadius: "5px",
    margin: "2px 0",
    cursor: "pointer"
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 15px"
  },
  content: {
    padding: "5px 10px 5px 20px",
    marginTop: "2px",
    display: "flex",
    flexDirection: "column"
  }
};

export default class Barcode extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  _handleOnDelete = () => {
    if (!this.state.loading) this.props.handleDelete(this.props.barcode);
  };

  _handleChildDelete = deletedChild => {
    const barcode = this.props.barcode;

    if ("units" in barcode) {
      barcode.units = barcode.units.filter(child => {
        return deletedChild.uid !== child.uid;
      });
    }

    if (barcode.units.length === 0) {
      this._handleOnDelete();
      return;
    }

    barcode.itemQuantity = parseFloat(
      barcode.itemQuantity - deletedChild.itemQuantity
    );

    console.log(
      "PARENT: " + barcode.uid + " AFTER DELETEING CHILD: " + deletedChild.uid
    );
    console.log(barcode);

    this.props.handleUpdate(barcode, deletedChild.itemQuantity);
  };

  _handleUpdate = (updatedChild, removedQuantity) => {
    const barcode = this.props.barcode;
    barcode.itemQuantity = parseFloat(barcode.itemQuantity - removedQuantity);

    barcode.units.map(child => {
      if (updatedChild.uid === child.uid) return updatedChild;
      else return child;
    });

    this.props.handleUpdate(barcode, removedQuantity);
  };

  handleOnClick = () => {
    console.log("CLICKED " + this.props.barcode.uid);
    let {
      state: { isOpen },
      props: { barcode }
    } = this;

    if (!barcode.isItem) {
      isOpen = !isOpen;
      this.setState({
        isOpen
      });
    }
  };

  render() {
    const {
      handleOnClick,
      state: { isOpen },
      props: { user, barcode, isItem }
    } = this;

    let children = [];

    if (!isItem) {
      if ("units" in barcode) {
        children = barcode.units.map((child, index) => {
          child.status = "200";
          return (
            <Barcode
              key={child.uid}
              barcode={child}
              isItem={child.isItem}
              index={child.uid}
            />
          );
        });
      }
    }

    // Styling the barcode container
    let rootBackground = "rgba(78,79,80, 0.12)";
    let error = false;
    if (barcode.status === "404") {
      rootBackground = "rgba(191,10,48, 0.12)";
      error = true;
    }

    return (
      <div
        className="Barcode"
        style={{ ...styles.root, background: rootBackground }}
      >
        <div style={styles.header} onClick={handleOnClick}>
          {/*<Label circular size="small" color={indicatorColor} empty/>*/}

          {!error && !barcode.loading && !barcode.isItem && (
            <div>
              {isOpen && <Icon name="angle down" />}
              {!isOpen && <Icon name="angle right" />}
            </div>
          )}

          <div className="BarcodeUID"> {barcode.uid} </div>
          <div className="BarcodePSN"> {barcode.itemQuantity} </div>

          <div
            className="BarcodeActionsContainer"
            onClick={this._handleOnDelete}
          >
            {barcode.loading ? (
              <Loader size="small" active inline />
            ) : (
              <Icon size="large" name="trash alternate outline" />
            )}
          </div>
        </div>

        {isOpen && <div style={styles.content}>{children}</div>}
      </div>
    );
  }
}

Barcode.propTypes = {
  user: PropTypes.object,
  barcode: PropTypes.object.isRequired,
  handleDelete: PropTypes.func,
  handleUpdate: PropTypes.func
};
