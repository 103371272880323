import React, { Component } from 'react';
import ItemCard from './components/ItemCard';
import {Dimmer, Header, Icon, Loader} from "semantic-ui-react";
import {DrawerContainer, DrawerItem, Main} from "../../AppBar";
import {Consumer} from "../../context";
import Backdrop from "../../AppBar/backdrop/Backdrop";
import InventoryCard from '../../../components/Inventory/InventoryCard';

const styles = {
    messageContainer: {
        flex: 1,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    message: {
        display: 'flex',
        flexDirection: 'column',
        background: 'transparent',
    },
};

export default class InventoryList extends Component {


    constructor (props) {
        super(props);

        this.state = {

        };
    }


    render() {

        const { user, loadingError, errorMessage, loading, displayResults } = this.props;

        if (loadingError) {

            return (
                <div style={styles.messageContainer}>
                    <div style={styles.message}>
                        <Header icon>
                            <Icon name='x' />
                            Server Error. {errorMessage}
                        </Header>
                    </div>
                </div>
            )

        } else if (loading) {

            return (
                <Dimmer active blurring inverted>
                    <Loader />
                </Dimmer>
            )

        } else if (displayResults.length === 0) {

            return (
                <div className="Invoice-InvoiceList">
                    <div className="Invoice-List">
                        <h2> Nuk ka artikuj. </h2>
                    </div>
                </div>
            );
        } else {

            return (

                <Consumer>
                    { context => (
                        <div className="Invoice-InvoiceList">
                            <div className="List">
                                {
                                    displayResults.map( (item, index) => {
                                        return (
                                            <InventoryCard
                                                key={index}
                                                item={item}
                                                onClick={context.actions.handleItemClick}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )}
                </Consumer>
            )
        }
    }
}
