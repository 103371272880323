import React from "react";
import _ from "lodash";
import Omnicon from "../../assets/icons/Omnicon";

import "./input-with-action.css";

const InputWithAction = props => {
  const { onKeyDown, onChange, value, type, placeholder, onClick } = props;
  return (
    <div className="input-with-action-root">
      <div className="input-with-action-container">
        <input
          onKeyDown={onKeyDown}
          onChange={onChange}
          value={value}
          type={type}
          className="input-with-action"
          placeholder={placeholder}
        />
      </div>
      <div className="input-with-action-action" onClick={onClick}>
        <div className="input-with-action-icon">
          <Omnicon name="search" color="#2336BC" />
        </div>
      </div>
    </div>
  );
};

export default InputWithAction;
