import React from "react";
import Omnicon from "../../../assets/icons/Omnicon";

import "./inventory-detail-card.css";

const InventoryDetailCard = ({ onPress, item, title, qty }) => {
  return (
    <div className="inventory-detail-card-root" onClick={() => onPress(item)}>
      <div className="inventory-detail-card-content">
        <div className="inventory-detail-card-header">
          <div className="inventory-detail-card-title">
            <div className="inventory-detail-card-titleText">
              {title ? title : "?"}
            </div>
          </div>
        </div>
      </div>
      <div className="inventory-detail-card-rightArrow">
        <div className="inventory-detail-card-amount">
          <div className="inventory-detail-card-unitText">qty</div>
          <div className="inventory-detail-card-amountText">
            {qty ? qty : 0}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryDetailCard;
