export const extractExplosivesThatAreItems = explosives => {
  if (!explosives || !explosives.length || explosives.length === 0) return [];
  let itemsOnThisLevel = [];
  for (let explosive of explosives) {
    if (explosive.isItem) {
      return explosives;
    } else {
      const children = extractExplosivesThatAreItems(explosive.units);
      itemsOnThisLevel = [...itemsOnThisLevel, ...children];
    }
  }
  return itemsOnThisLevel;
};

/**
 * Recursive function that finds items inside the Explosive objects.
 * @param {Array} explosives the array of explosives
 * @return {Array} an array of items grouped by product code.
 */
export const groupExplosivesByProductCode = explosives => {
  console.log("Gouping explosives by product code NR: ", explosives.length);
  const mappedItems = explosives.reduce((mapOfProducts, explosive) => {
    let product = mapOfProducts.get(explosive.product.code);
    if (product) {
      product = {
        ...product,
        itemsToRemove: [
          ...product.itemsToRemove,
          makeExplosiveLighter(explosive)
        ]
      };
    } else {
      product = {
        inventoryUid: explosive.product.code,
        product: {
          name: explosive.product.name,
          code: explosive.product.code
        },
        psn: explosive.psn,
        itemsToRemove: [makeExplosiveLighter(explosive)]
      };
    }
    mapOfProducts.set(explosive.product.code, product);
    return mapOfProducts;
  }, new Map());

  return Array.from(mappedItems.values());
};

const makeExplosiveLighter = explosive => {
  return {
    itemQuantity: explosive.itemQuantity,
    uid: explosive.uid,
    parentLine: explosive.parentLine
  };
};
