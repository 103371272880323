import React from 'react'
import {Button, Checkbox, Form, Header, Icon} from 'semantic-ui-react'
import "./Register.css";
import {Link} from "react-router-dom";
import {
    CognitoUserPool, 
    CognitoUserAttribute, 
} from 'amazon-cognito-identity-js';

const poolData = {
    UserPoolId: 'us-east-1_r738QDsdH',
    ClientId: '3ro592qgebcund6fepib41p4rl'
};

export default class RegisterContainer extends React.Component {


    constructor(props) {
        super(props);

        this.state = {

            // ROUTER
            redirectToLogin: false,
            

            // REGISTER FORM
            termsAgreed: false,
            name: "",
            phone: "",
            email: "",
            password: "",
            tenant: '',
        }
    }

    componentDidMount = () => {

    }

    handleSubmit = async event => {

        event.preventDefault();

        const { email, name, phone, password, termsAgreed } = this.state;

        var userPool = new CognitoUserPool(poolData);

        var attributeList = [];

        var dataEmail = {
            Name: 'email',
            Value: email
        };

        var dataName = {
            Name: 'name',
            Value: name
        };

        var dataPhoneNumber = {
            Name: 'phone_number',
            Value: phone
        };

        const dataTenantID = {
            Name: 'custom:tenant_id',
            Value: this.state.tenant
        };


        const attributeEmail = new CognitoUserAttribute(dataEmail);
        const attributePhoneNumber = new CognitoUserAttribute(dataPhoneNumber);
        const attributeName = new CognitoUserAttribute(dataName);
        const attributeTenantID = new CognitoUserAttribute(dataTenantID);

        attributeList.push(attributeEmail);
        attributeList.push(attributePhoneNumber);
        attributeList.push(attributeName);
        attributeList.push(attributeTenantID);

        
        if (termsAgreed) {
            try {
                userPool.signUp(email, password, attributeList, null, function(err, result) {
                    if (err) {
                        console.error(err);
                    } else {
                        
                        var cognitoUser = result.user;
                        console.log('user registered as ' + cognitoUser.getUsername());
                    }
                });
            } catch (e) {
                alert(e.message);
            }
        } else {
            alert("Please agree to our terms of agreement.")
        }
    };


    // Get info from the form inputs.
    handleTermsAndAgreementCheck = (event, data) => {

        this.setState({
            termsAgreed: data.checked,
        })

    };
    handleEmailChange = event => {

        this.setState({
            email: event.target.value
        });
    };
    handlePasswordChange = event => {

        this.setState({
            password: event.target.value,
        })
    };
    handleNameChange = event => {

        this.setState({
            name: event.target.value
        });
    };
    handlePhoneChange = event => {

        this.setState({
            phone: event.target.value,
        })
    };

    handleTenantChange = event => {

        this.setState({
            tenant: event.target.value,
        })
    };

    _handleRedirectToLogin = event => {

        this.props.history.push('/login');
    };



    render() {


        return (
            <div className="Register">
                <Header as='h2' icon textAlign='center'>
                    <button onClick={this._handleRedirectToLogin}> Back </button>
                    <Icon name='users' circular />
                    <Header.Content>Register</Header.Content>
                </Header>
                <Form onSubmit={ this.handleSubmit }>
                    <Form.Field>
                        <input
                            placeholder='name'
                            onChange={this.handleNameChange}/>
                    </Form.Field>
                    <Form.Field>
                        <input
                            placeholder='phone'
                        
                            onChange={this.handlePhoneChange}/>
                    </Form.Field>
                    <Form.Field>
                        <input
                            placeholder='email'
                            type='email'
                            onChange={this.handleEmailChange} />
                    </Form.Field>
                    <Form.Field>
                        <input
                            placeholder='password'
                            type='password'
                            onChange={this.handlePasswordChange}/>
                    </Form.Field>
                    <Form.Field>
                        <input
                            placeholder='Tenant'
                            type='text'
                            onChange={this.handleTenantChange}/>
                    </Form.Field>
                    <Form.Field>
                        <Checkbox onChange={ this.handleTermsAndAgreementCheck }
                                  label={<label> I agree to the <Link to="terms&conditions"> terms and conditions </Link> </label> }>

                        </Checkbox>
                    </Form.Field>
                    <Button type='submit'>Sign Up</Button>
                </Form>
            </div>
        )
    }

};