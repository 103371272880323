import {
  MERGE_CUSTOMERS,
  SET_FETCHING_CUSTOMERS_STATUS,
  SET_LAST_FETCHED_TIMESTAMP
} from "../types";

// Network imports.
import Axios from "axios";
import * as API from "../../../utils/API";

export const mergeCustomers = customers => ({
  type: MERGE_CUSTOMERS,
  customers
});

export const setFetchingCustomersStatus = status => ({
  type: SET_FETCHING_CUSTOMERS_STATUS,
  status
});

export const setLastFetchedTimestamp = timestamp => ({
  type: SET_LAST_FETCHED_TIMESTAMP,
  timestamp
});

export const fetchCustomers = (token, data) => {
  return dispatch => {
    console.debug("Fetching customers...");
    dispatch(setFetchingCustomersStatus(true));
    const params = {
      method: "post",
      headers: {
        Authorization: token
      },
      data,
      url: API.FETCH_CUSTOMERS
    };
    Axios(params)
      .then(res => {
        dispatch(setFetchingCustomersStatus(false));
        console.debug(res);
        if (res.data.status !== 201) return;
        const customers = res.data.items;
        if (!customers || customers.length === 0) return;
        dispatch(mergeCustomers(customers));
        dispatch(setLastFetchedTimestamp(Date.now()));
      })
      .catch(error => {
        dispatch(setFetchingCustomersStatus(false));
        console.debug("FETCHING ERROR", error.message);
      });
  };
};
