/**
 * Copyright Redi Kurti, 2019. All rights reserved.
 */

// React imports
import React from "react";
import { useHistory } from "react-router-dom";

// Redux imports
import { connect } from "react-redux";

// Other component imports
import DrawerItemMainAction from "../../../../components/DrawerItems/MainAction/DrawerItemMainAction";
import GoBack from "../../../../components/GoBack";

// CSS imports
import "./invoice.css";
import InvoiceSectionHeader from "../../../../components/Tracking/invoices/InvoiceSectionHeader";

const Invoice = props => {
  let history = useHistory();
  const { invoice, saveChanges } = props;

  const renderGoBack = () => {
    return (
      <GoBack
        onClick={() =>
          history.push(
            "/tracking/invoices/" + (invoice.isIn ? "in" : "out") + "/"
          )
        }
        color="black"
        title="Te gjitha faturat"
      />
    );
  };

  const renderAction = () => {
    return (
      <DrawerItemMainAction
        title="Save changes"
        iconName="save"
        iconColor="white"
        iconSize={24}
        handleClick={saveChanges}
      />
    );
  };

  return (
    <div className="tracking-in-invoices-invoice-root">
      <div className="tracking-in-invoices-invoice-content">
        <InvoiceSectionHeader
          title={`Fatura ${invoice.invoice_id}`}
          renderAction={renderAction}
          renderGoBack={renderGoBack}
        />
        {invoice.loading ? (
          <div> LOADING ... </div>
        ) : (
          <div className="tracking-in-invoices-invoice-body">
            <div> invoice_id: {invoice.invoice_id} </div>
            <div> date: {invoice.date} </div>
            <div> isTracking: {invoice.isTracking} </div>
            <div> tracking_status: {invoice.tracking_status} </div>
            <div> customer: {invoice.customer.name} </div>
            <div>files: {"\n" + JSON.stringify(invoice.files)}</div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { invoices } = state.tracking;
  const myInvoice = invoices[ownProps.invoice_id];
  return { invoice: myInvoice };
};

export default connect(mapStateToProps)(Invoice);
