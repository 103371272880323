import React from "react";
import PropTypes from "prop-types";
import MyLoader from "../../components/MyLoader";
import File from "../../components/Files/File";
import NoItemPlaceholder from "../../components/NoItemPlaceholder";
import Folder from "../../components/Files/Folder";
import "react-toastify/dist/ReactToastify.css";

import "./file.css";

const FileList = props => {
  const { files, loading, display, selectFile, addFile } = props;

  if (loading)
    return (
      <div className="FileListContainer">
        <MyLoader title="Loading files..." loading={loading} />
      </div>
    );

  if (files.length > 0 && display === "icon")
    return (
      <div className="FileListContainer">
        {files.map(file => {
          if (file.isFolder) {
            return (
              <Folder
                key={file.name + file.isFolder}
                name={file.name}
                isLoading={file.isLoading}
                isFolder={file.isFolder}
                isSelected={file.isSelected}
                bucketName={file.bucketName}
                prefix={file.prefix}
              />
            );
          } else {
            return (
              <File
                name={file.name}
                date={file.date}
                isLoading={file.isLoading}
                size={file.size}
                url={file.url}
                isFolder={file.isFolder}
                isSelected={file.isSelected}
                bucketName={file.bucketName}
                selectFile={selectFile}
                prefix={file.prefix}
                isRenaming={false}
              />
            );
          }
        })}
      </div>
    );

  return (
    <NoItemPlaceholder
      iconName="search"
      primaryAction={addFile}
      message="No files in this folder."
      primaryButtonName="+ Add File"
    />
  );
};

FileList.propTypes = {
  files: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  display: PropTypes.string.isRequired
};

export default FileList;
