import React from "react";
import Omnicon from "../../../assets/icons/Omnicon";

import "./drawer-item-main-action.css";

const DrawerItemMainAction = props => {
  const { title, iconName, iconColor, iconSize, handleClick } = props;

  return (
    <div className="drawer-item-main-action" onClick={handleClick}>
      <div className="drawer-item-main-action-title"> {title} </div>
      <div className="drawer-item-main-action-icon">
        <Omnicon
          name={iconName ? iconName : "plus"}
          color={iconColor ? iconColor : "white"}
          size={iconSize ? iconSize : 24}
        />
      </div>
    </div>
  );
};

export default DrawerItemMainAction;
