import React from "react";
import './icon.css';

const styles = {
    root: {
        width: '24px',
        height: '24px',
        position: 'relative',
        display: 'flex',
    },
};

export default class FolderPlus extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {

        let { color } = this.props;
        color = color ? color : 'black';

        return (
            <div style={styles.root}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                    fill="none" stroke={color}  strokeLinecap="round"
                    strokeLinejoin="round" className="feather feather-folder-plus">
                        <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"
                        />
                        <line x1="12" y1="11" x2="12" y2="17" />
                        <line x1="9" y1="14" x2="15" y2="14" />
                    </svg>
            </div>
        );
    }
}
