import React from 'react';
import propTypes from 'prop-types';
import {Link} from 'react-router-dom';
import './navigation.css';
import {Icon} from "semantic-ui-react";

const ServiceNavigationItem = ({iconName, iconColor, linkTo, title}) => {

    return (
        <Link to={linkTo}>
            <div className="drawer-services-menu-item">
                <div className="drawer-services-menu-item-icon-container">
                    <Icon name={iconName} size='huge' color={iconColor ? iconColor : 'teal'} />
                </div>
                <div className="drawer-services-menu-item-title-container">
                    {title}
                </div>
            </div>        
        </Link>
    );
    
}

ServiceNavigationItem.propTypes = {
    title: propTypes.string.isRequired,
    linkTo: propTypes.string.isRequired,
    iconName: propTypes.string.isRequired,
    iconColor: propTypes.string,
};

export default ServiceNavigationItem;
