import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import { Label } from 'semantic-ui-react'

import "./item-card.css";
import {Consumer} from "../../../context";



const styles = {
    lowQuantity: {
        color: "#484848"
    },
    highQuantity: {
        color: "#787878"
    },
    titleContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: 'center',
        alignItems: 'center',
        padding: "2px",
        fontFamily: "Lato",
    },
    title: {
        margin: "0 5px",
        fontWeight: '900',
    },
    customerName: {
        fontSize: '0.8em',
        fontWeight: '400'
    },
    total: {
        justifySelf: "flex-end",
        width: '100%',
        fontFamily: 'Lato',
        fontSize: '1em',
        padding: 7,
    }
};

export default class InvoiceCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {

        const { id, name, category, quantity, price, tag } = this.props;

        const linkToItem = `/inventory/view?id=${id}`;

        return (
            <Link to={linkToItem} style={{ textDecoration: 'none' }}>
                <Consumer>
                    { context => (
                        <div className="InvoiceCard">
                            <div className="Row1">
                                <div className="PaymentStatus" style={styles.date}>
                                    <p>  { quantity } </p>
                                </div>
                            </div>
                            <div className="Row1">
                                <div className="Id" style={styles.titleContainer}>
                                    <Label circular color={tag} empty/>
                                    <p style={styles.title}> { name } </p>
                                </div>
                            </div>
                            <div className="InvoiceCustomer">
                                <p style={styles.customerName}> {category} </p>
                            </div>
                            <div className="Total">
                                <div className="PaymentStatus" style={styles.total}>
                                    <Label style={styles.total} as='a' content={price*quantity} icon='dollar' />
                                </div>
                            </div>
                        </div>
                    )}
                </Consumer>
            </Link>
        );
    }
}




InvoiceCard.propTypes = {
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    quantity: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    isLow: PropTypes.bool.isRequired,
    user: PropTypes.object
};

