/**
 * Copyright Redi Kurti, 2019. All rights reserved.
 */

// React imports
import React from "react";
import { useHistory } from "react-router-dom";

// Redux imports
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Other component imports
import Barcode from "../../../../components/Tracking/Barcode";
import InvoiceSectionHeader from "../../../../components/Tracking/invoices/InvoiceSectionHeader";
import GoBack from "../../../../components/GoBack";

// CSS imports
import "./uids.css";

const Uids = props => {
  let history = useHistory();
  const { invoice } = props;

  const renderGoBack = () => {
    return (
      <GoBack
        onClick={() =>
          history.push(
            "/tracking/invoices/" +
              (invoice.isIn ? "in" : "out") +
              "/" +
              invoice.invoice_id +
              "/invoice"
          )
        }
        color="black"
        title={`Fatura ${invoice.invoice_id}`}
      />
    );
  };

  return (
    <div className="tracking-in-invoices-invoice-root">
      <div className="tracking-in-invoices-invoice-content">
        <InvoiceSectionHeader title="Barkodet" renderGoBack={renderGoBack} />
        <div className="tracking-in-invoices-invoice-body">
          {invoice.uids &&
            invoice.uids.length > 0 &&
            invoice.uids.map((barcode, index) => {
              return (
                <Barcode
                  key={barcode.uid}
                  barcode={barcode}
                  index={barcode.uid}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

const mapStateToProps = (state, ownProps) => {
  const { invoices } = state.tracking;
  const myInvoice = invoices[ownProps.invoice_id];
  console.log("UIDS for invoice", myInvoice);
  return { invoice: myInvoice };
};

export default connect(mapStateToProps, mapDispatchToProps)(Uids);
