import React from "react";
import { Icon, Loader } from "semantic-ui-react";
import { Consumer } from "../../containers/context";

import "./file.css";

const styles = {
  icon: {
    marginRight: "3px",
    flex: 0.1
  },
  title: {
    fontSize: "1em",
    fontFamily: "Lato",
    fontWeight: "400",
    flex: 0.1
  },
  arrow: {
    flex: 0.1
  },
  rootFolder: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
    padding: "0 0 0 5px"
  },
  children: {
    padding: "0 0 0 20px",
    display: "flex",
    flexDirection: "column"
  }
};

export default class FolderFlat extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: "",
      isOpen: false,
      renamingDone: false
    };
  }

  handleSelect = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    const {
      props: {
        isLoading,
        isLoadingChildren,
        isEmpty,
        isSelectedAsDestination,
        isHome,
        name,
        prefix,
        children,
        path
      },
      state: { isOpen }
    } = this;

    // Change background color for the item being de/selected.
    const bgColor = isSelectedAsDestination ? "rgba(120,205,158,0.7)" : null;

    const fullName = prefix === "" ? name : "/" + name;

    console.log("isSelectedAsDestnation", fullName, isSelectedAsDestination);
    return (
      <Consumer>
        {context => (
          <div>
            <div
              style={{ ...styles.rootFolder, backgroundColor: bgColor }}
              className="FolderFlat"
              onClick={() => {
                context.actions.selectAsDestination({
                  prefix,
                  name,
                  children,
                  isEmpty,
                  path
                });
                if (!isHome) this.handleSelect();
              }}
            >
              {!isHome && (
                <div style={styles.arrow}>
                  {isLoading || isLoadingChildren ? (
                    <Loader active inline size="small" />
                  ) : isEmpty ? null : isOpen ? (
                    <Icon name="angle down" size="large" />
                  ) : (
                    <Icon name="angle right" size="large" />
                  )}
                </div>
              )}

              <div style={styles.icon}>
                <Icon name="folder open outline" size="large" />
              </div>

              <div style={styles.title}> {isHome ? "HOME" : name} </div>
            </div>

            {isOpen && (
              <div style={styles.children}>
                {context.files
                  .filter(file => {
                    return file.isFolder && file.prefix === prefix + fullName;
                  })
                  .map(file => {
                    return (
                      <FolderFlat
                        name={file.name}
                        isLoading={file.isLoading}
                        isLoadingChildren={file.isLoadingChildren}
                        isFolder={file.isFolder}
                        isEmpty={file.isEmpty}
                        isSelectedAsDestination={
                          context.moveToDestination.name === file.name
                        }
                        bucketName={file.bucketName}
                        prefix={file.prefix}
                        path={file.path}
                      />
                    );
                  })}
              </div>
            )}
          </div>
        )}
      </Consumer>
    );
  }
}
