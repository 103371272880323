const formatDate = dateString => {
  const date = new Date(dateString);
  if (!(date instanceof Date && !isNaN(date))) return null;
  const today = new Date();

  const months = [
    "Janar",
    "Shkurt",
    "Mars",
    "Prill",
    "Maj",
    "Qershor",
    "Korrik",
    "Gusht",
    "Shtator",
    "Tetor",
    "Nentor",
    "Dhjetor"
  ];

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  const thisDay = today.getDate();
  const thisMonthIndex = today.getMonth();
  const thisYear = today.getFullYear();

  let dateDiff = 100;

  if (monthIndex === thisMonthIndex && year === thisYear) {
    dateDiff = thisDay - day;
  }

  if (dateDiff === 0) {
    return "Sot";
  } else if (dateDiff === 1) {
    return "Dje";
  } else if (dateDiff === -1) {
    return "Neser";
  } else if (year === thisYear) {
    return day + " " + months[monthIndex];
  } else {
    return day + " " + months[monthIndex] + " " + year;
  }
};

// Helper method that shortens the uid for visibility in mobile devices.
const shortenUid = uid => {
  console.log("SHORTENING UID > ", uid);

  // Start at 0 and ends at uid's length
  let endingIndex = uid.length - 1;

  // If uid length is less than 15, no shortening needed.
  if (endingIndex - 15 < 0) return uid;
  // Short starting from end-15 to end, and add ... at the end of the string.
  else return uid.substring(endingIndex - 15, endingIndex) + "...";
};

export { formatDate, shortenUid };
