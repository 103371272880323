import React from "react";
import "./inventory-detail-header.css";

const InventoryDetailHeader = ({ item, renderGoBack, renderMainAction }) => {
  const title =
    item.product && item.product.name ? item.product.name : "No name";
  return (
    <div className="inventory-detail-header">
      <div className="inventory-detail-go-back">
        {renderGoBack ? renderGoBack() : null}
      </div>
      <div className="inventory-detail-header-content">
        <div className="inventory-detail-header-title">
          {title ? title : "no title"}
        </div>
        <div className="inventory-detail-header-action">
          {renderMainAction ? renderMainAction() : null}
        </div>
      </div>
    </div>
  );
};

export default InventoryDetailHeader;
