export const SET_ACTIVE_DRAWER_ITEM = "SET_ACTIVE_DRAWER_ITEM";
export const TOGGLE_DRAWER = "TOGGLE_DRAWER";
export const INVOICE_IN_ADD_FILE = "INVOICE_IN_ADD_FILE";
export const INVOICE_IN_REMOVE_FILE = "INVOICE_IN_REMOVE_FILE";
export const TRACKING_INVOICE_SET_LOADING = "TRACKING_INVOICE_SET_LOADING";
export const TRACKING_INVOICE_ADD_EXPLOSIVE = "TRACKING_INVOICE_ADD_EXPLOSIVE";
export const TRACKING_INVOICE_ADD_EXPLOSIVE_LIST =
  "TRACKING_INVOICE_ADD_EXPLOSIVE_LIST";

// Invoice
export const MERGE_WITH_EXISTING_INVOICES = "MERGE_WITH_EXISTING_INVOICES";
export const SET_LAST_FETCHED_TIMESTAMP = "SET_LAST_FETCHED_TIMESTAMP";
export const DELETE_INVOICE = "DELETE_INVOICE";
export const ADD_INVOICE = "ADD_INVOICE";
export const DELETE_EXPLOSIVE = "DELETE_EXPLOSIVE";
export const ADD_EXPLOSIVE = "ADD_EXPLOSIVE";
export const ADD_EXPLOSIVES = "ADD_EXPLOSIVES";
export const SET_ACTIVE_RADIO_ITEM = "SET_ACTIVE_RADIO_ITEM";
export const SET_FETCHING_INVOICES_STATUS = "SET_FETCHING_INVOICES_STATUS";
export const SET_UPLOADING_INVOICE_STATUS = "SET_UPLOADING_INVOICE_STATUS";

// Invoice Explosives
export const CREATE_EXPLOSIVES = "CREATE_EXPLOSIVES";
export const UPDATE_EXPLOSIVES = "UPDATE_EXPLOSIVES";
export const DELETE_EXPLOSIVES = "DELETE_EXPLOSIVES";

// Inventory
export const MERGE_INVENTORY = "MERGE_INVENTORY";
export const SET_FETCHING_INVENTORY_STATUS = "SET_FETCHING_INVENTORY_STATUS";
export const SELECTED_ITEM_HISTORY_PUSH = "SELECTED_ITEM_HISTORY_PUSH";
export const SELECTED_ITEM_HISTORY_BACK = "SELECTED_ITEM_HISTORY_BACK";
export const SELECTED_ITEM_HISTORY_CLEAR = "SELECTED_ITEM_HISTORY_CLEAR";

// CUSTOMER
export const MERGE_CUSTOMERS = "MERGE_CUSTOMERS";
export const SET_FETCHING_CUSTOMERS_STATUS = "SET_FETCHING_CUSTOMERS_STATUS";

// INVOICE
export const UPDATE_NEW_INVOICE = "UPDATE_NEW_INVOICE";
export const SET_LOADING_STATUS = "SET_LOADING_STATUS";