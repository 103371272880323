import React from 'react';
import PropTypes from 'prop-types';
import { Button, Header, Icon } from "semantic-ui-react";


const styles = {
    primaryButton: {
        flex: 0.5,
        alignSelf: 'center',
        margin: '8px',
        maxWidth: '200px',
        borderRadius: '20px',
        color: 'black',
        backgroundImage: 'linear-gradient(to right, #43e97b 0%, #38f9d7 100%)',
    },
    messageContainer: {
        flex: 1,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    message: {
        display: 'flex',
        flexDirection: 'column',
        background: 'transparent',
    },
}

const NoItemPlaceholder = props => {
    const { message, iconName, primaryAction, primaryButtonName } = props;

    return (
        <div style={styles.messageContainer}>
            <div style={styles.message}>
                <Header icon>
                    <Icon name={iconName} />
                    {message}
                </Header>
                <Button
                    primary
                    style={styles.primaryButton}
                    onClick={primaryAction}> {primaryButtonName}
                </Button>
            </div>
        </div>
    );
}

NoItemPlaceholder.propTypes = {
    iconName: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    primaryAction: PropTypes.func,
    primaryButtonName: PropTypes.string,
};

export default NoItemPlaceholder;
