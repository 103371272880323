// src/components/Profile.js

import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";

import "./user.css";
import Omnicon from "../../assets/icons/Omnicon";

const User = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({
    email: "",
    name: ""
  });
  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: false
    })
      .then(user => {
        const myUser = {
          email: user.attributes.email,
          name: user.attributes.name
        };
        console.log(myUser);
        setLoading(false);
        setUser(myUser);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const handleClick = () => {
    // history.push("/profile");
    console.log("USER CLICKED");
  };

  if (loading) {
    return <div> Loading ...</div>;
  }

  return (
    <div className="appbar-user-root" onClick={handleClick}>
      <div className="appbar-user-content">
        <div className="appbar-user-info">
          <div className="appbar-user-name">{user.name}</div>
          <div className="appbar-user-email">{user.email}</div>
        </div>
        <div className="appbar-user-image">
          <div className="appbar-user-icon">
            <Omnicon name="user" color="#242F45" size={20} />
          </div>

          {/* <div className="appbar-user-image-letter">{user.name[0]}</div> */}
        </div>
      </div>
    </div>
  );
};

export default User;
