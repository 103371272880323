import React from 'react';
import NoItemPlaceholder from "./NoItemPlaceholder";
import {Icon} from "semantic-ui-react";
import PropTypes from 'prop-types';

const styles = {
    container: {
        flex: 1,
        height: '100%',
        marginRight: '20px',
        margin: '0 0 10px 0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: 'white',
        borderRadius: '20px',
        boxShadow: '0 7px 7px #f1f1f1',
    },
    header: {
        padding: '15px 20px',
        flex: 0.1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '20px 20px 0 0',
        // backgroundImage: 'linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)'
    },
    headerEmpty: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        marginBottom: '10px',
    },
    headerAction: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    headerTitle: {
        fontSize: '2.5em',
        fontWeight: '900',
        color: '#484848',
        display: 'flex',
        alignItems: 'center',
    },
    headerButton: {
        height: '35px',
        width: '35px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '2em',
        borderRadius: '20px',
        color: 'black',
        boxShadow: "0 1px 2px 2px #f5f5f5",
        backgroundImage: 'linear-gradient(to right, #43e97b 0%, #38f9d7 100%)',
        cursor: 'pointer',
    },
    listContainer: {
        padding: '15px',
        width: '100%',
        flex: 1,
    },
    list: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-around',

    },
    searchBox: {

    },
    messageContainer: {
        flex: 1,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    message: {
        display: 'flex',
        flexDirection: 'column',
        background: 'transparent',
    },

};

const ColumnHeaderList = props => {

    const { iconName, headerTitle, headerAction, children } = props;

    return (
        <div style={styles.container}>
            <div style={styles.header}>
                {
                    iconName
                    ?
                    <div style={styles.headerEmpty}>
                        <Icon name={iconName} size='huge' />
                    </div>
                    :
                    null
                }
                <div style={styles.headerAction}>
                    <div style={styles.headerTitle}> {headerTitle} </div>
                    {
                        headerAction !== undefined &&
                        <div style={styles.headerButton} onClick={headerAction}> + </div>
                    }
                </div>
            </div>
            <div style={styles.listContainer}>
                {
                    children === undefined || children.length === 0
                    ?
                        <NoItemPlaceholder
                            iconName="search"
                            message="No items."
                        />
                    :
                        <div style={styles.list}>
                            { children }
                        </div>
                }
            </div>
        </div>
    )
};

ColumnHeaderList.propTypes = {
    iconName: PropTypes.string,
    headerTitle: PropTypes.string.isRequired,
    headerAction: PropTypes.func,
};

export default ColumnHeaderList;