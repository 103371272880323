import {
  MERGE_INVENTORY,
  SET_LAST_FETCHED_TIMESTAMP,
  SET_FETCHING_INVENTORY_STATUS,
  SELECTED_ITEM_HISTORY_PUSH,
  SELECTED_ITEM_HISTORY_BACK,
  SELECTED_ITEM_HISTORY_CLEAR
} from "../types";

const INITIAL_STATE = {
  items: {},
  selectedItemHistory: [],
  fetchingInventory: false,
  explosives: [],
  last_fetched_timestamp: 0
};

const inventoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SELECTED_ITEM_HISTORY_PUSH:
      return {
        ...state,
        selectedItemHistory: state.selectedItemHistory
          ? [...state.selectedItemHistory, action.selectedItem]
          : [action.selectedItem]
      };
    case SELECTED_ITEM_HISTORY_BACK:
      return {
        ...state,
        selectedItemHistory: state.selectedItemHistory.slice(0, -1)
      };
    case SELECTED_ITEM_HISTORY_CLEAR:
      return {
        ...state,
        selectedItemHistory: []
      };
    case MERGE_INVENTORY:
      const newState = {
        ...state,
        fetchingInventory: false,
        items: {
          ...state.items,
          ...action.inventory.reduce((items, currentItem) => {
            return {
              ...items,
              [currentItem.uid]: currentItem
            };
          }, {})
        }
      };
      return newState;
    case SET_LAST_FETCHED_TIMESTAMP:
      return {
        ...state,
        last_fetched_timestamp: action.payload
      };
    case SET_FETCHING_INVENTORY_STATUS:
      return {
        ...state,
        fetchingInventory: action.status
      };
    default:
      return state;
  }
};

export default inventoryReducer;
