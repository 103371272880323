import React from 'react';
import PropTypes from 'prop-types';

import './payment-card.css';

const PaymentCard = props => {

    const { payment } = props;

    // Add dots every 3 digits for the payment amount
    const amount = payment.amount
        .toString()
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");

    return (
        <div className="payment-card-container">
            <div className="payment-card-header">
                <div className="payment-card-info">
                    { payment.bank } • { payment.date }
                </div>
                <div className="payment-card-invoice">
                    { payment.invoice }
                </div>
            </div>
            <div className="payment-card-body">
                <div className="payment-card-amount">
                    { amount }
                </div>
                <div className="payment-card-currency">
                    { payment.currency }
                </div>
            </div>
        </div>
    );
};

PaymentCard.propTypes = {
    customer: PropTypes.object.isRequired,
    payment: PropTypes.object.isRequired,
};

export default PaymentCard;
