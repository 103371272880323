// FILE
export const UPLOAD_FILE_URL    = 'https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/files/upload';
export const DELETE_FILES       = 'https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/files/delete';
export const MOVE_FILES         = 'https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/files/move';
export const RENAME_FILE_URL    = 'https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/files/rename';
export const GET_S3_SIGNED_URL  = 'https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/tracking/files/add';

// INVOICE
export const FETCH_ALL_INVOICES = 'https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/invoice/list';
export const ADD_INVOICE = 'https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/invoice/add';
export const FETCH_INVOICE_BY_ID = 'https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/invoice/id';
export const VIEW_INVOICE = 'https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/invoice/view';


// TRACKING
export const UPLOAD_TRACKING_FILE = 'https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/tracking/files/convert';
export const UPLOAD_TRACKING_ITEMS = 'https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/tracking/add';
export const ADD_TRACKING_INVOICE = 'https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/tracking/invoices/add';
export const GET_ENTRY = 'https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/tracking/entries';
export const SAVE_ENTRIES_FROM_XML_FILE = 'https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/tracking/entries/save';
export const UPDATE_ENTRIES = 'https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/tracking/entries/update';
export const FETCH_ALL_TRACKING_INVOICES = "https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/invoice/read";

// CUSTOMERS
export const FETCH_ALL_CUSTOMERS = 'https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/customer/read';
export const ADD_CUSTOMER = 'https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/customer/add';
export const VIEW_CUSTOMER = 'https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/customer/view';
export const DELETE_CUSTOMER = `https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/customer/list/delete`;
export const CUSTOMER_PAYMENT_CREATE = `https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/customer/payment/create`;


// INVENTORY
export const READ_CATALOG = 'https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/product/catalog';
export const CREATE_CATALOG = 'https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/product/catalog/create';
export const UPDATE_INVENTORY = 'https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/inventory/update';
export const READ_INVENTORY = 'https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/inventory';
export const GET_ITEM = 'https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/inventory/view';
export const GET_ITEM_TX = 'https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/inventory/view/tx';
export const UPDATE_ITEM = 'https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/inventory/list/update';
export const ADD_ITEM = 'https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/inventory/add';
export const ADD_INVENTORY_TX = 'https://3b7rolcfye.execute-api.us-east-1.amazonaws.com/dev/inventory/tx/add';


// EXPOS

// expos - Users
export const ORG1_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE1NTU4NDgyNDYsInVzZXJuYW1lIjoidXNlcjItMSIsIm9yZ05hbWUiOiJPcmcxIiwiaWF0IjoxNTUyMjQ4MjQ2fQ.hDtPEeXa1Dq9TFeGqJ2kQltzzT0m7NfIgV9_LN1zzlY";
export const ORG2_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE1NTU4NDgyNDcsInVzZXJuYW1lIjoidXNlcjItMiIsIm9yZ05hbWUiOiJPcmcyIiwiaWF0IjoxNTUyMjQ4MjQ3fQ.lDs78zJmSTj7tNkEUJugdVmAmniLHVqt2xBtJlOA9GM";
export const ORG3_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE1NTU4NDgyNDgsInVzZXJuYW1lIjoidXNlcjItMyIsIm9yZ05hbWUiOiJPcmczIiwiaWF0IjoxNTUyMjQ4MjQ4fQ.qvQEpPwH89nfZj-nXih3ER_WEgFMmYKZ4NLxpFNTi2E";
export const ORG4_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE1NTU4NDgyNDgsInVzZXJuYW1lIjoidXNlcjItNCIsIm9yZ05hbWUiOiJPcmc0IiwiaWF0IjoxNTUyMjQ4MjQ4fQ.WhRo6qJviqzOOUO4praBLzHDwX0mPufmFIF6QXk2owU";
export const ORG5_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE1NTU4NDgyNDksInVzZXJuYW1lIjoidXNlcjItNSIsIm9yZ05hbWUiOiJPcmc1IiwiaWF0IjoxNTUyMjQ4MjQ5fQ.LjDXooaX_tfkrU4iRyom0uD4b7GaH6xc6wOu1WMabkA";
export const ORG6_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE1NTU4NDgyNTAsInVzZXJuYW1lIjoidXNlcjItNiIsIm9yZ05hbWUiOiJPcmc2IiwiaWF0IjoxNTUyMjQ4MjUwfQ.bTdnQB0aCZfY85b98tFR_NTYJjCj44y3NAikLFOPCe8";

// expos - License
export const GET_LICENSES_BY_OWNER = `http://localhost:4000/channels/mychannel/chaincodes/license_cc?peer=peer0.$ORG.example.com&fcn=queryLicenseByOwner&args=%5B%22$ORG%22%5D`;
export const QUERY_LICENSE_BY_ISSUER = `http://localhost:4000/channels/mychannel/chaincodes/license_cc?peer=peer0.$ORG.example.com&fcn=queryLicenseByIssuer&args=%5B%22$ORG%22%5D`;
export const INVOKE_LICENSE_CC = "http://localhost:4000/channels/mychannel/chaincodes/license_cc";


// expos - Permit
export const INVOKE_PERMIT_CC = "http://localhost:4000/channels/mychannel/chaincodes/permit_cc";
export const QUERY_PERMIT_BY_ISSUER = `http://localhost:4000/channels/mychannel/chaincodes/permit_cc?peer=peer0.$ORG.example.com&fcn=queryPermitByIssuer&args=%5B%22$ORG%22%5D`;
export const QUERY_PERMIT_BY_BUYER = `http://localhost:4000/channels/mychannel/chaincodes/permit_cc?peer=peer0.$ORG.example.com&fcn=queryPermitByBuyer&args=%5B%22$ORG%22%5D`;
export const QUERY_PERMIT_BY_SELLER = `http://localhost:4000/channels/mychannel/chaincodes/permit_cc?peer=peer0.$ORG.example.com&fcn=queryPermitBySeller&args=%5B%22$ORG%22%5D`;
export const QUERY_PERMIT_BY_ID = `http://localhost:4000/channels/mychannel/chaincodes/permit_cc?peer=peer0.$ORG.example.com&fcn=getPermit&args=%5B%22$PERMIT_ID%22%5D`;