import React from 'react';
import axios from "axios/index";
import PropTypes from 'prop-types';
import * as API from "../../dowhileAPI";
import { Input, Button, Icon, Dropdown, Loader } from "semantic-ui-react";
import {Main} from "../AppBar";
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './customer-create-payments.css';

const styles = {
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    formInput: {
        margin: '10px',
        width: '100%',
    },
    action: {
        display: "flex",
        flexDirection: "row",
        justifyContent: 'center',
        alignItems: 'center',
    },
    primaryButton: {
        flex: 0.7,
        width: '100%',
        height: '45px',
        alignSelf: 'center',
        margin: '8px',
        borderRadius: '20px',
        color: 'black',
        boxShadow: "0 3px 3px 1px #e1e1e1",
        backgroundImage: 'linear-gradient(to right, #43e97b 0%, #38f9d7 100%)',
    },
    centeredContent: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
};

const banks = [
    {
        key: 0,
        text: 'Reifeissen',
        value: 'Reifeissen',
    },
    {
        key: 1,
        text: 'Credins',
        value: 'Credins',
    },
    {
        key: 2,
        text: 'BKT',
        value: 'BKT',
    },
    {
        key: 3,
        text: 'Banka popullore',
        value: 'Banka popullore',
    },
    {
        key: 4,
        text: 'OPT',
        value: 'OPT',
    }
]

export default class CustomerCreatePayment extends React.Component {

    toastId = null;
    successToast = message => this.toastId = toast.success(message, { autoClose: true, hideProgressBar: true });
    errorToast = message => this.toastId = toast.error(message, { autoClose: true, hideProgressBar: true });

    constructor(props) {

        super(props);

        this.state = {

            sideBarOpen: props.sideBarOpen,

            // AUTH
            user: props.user,
            
            customer: props.customer,
            invoices: props.invoices,

            // SERVER DATA
            uploading: false,       // Is it uploading to server?
            uploaded: false,        // Has it been uploaded successfully?
            error: null,            // error object

            // PAYMENT FORM DATA
            amount: "",
            currency: "ALL",
            bank: "",
            invoice: "",
            date: "",

            amountErr: false,
            currencyErr: false,
            bankErr: false,
            invoiceErr: false,
            dateErr: false,
        }
    }

    componentDidMount() {
        if (!this.state.customer.name) {
            this.props.history.push(`/customer/${this.props.match.params.id}`)
        }
    }

    // INVOICE FORM DATA HANDLERS
    _handleAmountChange = event => {

        this.setState({
            amount: event.target.value,
        })
    };
    _handleCurrencyChange = currency => {

        this.setState({ currency })
    };
    _handleBankChange = (event, data) => {
        
        this.setState({
            bank: data.value,
        })
    };
    _handleInvoiceChange = (event, data) => {
        
        this.setState({
            invoice: data.value
        })
    };
    _handleInvoiceAddition = (e, { value }) => {
        this.setState(prevState => ({
            invoices: [{ invoice_id: value }, ...prevState.invoices],
        }))
    };
    _handleDateChange = event => {

        this.setState({
            date: event.target.value,
        })
    };

    _validateForm = () => {

        const { amount, currency, bank, invoice, date } = this.state;

        let amountErr = amount === "";
        let currencyErr = currency === "";
        let bankErr = bank === "";
        let invoiceErr = invoice === "";
        let dateErr = date === "";

        // If one of the fields is empty, update error state for all fields.
        if (amountErr || currencyErr || bankErr || invoiceErr || dateErr) {
            this.setState({ amountErr, currencyErr, bankErr, invoiceErr, dateErr });
            return;
        }
            
        const payment = { amount, currency, bank, invoice, date };
        this.uploadPayment([payment])
            .then(this.handleUploadPaymentResponse)
            .catch(err => {
                this.setState({error: err});
                console.log(err);
            }) 
    };

    // Upload Customer
    uploadPayment = payments => {

        console.log('UPLOADING PAYMENT...');

        this.setState({
            uploading: true,
        });

        const { customer, user } = this.state;


        const data = {
            customer,
            payments
        };

        const config = {
            headers: {
                'Authorization': user.idToken,
                'tenant_id': user.tenantID,
            }
        };

        return axios.post(API.CUSTOMER_PAYMENT_CREATE, data, config)
    };
    handleUploadPaymentResponse = res => {

        console.log(res);

        // Hide loader
        this.setState({
            uploading: false,
        });

        if (res.data.status === 201) {

            this.setState({
                uploaded: true,
            })

            const { amount, currency, bank, invoice, date } = this.state;
            const payment = { amount, currency, bank, invoice, date };

            this.props.addPayment([payment]);
            this.props.history.goBack();

        } else {

            this.errorToast(res.data.body.Error.message);

            this.setState({
                uploaded: false,
            })
        }
        
        // const data = JSON.parse(response.data.body);
    };

    render() {

        const {
            customer,
            invoices,
            amountErr, bankErr, invoiceErr, dateErr,
            currency, invoice,
            uploading,
        } = this.state;

        if (uploading)
            return (
                <div style={styles.centeredContent}>
                    <Loader active inline />
                </div>
            );
        
        return (
                <div className="customer-payments-create-container">
                    <ToastContainer />
                    <div className="customer-payments-create-header">
                        <div className="customer-payments-create-header-title">
                            {customer.name}
                        </div>
                        <Icon name='user circle' size='huge' />
                    </div>
                <div className="customer-payments-create-form">
                    <div className="customer-payments-create-form-header">
                        Pagese e re
                    </div>
                    <Dropdown 
                        style={styles.formInput}
                        placeholder='Fatura' 
                        fluid
                        search 
                        selection 
                        error={invoiceErr}
                        options={invoices.map(invoice => {
                            return {
                                key: invoice.invoice_id,
                                text: invoice.invoice_id,
                                value: invoice.invoice_id,
                            }
                        })}
                        allowAdditions
                        value={invoice}
                        onAddItem={this._handleInvoiceAddition}
                        onChange={this._handleInvoiceChange}
                    />
                    <Input
                        style={styles.formInput}
                        placeholder='Data'
                        type='date'
                        icon='calendar alternate outline'
                        iconPosition='left'
                        error={dateErr}
                        onChange={this._handleDateChange}
                    />
                    <Input
                        style={styles.formInput}
                        placeholder='Amount'
                        type='number'
                        icon='dollar'
                        iconPosition='left'
                        error={amountErr}
                        onChange={this._handleAmountChange}
                    />
                    <Button.Group style={styles.formInput}>
                        <Button 
                            active={currency === 'ALL'}
                            onClick={ () => this._handleCurrencyChange('ALL')}>
                                ALL
                        </Button>
                        <Button
                            active={currency === 'EUR'}
                            onClick={ () => this._handleCurrencyChange('EUR')}>
                                EUR
                        </Button>
                        <Button
                            active={currency === 'USD'}
                            onClick={ () => this._handleCurrencyChange('USD')}>
                                USD
                        </Button>
                    </Button.Group>
                    <Dropdown 
                        style={styles.formInput}
                        placeholder='Bank' 
                        error={bankErr}
                        fluid
                        search 
                        selection 
                        options={banks}
                        onChange={this._handleBankChange}
                    />
                </div>
                <div style={styles.action}>
                    <Button
                        disabled={false}
                        style={styles.primaryButton}
                        onClick={this._validateForm}
                    > 
                        SAVE
                    </Button>
                </div>
            </div>
        );
    }
}

CustomerCreatePayment.propTypes = {
    sideBarOpen: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
    customer: PropTypes.object.isRequired,
    invoices: PropTypes.array.isRequired,
    addPayment: PropTypes.func.isRequired,
};