import React from "react";
import { Icon } from "semantic-ui-react";
import { Consumer } from "../../containers/context";

const styles = {
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    width: "100%",
    borderRadius: "7px 7px 0 0",
    justifyContent: "space-around"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    flex: "0.33",
    width: "100%",
    justifyContent: "space-around"
  }
};

export default class FileOption extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  _handleOnDelete = () => {
    console.log("Delete");
  };
  _handleOnSelect = () => {
    console.log("Select");
  };
  _handleOnShare = () => {
    console.log("Share");
  };
  _handleOnMore = () => {
    console.log("More");
  };

  render() {
    const {
      props: { name },
      _handleOnShare,
      _handleOnMore
    } = this;

    return (
      <Consumer>
        {context => (
          <div style={styles.container}>
            <div className="FileOptionRow">
              <div
                className="FileOptionDelete"
                onClick={() => context.actions.deleteFile(name)}
              >
                <div>
                  <Icon name="trash" />
                </div>
                <div>Delete</div>
              </div>
            </div>
            <div className="FileOptionRow">
              <div className="FileOptionShare" onClick={_handleOnShare}>
                <div>
                  <Icon name="paper plane" />
                </div>
                <div>Share</div>
              </div>
            </div>
            <div className="FileOptionRow">
              <div className="FileOptionMore" onClick={_handleOnMore}>
                <div>
                  <Icon name="ellipsis vertical" />
                </div>
                <div>More</div>
              </div>
            </div>
          </div>
        )}
      </Consumer>
    );
  }
}

FileOption.propTypes = {};
