import React from "react";
import './icon.css';

const styles = {
    root: {
        width: '24px',
        height: '24px',
        position: 'relative',
        display: 'flex',
    },
};

export default class FileMove extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {

        let { color } = this.props;
        color = color ? color : 'black';

        return (
            <div style={styles.root}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                fill="none" stroke={color} strokeWidth="1" strokeLinecap="round"
                strokeLinejoin="round" className="feather feather-file-move">
                    <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"/>
                    <polyline points="15 10 20 15 15 20"></polyline>
                    <path d="M2 4v7a4 4 0 0 0 4 4h12"></path>
                </svg>
            </div>
        );
    }
}
