import React from "react";
import PropTypes from "prop-types";

import "./customer-card.css";

const styles = {
    container: {
        padding: '8px 15px',
        borderRadius: '15px',
        display: 'flex',
        flexDirection: 'column',
    }
};

class CustomerCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    _handleSelection = event => {

        console.log(`CustomerCard -> Customer selected with source ${this.props.source}`);

        if (this.props.source === "customer") {

            const linkToCustomer = `/customer/${this.props.id}/`;
            this.props.history.push(linkToCustomer);

        } else {

            this.props.handleSelection({
                
                customer_id: this.props.id,
                name: this.props.name,
                address: this.props.address,
                email: this.props.email,
                phone: this.props.phone
            })
        }
    };

    render() {

        const path = 'M-314, ' + (300 + Math.floor(Math.random() * 400)) + ' C105,364 400,100 812,279';

        return (
            <div className="Customer" onClick={this._handleSelection}>

                <div style={styles.container}>
                    <div className="Row1">
                        <div className="Name">
                            <p> {this.props.name} </p>
                        </div>
                        <div className="PaymentStatus">

                        </div>
                    </div>
                    <div className="Address">
                        <p> {this.props.address} </p>
                    </div>
                </div>

                <svg id="svg" xmlns="http://www.w3.org/2000/svg" viewBox="-300 0 950 270" >
                    {
                        <path d={path} fill="none" stroke="white" strokeWidth="120" strokeLinecap="round"/>
                    }
                </svg>
            </div>
        );
    }
}

CustomerCard.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    source: PropTypes.string,
};

export default CustomerCard;
