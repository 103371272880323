/**
 * Copyright Redi Kurti, 2020. All rights reserved.
 */

import React from "react";

// Other component imports
import { Checkbox, Input } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import _ from "lodash";
import moment from "moment";

// Redux imports
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateNewInvoice } from "../../../redux/actions/InvoiceActions";

// CSS imports
import "react-datepicker/dist/react-datepicker.css";
import "./invoice-form.css";
import InvoiceFormHeader from "../../../../components/Tracking/invoices/new/InvoiceFormHeader";

const styles = {
  formInput: {
    margin: "10px 0",
    width: "100%",
    fontWeight: "bold"
  }
};

const InvoiceForm = ({
  customerList,
  customerLoading,
  customerError,
  newInvoice,
  updateNewInvoice
}) => {
  const { invoice_id, customer, date, isIn } = newInvoice;

  const handleIdChange = event => {
    updateNewInvoice({ invoice_id: event.target.value });
  };
  const handleCustomerChange = (event, { value }) => {
    const selectedCustomer = customerList.filter(customer => {
      return customer.name === value;
    });

    if (selectedCustomer.length !== 0) {
      updateNewInvoice({
        customer: selectedCustomer[0]
      });
    } else {
      updateNewInvoice({
        customer: {
          name: value,
          id: value + Math.round(Math.random() * 100).toString()
        }
      });
    }
  };
  const handleInChange = () => {
    updateNewInvoice({ isIn: !newInvoice.isIn });
  };
  const handleDateChange = date => {
    updateNewInvoice({ date });
  };

  return (
    <div style={styles.formContainer} className="invoice-form-container">
      <InvoiceFormHeader title="Fatura e re" />
      <div
        style={styles.formContainerBody}
        className="invoice-form-container-body"
      >
        <div>
          <Input
            style={styles.formInput}
            size="medium"
            placeholder="Numri i fatures..."
            type="text"
            icon="tag"
            iconPosition="left"
            onChange={handleIdChange}
          />
        </div>
        <div>
          <Input
            style={styles.formInput}
            size="medium"
            placeholder="Klienti..."
            type="text"
            icon="user"
            iconPosition="left"
            list="firms"
            loading={customerLoading}
            error={customerError}
            onChange={handleCustomerChange}
          />
          <datalist id="firms">
            {customerList.map((customer, index) => {
              return (
                <option
                  key={customer.customer_id + index}
                  value={customer.name}
                />
              );
            })}
          </datalist>
        </div>
        <div
          style={styles.checkBoxContainer}
          className="invoice-form-checkbox-container"
        >
          <Checkbox
            style={styles.formInput}
            label="Hyrje"
            onClick={handleInChange}
          />
        </div>
        <DatePicker
          inline
          dateFormat="DD/MM/YYYY"
          selected={date._isAMomentObject ? date : moment()}
          onChange={handleDateChange}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateNewInvoice
    },
    dispatch
  );

const mapStateToProps = state => {
  const { newInvoice } = state.invoice;
  return { newInvoice };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceForm);
