/**
 * Copyright Redi Kurti, 2020. All rights reserved.
 */

import React from "react";

// Redux imports
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchTrackingInventory,
  pushItemToSelectedHistory,
  removeItemFromSelectedHistory
} from "../../redux/actions/InventoryActions";
import { getUnits } from "../../../utils/Inventory";

// Network imports
import { Auth } from "aws-amplify";

import InventoryDetailHeader from "../../../components/Tracking/inventory/InventoryDetailHeader";
import InventoryDetailUnits from "../../../components/Tracking/inventory/InventoryDetailUnits";
import DrawerItemMainAction from "../../../components/DrawerItems/MainAction/DrawerItemMainAction";
import GoBack from "../../../components/GoBack";

import "./tracking-inventory-detail.css";

const InventoryDetail = props => {
  const {
    refreshing = false,
    fetchTrackingInventory,
    pushItemToSelectedHistory,
    removeItemFromSelectedHistory,
    item,
    units
  } = props;

  const onRefresh = async () => {
    await Auth.currentSession()
      .then(data => {
        const token = data.idToken.jwtToken;
        token && fetchTrackingInventory(token, item.uid.split("+").splice(1));
      })
      .catch(err => console.log(err));
  };

  const handleSelection = item => {
    pushItemToSelectedHistory(item);
  };

  const renderGoBack = () => (
    <GoBack onClick={() => removeItemFromSelectedHistory(item)} color="black" />
  );

  const renderMainAction = () => (
    <DrawerItemMainAction
      title=""
      iconName="download"
      iconColor="white"
      iconSize={24}
      handleClick={onRefresh}
    />
  );

  return (
    <div className="tracking-inventory-detail-root">
      <InventoryDetailHeader
        item={item}
        renderMainAction={renderMainAction}
        renderGoBack={renderGoBack}
      />
      {item.isItem ? (
        <div> IS ITEM </div>
      ) : (
        <InventoryDetailUnits
          loading={refreshing}
          items={units}
          selectItem={handleSelection}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTrackingInventory,
      pushItemToSelectedHistory,
      removeItemFromSelectedHistory
    },
    dispatch
  );

const mapStateToProps = (state, ownProps) => {
  const { fetchingInventory, items, selectedItemHistory } = state.inventory;

  if (selectedItemHistory.length === 0)
    ownProps.history.push("/tracking/inventory");
  const itemFromProps = selectedItemHistory.slice(-1)[0];
  if (!itemFromProps) return { item: null };
  const units = getUnits(itemFromProps, items);

  return {
    refreshing: fetchingInventory,
    item: itemFromProps,
    units
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryDetail);
