/**
 * Copyright Redi Kurti, 2019. All rights reserved.
 */

import React from "react";
import propTypes from "prop-types";
import Omnicon from "../../assets/icons/Omnicon";
import User from "./User";

// Redux imports
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toggleDrawer } from "../../containers/redux/actions/AppbarActions";

// CSS imports
import "./appbar.css";

const Appbar = props => {
  const { toggleDrawer, title, children } = props;

  const handleServicesToggle = () => {};
  return (
    <div className="appbar-root">
      <div className="appbar-content">
        <div className="appbar-left">
          <div className="appbar-menu" onClick={toggleDrawer}>
            <div className="appbar-menu-content">
              <Omnicon name="menu" color="#47455E" size={24} />
            </div>
          </div>
          <div className="appbar-title" onClick={handleServicesToggle}>
            {title}
          </div>
        </div>
        <div className="appbar-center">{children}</div>
        <User />
      </div>
    </div>
  );
};

Appbar.propTypes = {
  title: propTypes.string
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      toggleDrawer
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Appbar);
