import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import "./invoice-card.css";
import Icon from "../../../components/Icon/Icon";

const InvoiceCard = props => {
  const { invoice, linkTo } = props;
  let {
    invoice_id,
    customer,
    date,
    payment_status,
    isIn,
    amount,
    currency
  } = invoice;

  if (!customer) {
    customer = {
      customer_id: "X00000000X",
      name: "Customer unknown"
    };
  }

  if (!amount) amount = 1000000;
  if (!currency) currency = "ALL";

  let localDate;

  console.log(invoice);

  try {
    localDate = new Intl.DateTimeFormat("en-GB", {
      year: "numeric",
      month: "short",
      day: "2-digit"
    }).format(new Date(date));
  } catch (e) {
    console.error(e);
    localDate = date;
  }

  const isInText = isIn ? "in" : "out";

  const linkToCustomer = `/customer/view?id=${customer.customer_id}`;
  const linkToInvoice = `${invoice_id}/`;

  const isPaid = payment_status === "ok";
  const isPaidText = isPaid ? "Payment done" : "Payment pending";
  const isPaidColor = isPaid ? "green" : "orange";
  const isPaidIcon = isPaid ? "check" : "alert-circle";

  // const isInColor = isIn ? "green" : "orange";
  // const isInText = isIn ? "In" : "Out";
  // const isInIcon = isIn ? "chevron-down" : "chevron-up";

  // Add dots every 3 digits for the invoice amount
  const amountHumanReadable = amount.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");

  const className = isPaid
    ? "invoice-card invoice-card-paid"
    : "invoice-card invoice-card-pending";

  return (
    <Link to={linkToInvoice} style={{ textDecoration: "none" }}>
      <div className={className}>
        <div className="invoice-card-row-1">
          <div className="invoice-card-id">{invoice_id}</div>
          <div className="invoice-card-date">{localDate}</div>
        </div>
        <div className="invoice-card-customer">
          <Link to={linkToCustomer} style={{ textDecoration: "none" }}>
            <p> {customer.name} </p>
          </Link>
        </div>
        <div className="invoice-card-finance">
          <div className="invoice-card-finance-row-1">
            <div className="invoice-card-indicator">
              <div className="invoice-card-indicator-icon">
                <Icon name={isPaidIcon} size={24} color={isPaidColor} />
              </div>
              <div
                className="invoice-card-indicator-text"
                style={{ color: "#484848" }}
              >
                {isPaidText}
              </div>
            </div>
            {/* <div className="invoice-card-indicator">
              <div className="invoice-card-indicator-icon">
                <Icon name={isInIcon} size={24} color={isInColor} />
              </div>
              <div
                className="invoice-card-indicator-text"
                style={{ color: "#484848" }}
              >
                {isInText}
              </div>
            </div> */}
          </div>
          <div className="invoice-card-finance-row-2">
            <div className="invoice-card-amount">{amountHumanReadable}</div>
            <div className="invoice-card-currency">{currency}</div>
          </div>
        </div>
      </div>
    </Link>
  );
};

InvoiceCard.propTypes = {
  invoice: PropTypes.object.isRequired,
  linkTo: PropTypes.string.isRequired
};

export default InvoiceCard;
