import React from "react";
import FolderFlat from "./FolderFlat";
import { Button, Icon } from "semantic-ui-react";
import { Consumer } from "../../containers/context";

import "./file.css";

let styles = {
  root: {
    fontFamily: "Lato",
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    padding: "5px 10px 10px 10px",
    boxShadow: "0 10px 30px 3px #b9b9b9"
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flex: 0.1
  },
  close: {
    height: "30px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  closeButtonContainer: {
    height: "30px",
    width: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  },
  action: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  primaryButton: {
    flex: 0.9,
    alignSelf: "center",
    margin: "8px",
    maxWidth: "200px",
    borderRadius: "20px",
    color: "white",
    boxShadow: "0 10px 30px 3px #b9b9b9",
    // backgroundImage: "linear-gradient(to right, #43e97b 0%, #38f9d7 100%)"
    backgroundColor: "#6CA59F"
  },
  empty: {
    flex: 1
  },
  title: {
    fontSize: "36px",
    fontWeight: "700"
  },
  container: {
    padding: "20px 0",
    display: "flex",
    flexDirection: "column",
    flex: 0.9
  }
};

export default class MoveFile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navigation: []
    };
  }

  render() {
    const {
      props: { readyToSelect }
    } = this;

    return (
      <Consumer>
        {context => (
          <div style={styles.root} className="MoveFileDrawer">
            <div style={styles.header}>
              <div style={styles.title}> Move to </div>
              <div style={styles.close} onClick={context.actions.closeMoveFile}>
                <div style={styles.closeButtonContainer}>
                  <Icon name="close" />
                </div>
              </div>
            </div>

            <div style={styles.container}>
              <FolderFlat
                isHome={true}
                name={""}
                isFolder={false}
                isEmpty={true}
                isSelectedAsDestination={context.moveToDestination.name === ""}
                bucketName={"dowhile-gemo-in"}
                prefix={""}
                path={""}
              />
              {context.files
                .filter(file => file.isFolder && file.prefix === "")
                .map((file, index) => {
                  return (
                    <FolderFlat
                      key={file.name + index}
                      name={file.name}
                      isLoading={file.isLoading}
                      isLoadingChildren={file.isLoadingChildren}
                      isFolder={file.isFolder}
                      isEmpty={file.isEmpty}
                      isSelectedAsDestination={
                        context.moveToDestination.name === file.name
                      }
                      bucketName={file.bucketName}
                      prefix={file.prefix}
                      path={file.path}
                    />
                  );
                })}
            </div>
            <div style={styles.action}>
              <Button
                disabled={!readyToSelect}
                style={styles.primaryButton}
                onClick={context.actions.confirmMove}
              >
                Confirm
              </Button>
            </div>
          </div>
        )}
      </Consumer>
    );
  }
}
