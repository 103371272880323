import React from "react";
import PropTypes from "prop-types";
import "./summary-item.css";

const SummaryItemCard = props => {
  const { name, quantity } = props;
  return (
    <div className="SummaryItemCard">
      <div className="SummaryItemProductName">{name}</div>
      <div className="SummaryItemProductQnt">{quantity}</div>
    </div>
  );
};

SummaryItemCard.propTypes = {
  name: PropTypes.string.isRequired,
  quantity: PropTypes.string.isRequired
};
export default SummaryItemCard;
