import React from "react";
import PropTypes from "prop-types";
import { Icon, Loader } from "semantic-ui-react";
import FileOption from "./FileOption";
import { Consumer } from "../../containers/context";
import axios from "axios";
import { Auth } from "aws-amplify";
import * as API from "../../utils/API";

const styles = {
  progress: {
    marginTop: "50"
  },
  HeaderContainer: {
    width: "100%",
    flex: "1",
    display: "flex",
    flexDirection: "column"
  },
  Image: {
    boxSizing: "border-box",
    width: "100%",
    flex: "1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "2em",
    userSelect: "none",
    borderRadius: "7px 7px 0 0"
  },
  Title: {
    // background: 'lightgray',
    // flex: '0.2',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // cursor: 'pointer',
    // borderRadius: '0 0 7px 7px',
  },
  optionsBar: {
    display: "flex",
    flex: "0.2",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "7px"
  },
  OptionContainer: {
    width: "100%",
    flex: "1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  }
};

export default class File extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileHover: false,
      optionOpen: false,
      timeout: ""
    };
  }

  getDownloadURL = async (key, prefix) => {
    console.log("getDownloadURL > key", key);
    console.log("getDownloadURL > prefix", prefix);
    const token = await Auth.currentSession()
      .then(data => {
        return data.idToken.jwtToken;
      })
      .catch(err => console.log(err));

    return axios({
      method: "post",
      url: API.TRACKING_FILES_DOWNLOAD,
      headers: {
        Authorization: token
      },
      data: { key, prefix }
    })
      .then(res => {
        return res.data;
      })
      .catch(error => {
        console.log(error);
      });
  };
  _downloadFile = async () => {
    const { name, prefix } = this.props;
    console.log(this.props);
    const url = await this.getDownloadURL(
      name,
      prefix + (prefix === "" ? "" : "/")
    );
    window.open(url, "_self");
  };
  _handleOnMouseEnter = () => {
    // Set state that mouse has entered.
    this.setState({
      fileHover: true
    });
  };
  _handleOnMouseLeave = () => {
    this.setState({
      fileHover: false,
      optionOpen: false
    });
  };
  _handleOptionBarOnMouseEnter = () => {
    this.setState({
      optionOpen: !this.state.optionOpen
    });
  };

  render() {
    const {
      props: { isLoading, isSelected, name, prefix },
      state: { fileHover, optionOpen }
    } = this;

    const fileParts = name.split(".");
    let fileName, fileType;
    if (fileParts.length > 1) {
      fileName = fileParts[0];
      fileType = fileParts[1];
    }

    // Change background color for the item being de/selected.
    const bgColor = isSelected ? "rgba(215, 215, 215, 0.3)" : "white";

    return (
      <div className="File">
        <div
          style={styles.HeaderContainer}
          onMouseEnter={this._handleOnMouseEnter}
          onMouseLeave={this._handleOnMouseLeave}
        >
          {/* {fileHover && (
            <div
              style={styles.optionsBar}
              onClick={this._handleOptionBarOnMouseEnter}
            >
              <Icon name={`chevron ${optionOpen ? "up" : "down"}`} />
            </div>
          )} */}
          {
            <div style={styles.OptionContainer}>
              {isLoading ? (
                <Loader active inline="centered" />
              ) : (
                <Consumer>
                  {context =>
                    fileHover && optionOpen ? (
                      <FileOption name={name} />
                    ) : (
                      <div
                        style={{
                          ...styles.Image,
                          backgroundColor: bgColor
                        }}
                        onClick={() => context.actions.selectFile({name, prefix})}
                      >
                        {fileType}
                      </div>
                    )
                  }
                </Consumer>
              )}
            </div>
          }
        </div>

        <div
          className="FileTitle"
          style={styles.Title}
          onClick={this._downloadFile}
        >
          {fileName}
        </div>
      </div>
    );
  }
}

File.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string,
  date: PropTypes.string,
  url: PropTypes.string,
  browserURL: PropTypes.string,
  size: PropTypes.number,
  image: PropTypes.string,
  bucketName: PropTypes.string,
  isLoading: PropTypes.bool,
  isFolder: PropTypes.bool,
  fullPath: PropTypes.array,
  removeFile: PropTypes.func.isRequired,
  renameFile: PropTypes.func.isRequired,
  selectFile: PropTypes.func.isRequired,
  navigateToFolder: PropTypes.func.isRequired
};
