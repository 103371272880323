import React from "react";
import { Icon } from "semantic-ui-react";
import DrawerItem from "../../../components/DrawerItems/Navigator/DrawerItem";
import DrawerItemMainAction from "../../../components/DrawerItems/MainAction/DrawerItemMainAction";
import "./drawer.css";

const DrawerContainer = ({ open, toggle, goBack, children, mainAction }) => {
  if (!open) return null;

  return (
    <div className="Drawer">
      <div className="drawer-service-container">
        {window.innerWidth < 698 && (
          <div
            className="drawer-close"
            onClick={toggle}
            style={{ cursor: "pointer" }}
          >
            <Icon circular name="x" size="large" />
          </div>
        )}
        {mainAction && <DrawerItemMainAction
            title={mainAction.title}
            iconName={mainAction.iconName}
            iconColor={mainAction.iconColor}
            handleClick={mainAction.handleClick}
            primaryPositive
          />}
        {goBack && (
          <DrawerItem
            title="Go back"
            iconName="arrow left"
            iconSize="large"
            iconColor="black"
            handleClick={goBack}
          />
        )}
      </div>

      <div className="drawer-item-list-container">{children}</div>
    </div>
  );
};

DrawerContainer.propTypes = {};

export default DrawerContainer;
