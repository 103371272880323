import React, { Component } from "react";
import PropTypes from "prop-types";
import Icon from "../../Icon/Icon";

import "./drawer-item.css";

const styles = {
  infoLabel: {
    justifySelf: "flex-end",
    alignSelf: "right"
  },
  primaryPositive: {
    width: "80%",
    backgroundColor: "#2336BD",
    color: "white",
    borderRadius: "10px",
    margin: "10px"
  },
  primaryNegative: {
    backgroundImage:
      "linear-gradient(to right, #f78ca0 0%, #f9748f 19%, #fd868c 60%, #fe9a8b 100%)",
    color: "black",
    borderRadius: "0 20px 20px 0",
    margin: "5px 5px 5px 0",
    boxShadow: "0 4px 6px rgba(0,0,0,0.15)"
  },
  disabled: {
    backgroundColor: "rgba(220, 220, 220, 0.8)",
    color: "black",
    borderRadius: "0 20px 20px 0",
    margin: "5px 5px 5px 0",
    cursor: "no-drop",
    boxShadow: "0 4px 6px rgba(0,0,0,0.15)"
  },
  active: {
    backgroundColor: "#f9f9f9",
    borderLeft: "4px solid #2336BD",
    paddingLeft: "36px",
    color: "#2336BD"
  }
};

export default class DrawerItem extends Component {
  _handleClick = () => {
    console.debug("DrawerItem click!");
    if (!this.props.disabled) this.props.handleClick();
  };

  render() {
    const {
      title,
      iconName,
      iconColor,
      primaryPositive,
      primaryNegative,
      primaryBottom,
      disabled,
      active
    } = this.props;

    let customStyle;

    if (primaryPositive) customStyle = styles.primaryPositive;
    if (primaryNegative) customStyle = styles.primaryNegative;
    if (disabled) customStyle = styles.disabled;
    if (primaryBottom) customStyle = styles.primaryBottom;
    if (active) {
      customStyle = styles.active;
    }

    return (
      <div
        className="drawer-item"
        onClick={this._handleClick}
        style={{
          ...customStyle,
          fontFamily: "Lato, Segoe UI, sans-serif"
        }}
      >
        <div className="drawer-item-icon">
          <Icon
            name={iconName}
            color={active ? "#2336BD" : iconColor}
            size={24}
            // focused={active}
          />
        </div>
        <div className="drawer-item-title"> {title} </div>
      </div>
    );
  }
}

DrawerItem.propTypes = {
  handleClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
  iconSize: PropTypes.string,
  loading: PropTypes.bool,
  primaryPositive: PropTypes.bool,
  primaryNegative: PropTypes.bool,
  disabled: PropTypes.bool
};
