/**
 * Copyright Redi Kurti, 2020. All rights reserved.
 */

import React from "react";
import MainButton from "./MainButton";
import Omnicon from "../assets/icons/Omnicon";

const styles = {
  root: {
    height: '100%',
    width: "100%",
    padding: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    width: 100,
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: 'relative',
  },
  noItemPlaceholderText: {
    padding: 7,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 24,
    fontFamily: "Lato",
    fontWeight: '300',
  },
  button: {
    marginTop: 10,
    width: "60%",
    padding: 0,
    height: 45
  }
};

const NoItems = props => {
  let {
    title,
    buttonTitle,
    buttonColor,
    onPress,
    iconName,
    iconColor,
    iconSize
  } = props;

  if (!title) title = "No items in the list.";
  return (
    <div style={styles.root}>
      <div style={styles.icon}>
        <Omnicon
          name="box-empty-color"
          color="black"
          size={iconSize ? iconSize : 64}
        />
      </div>
      <div style={styles.noItemPlaceholderText}>{title}</div>
      {onPress ? (
        <div style={styles.button}>
          <MainButton
            title={buttonTitle}
            color={buttonColor}
            iconColor={iconColor}
            textColor={iconColor}
            iconName={iconName}
            onPress={onPress}
          />
        </div>
      ) : null}
    </div>
  );
};

export default NoItems;
