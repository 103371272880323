import React from 'react';
import propTypes from 'prop-types';
import './navigation.css';
import ServiceNavigationItem from './ServiceNavigationItem';

export default class ServiceNavigation extends React.Component {

    render() {

        return (
            <div className="drawer-services-menu-container">

                {/* <div className="drawer-services-menu-header">
                    HELLO FROM HEADER
                </div> */}

                <div className="drawer-services-menu-list">
                    <ServiceNavigationItem linkTo="/dashboard" title="Dashboard" iconName="block layout"/>
                    <ServiceNavigationItem linkTo="/invoice" title="Invoice" iconName="file alternate outline"/>
                    <ServiceNavigationItem
                        linkTo="/customer/list"
                        title="Customer"
                        iconName="user circle filled"
                        iconColor="red"
                    />
                    <ServiceNavigationItem linkTo="/files" title="Files" iconName="folder outline" iconColor="white"/>
                    <ServiceNavigationItem linkTo="/tracking" title="Tracking" iconName="barcode"/>
                    <ServiceNavigationItem linkTo="/inventory" title="Inventory" iconName="box"/>
                </div>
                
            </div>
        );
    }
}

ServiceNavigation.propTypes = {
    serviceNavOpen: propTypes.bool.isRequired,
};


