import React from 'react';
import propTypes from 'prop-types';
import {Button, Icon} from 'semantic-ui-react'

// import ServiceNavigation from "../ServiceNavigation";
import {CognitoUserPool} from "amazon-cognito-identity-js";
import { Dimmer } from 'semantic-ui-react'
import ServiceNavigation from '../navigation/ServiceNavigation';

import "./app-bar.css";

const styles = {
    Hamburger: {
        cursor: 'pointer',
    },
    AppBarMain: {
      background: 'white',
    }
};
const poolData = {
    UserPoolId: 'us-east-1_r738QDsdH',
    ClientId: '3ro592qgebcund6fepib41p4rl'
};

class AppBar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            userName: '...',
            servcesOpen: false,
        };
    }

    componentDidMount = () => {
        this._loadAuthenticatedUser().then(this._handleUserData);
    };
    _handleServicesToggle = () => {
        this.setState({
            servicesOpen: !this.state.servicesOpen
        })
    };

    _handleUserData = (user) => {


        this.setState({
            userName: user.name,
        });

        try {
            this.props.getUserData(user);
        } catch (err) {
            console.log(err);
        }
    };
    _loadAuthenticatedUser()  {

        return new Promise(function(resolve, reject) {

            const userPool = new CognitoUserPool(poolData);
            const cognitoUser = userPool.getCurrentUser();



            if (cognitoUser != null) {
                cognitoUser.getSession(function(err, session) {

                    if (err) {
                        alert(err);
                        return;
                    }

                    let user = {};

                    // TODO: Remove debug log
                    console.log(session);

                    user.idToken = session.idToken.jwtToken;

                    //get User Attributes
                    cognitoUser.getUserAttributes( (err, result) => {

                        if(err) {
                            console.log(err);
                            return;
                        }

                        result.forEach( attribute => {

                            if (attribute.getName() === 'name')
                                user.name = attribute.getValue();

                            if (attribute.getName() === 'custom:tenant_id')
                                user.tenantID = attribute.getValue();
                        });

                        resolve(user)
                    });
                });
            }
        });
    };

  render() {

    const {
        state: {            
            userName,
            servicesOpen
        },
        props: {
            service
        },
    } = this;

    if (servicesOpen) {
        return (
            <Dimmer active={servicesOpen} onClickOutside={this._handleServicesToggle} page>
                <ServiceNavigation />
            </Dimmer>        
        )
    }
        

    return (
      <div className="AppBar">
           
        <div className="AppBarMain" style={styles.AppBarMain}>
            <div className="AppBarLeft">
                <div className="hamburger" onClick={this.props.handleSideBarToggle}>
                    <Icon name="bars" size="large" />
                </div>
                <div className="Service" onClick={this._handleServicesToggle}>
                    { service }
                </div>
            </div>
            <div className="AppBarCenter">
                {this.props.children}
            </div>
            <div className="AppBarRight">
            { userName }
            </div>
        </div>
      </div>
    );
  }
}

AppBar.propTypes = {
    service: propTypes.string,
    getUserData: propTypes.func,
    handleSideBarToggle: propTypes.func,
};

export default AppBar;
