import React from "react";
import PropTypes from "prop-types";

import "./inventory-card.css";

const InventoryCard = props => {
  const { item, onClick } = props;

  return (
    <div className="inventory-card-container" onClick={() => onClick(item.id)}>
      <div className="inventory-card-header">
        <div className="inventory-card-info">
          {item.category} • {item.sub_category}
        </div>
      </div>
      <div className="inventory-card-invoice">{item.title}</div>
      <div className="inventory-card-body">
        <div className="inventory-card-amount">{item.quantity}</div>
        <div className="inventory-card-currency">{item.unit}</div>
      </div>
    </div>
  );
};

InventoryCard.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

export default InventoryCard;
