/**
 * Copyright Redi Kurti, 2020. All rights reserved.
 */

import React, { useState } from "react";
import { Button, Modal } from "semantic-ui-react";

const styles = {
  formInput: {
    height: "35px",
    borderRadius: "5px",
    border: "none",
    boxShadow: "0 1px 1px 0 #cfd9df",
    fontFamily: "Lato, sans-serif",
    fontSize: "1em",
    padding: "0 6px",
    background: "#d3d3d3",
    width: "100%"
  }
};

const NewFolderModal = ({ onSave, isOpen, close }) => {
  const [folderName, setFolderName] = useState("");
  return (
    <Modal dimmer="inverted" open={isOpen} onClose={close}>
      Folder
      <Modal.Content>
        <input
          style={styles.formInput}
          type="text"
          value={folderName}
          onChange={e => setFolderName(e.target.value)}
          autoFocus
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          positive
          icon="checkmark"
          labelPosition="right"
          content="Save"
          onClick={() => onSave(folderName)}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default NewFolderModal;
